import React, { useContext, useState } from 'react';
import { View, TextInput, StyleSheet, KeyboardTypeOptions, NativeSyntheticEvent, TextInputKeyPressEventData, ViewStyle, StyleProp } from 'react-native';
import { translate } from "../services/translate";
import MyAppText from './MyAppText';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';

interface FormInputProps {
    label: string,
    placeholder?: string,
    errorMessage?: string,
    value: string,
    onChangeText: (value: string) => void,
    invalid: (value: string) => boolean;
    disabled?: boolean;
    secureTextEntry?: boolean;
    keyboardType?: KeyboardTypeOptions;
    onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
    maxLength?: number;
    viewStyle?: StyleProp<ViewStyle>;
    multiline?: boolean;
    numberOfLines?: number;
}

export default function FormInput({ label, placeholder, errorMessage, value, onChangeText, invalid, disabled, secureTextEntry, maxLength = 255, keyboardType, onKeyPress, viewStyle, multiline, numberOfLines }: FormInputProps) {
    const { theme } = useContext(ThemeContext);
    const [isDirty, setIsDirty] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const styles = getStyles(theme);

    disabled = !!disabled;

    return (
        <View style={[{ rowGap: 5 }, viewStyle]}>
            <MyAppText style={styles.label}>{label}</MyAppText>
            <TextInput
                editable={!disabled}
                placeholder={placeholder ? placeholder : label}
                style={[styles.input, {
                    color: value == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222')
                }, disabled ? styles.disabled : (isInvalid ? styles.invalid : null), !multiline ? { height: 40 } : {}]}
                value={value}
                onBlur={() => {
                    setIsInvalid(isDirty && invalid(value));
                }}
                onChangeText={(value) => {
                    if (!invalid(value)) {
                        setIsInvalid(false);
                    }
                    setIsDirty(true);
                    onChangeText(value);
                }}
                secureTextEntry={secureTextEntry}
                keyboardType={keyboardType}
                onKeyPress={onKeyPress}
                maxLength={!multiline ? maxLength : undefined}
                multiline={multiline}
                numberOfLines={numberOfLines}
            />
            {isInvalid ?
                <MyAppText style={styles.error}>{errorMessage ? errorMessage : translate("required_field")}</MyAppText> : null}
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            padding: 10,
            borderRadius: 4,
        },
        error: {
            color: '#FF0000',
            fontSize: 10
        },
        invalid: {
            borderColor: '#FF0000',
        },
        disabled: {
            backgroundColor: getThemedColor(theme, '#EEEEEE')
        }
    });
}
