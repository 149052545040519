import React from 'react';
import LateralMenu from "../LateralMenu";
import { faRightFromBracket, faGear, faKey, faLock } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';

export type SelectedMenu = 'account' | 'password' | 'sessions';

interface UserLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function UserLateralMenu({ navigation, selectedMenu }: UserLateralMenuProps) {
    return <LateralMenu
        items={[{
            icon: faGear,
            text: translate('account'),
            action: () => navigation.navigate('User', { screen: 'Account' }),
            selected: selectedMenu == 'account'
        }, {
            icon: faKey,
            text: translate('changePassword'),
            action: () => navigation.navigate('User', { screen: 'Password' }),
            selected: selectedMenu == 'password'
        }, {
            icon: faLock,
            text: translate('sessions'),
            action: () => navigation.navigate('User', { screen: 'Sessions' }),
            selected: selectedMenu == 'sessions'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
