import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Modal, Switch } from 'react-native';
import MyAppText from '../../MyAppText';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';
import FormInput from '../../formInput';
import MyDropDownPicker from '../../MyDropDownPicker';
import FormActions from '../../formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import { ScrollView } from 'react-native-web-hover';

interface InvolvedVehicleModalParameters {
    involvedVehicle: InvolvedVehicle;
    setInvolvedVehicle: (value: InvolvedVehicle) => void;
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
}

const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

export default function InvolvedVehicleModal({ involvedVehicle, setInvolvedVehicle, isModalVisible, setModalVisible, onSubmit }: InvolvedVehicleModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [relationDropdown, setRelationDropdown] = useState<boolean>(false);
    const [vehicleRelations, setVehicleRelations] = useState<VehicleRelation[]>([]);

    async function getVehicleRelations(): Promise<void> {
        try {
            const response = await dispatchService.getVehicleRelations({ page: 0, limit: 9999999999999 });
            setVehicleRelations(response.rows);

        } catch (err) {
            //TODO: add proper error treatments
            console.error(err);
        }
    }

    function isPlateInvalid(value: string) {
        return value == "" || value.length > 7 || !regPlate.test(value);
    }

    function isValidForm() {
        if (involvedVehicle.plate === '' || isPlateInvalid(involvedVehicle.plate)) {
            return false;
        }
        if (involvedVehicle.brand === '') {
            return false;
        }
        if (involvedVehicle.model === '') {
            return false;
        }
        if (involvedVehicle.color === '') {
            return false;
        }
        if (involvedVehicle.chassis === '') {
            return false;
        }
        if (involvedVehicle.vehicleRelationId === 0) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        getVehicleRelations();
    }, []);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                        <ScrollView>
                            <View style={{ rowGap: 20 }}>
                                <View style={styles.row}>
                                    <FormInput
                                        label={translate('vehicle_metadata_plate')}
                                        placeholder={translate('vehicle_metadata_plate')}
                                        value={involvedVehicle.plate}
                                        onChangeText={(value) => setInvolvedVehicle({ ...involvedVehicle, plate: value })}
                                        invalid={isPlateInvalid}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <FormInput
                                        label={translate('brand')}
                                        placeholder={translate('brand')}
                                        value={involvedVehicle.brand}
                                        onChangeText={(value) => setInvolvedVehicle({ ...involvedVehicle, brand: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <FormInput
                                        label={translate('model')}
                                        placeholder={translate('model')}
                                        value={involvedVehicle.model}
                                        onChangeText={(value) => setInvolvedVehicle({ ...involvedVehicle, model: value })}
                                        invalid={() => false}
                                        viewStyle={[{ flex: 1 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}
                                    />
                                </View>
                                <View style={[styles.row, { zIndex: 2 }]}>
                                    <FormInput
                                        label={translate('color')}
                                        placeholder={translate('color')}
                                        value={involvedVehicle.color}
                                        onChangeText={(value) => setInvolvedVehicle({ ...involvedVehicle, color: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <FormInput
                                        label={translate('chassis')}
                                        placeholder={translate('chassis')}
                                        value={involvedVehicle.chassis}
                                        onChangeText={(value) => setInvolvedVehicle({ ...involvedVehicle, chassis: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <View style={[{ gap: 5, zIndex: 2, flex: 1 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%', columnGap: 0 }]}>
                                        <MyAppText>{translate('relation')}</MyAppText>
                                        <MyDropDownPicker
                                            placeholder={translate('relation')}
                                            multiple={false}
                                            mode='BADGE'
                                            open={relationDropdown}
                                            setOpen={setRelationDropdown}
                                            value={involvedVehicle.vehicleRelationId}
                                            setValue={(value) => {
                                                const vehicleRelationId = value(involvedVehicle.vehicleRelationId);
                                                setInvolvedVehicle({
                                                    ...involvedVehicle,
                                                    vehicleRelationId: vehicleRelationId,
                                                    vehicleRelation: vehicleRelations.find(vehicleRelation => vehicleRelation.id == vehicleRelationId)
                                                });
                                            }}
                                            items={vehicleRelations.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id
                                                };
                                            })}
                                            searchable={false}
                                            zIndex={2}
                                        />
                                    </View>
                                </View>
                                <View style={styles.row}>
                                    <View style={[{ gap: 5 }, windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                        <MyAppText>{translate('theft')}</MyAppText>
                                        <Switch
                                            trackColor={{ false: '#767577', true: '#81b0ff' }}
                                            thumbColor={involvedVehicle.stolen ? '#f5dd4b' : '#f4f3f4'}
                                            onValueChange={(value) => {
                                                setInvolvedVehicle({ ...involvedVehicle, stolen: value });
                                            }}
                                            value={involvedVehicle.stolen}
                                        />
                                    </View>
                                    <FormInput
                                        label={translate('restriction')}
                                        placeholder={translate('restriction')}
                                        value={involvedVehicle.restriction || ''}
                                        onChangeText={(value) => setInvolvedVehicle({ ...involvedVehicle, restriction: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { flex: 1 }}
                                    />
                                </View>
                                <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                                    <FormActions
                                        onSubmit={() => {
                                            onSubmit();
                                            setModalVisible(false);
                                        }}
                                        onClose={() => setModalVisible(false)}
                                        disabled={!isValidForm()} />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        videoNotReadyText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 650,
            padding: 30,
        },
        row: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            columnGap: 30,
            minHeight: 'auto',
            rowGap: 20,
        }
    });
}
