import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import PermissionLevelModal from './PermissionLevelModal';
import Toast from 'react-native-toast-message';
import { accessService } from '../../services/central-api/access';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AccessParamList } from '../../typings/Params';
import MyDataTable from '../../components/MyDataTable';


export default function PermissionLevels({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<AccessParamList, 'PermissionLevels'>>();

    const [pageCount, setPageCount] = useState(1);
    const [permissionLevels, setPermissionLevels] = useState<PermissionLevelData[]>([]);
    const [page, setPage] = useState(Math.max(route.params.page - 1, 0));
    const [selectedPermissionLevel, setSelectedPermissionLevel] = useState<PermissionLevelData>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function getPermissionLevels({ page }: { page: number; }) {
        try {
            setIsLoading(true);
            setPage(page);
            const response = await accessService.getPermissionLevels({ page });
            setPermissionLevels(response.rows);
            setPageCount(Math.ceil(response.count / 10));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(permissionLevels: PermissionLevelData) {
        try {
            if (permissionLevels?.id) {
                await accessService.deletePermissionLevel(permissionLevels.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });

                setDeleteModalVisible(false);
                getPermissionLevels({ page });

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getPermissionLevels({ page });
    }, [page]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='permissionLevels' lateralMenu='access' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                setSelectedPermissionLevel(undefined);
                                setModalVisible(true);
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    grow: 2,
                                    wrap: true
                                },
                                {
                                    name: translate('actingBody'),
                                    selector: row => row.actingBodyUnit?.actingBody?.name || '',
                                    grow: 2,
                                },
                                {
                                    name: translate('unit'),
                                    selector: row => row.actingBodyUnit?.name || '',
                                    grow: 2,
                                }, {
                                    name: translate('userCount'),
                                    selector: row => row.userCount || 0,
                                    center: true
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedPermissionLevel(row);
                                                setModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedPermissionLevel(row);
                                                setDeleteModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={permissionLevels}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    {/* Delete Modal */}
                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedPermissionLevel?.name) || ''}
                        onSubmit={async () => {
                            if (selectedPermissionLevel) {
                                await confirmDelete(selectedPermissionLevel);
                            }
                        }}
                    />

                    {/* Create/Edit Modal */}
                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <PermissionLevelModal
                                    onClose={(success) => {
                                        setSelectedPermissionLevel(undefined);
                                        setModalVisible(false);
                                        getPermissionLevels({ page });
                                        if (success) {
                                            Toast.show({
                                                type: 'sentinelxSuccess',
                                                text1: translate('ActionSuccessfully'),
                                            });
                                        }
                                    }}
                                    permissionLevelId={selectedPermissionLevel?.id}
                                />
                            </View>
                        </View>
                    </Modal>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B')
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        deleteButtonsView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        deleteButtons: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 10,
            color: getThemedColor(theme, '#FFFFFF')
        },
        red: {
            backgroundColor: 'red',
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
    });
}
