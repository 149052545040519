import ObjectCategoriesModal from './ObjectCategoryModal';
import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAppText from '../../components/MyAppText';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { Pagination } from '../../components/Pagination';
import { dispatchService, getObjectCategoriesPaginatedParameters } from '../../services/central-api/dispatch';
import MyDataTable from '../../components/MyDataTable';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';

const limit = 25;

export default function ObjectsCategories({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'ObjectsCategories'>>();

    const [isModalVisible, setModalVisible] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedObjectCategory, setSelectedObjectCategory] = useState<PaginatedObjectCategory>();
    const [objectsCategories, setObjectCategories] = useState<PaginatedObjectCategory[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);
    const [filters, setFilters] = useState<getObjectCategoriesPaginatedParameters>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit
    });
    const [page, setPage] = useState(filters.page);

    async function getObjectCategories(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getPaginatedObjectsCategories(filters);
            setObjectCategories(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setFilters({ ...filters, page });
    }, [page]);

    useEffect(() => {
        getObjectCategories();
    }, [filters]);

    async function confirmDelete(objectCategory: PaginatedObjectCategory) {
        try {
            await dispatchService.deleteObjectCategory(objectCategory.id);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setDeleteModalVisible(false);
            getObjectCategories();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='objects_categories' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                setSelectedObjectCategory(undefined);
                                setModalVisible(true);
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true,
                                    grow: 1
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedObjectCategory(row);
                                                setModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedObjectCategory(row);
                                                setDeleteModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={objectsCategories}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                    {/* Delete Modal */}
                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={selectedObjectCategory?.name || ''}
                        onSubmit={async () => {
                            if (selectedObjectCategory) {
                                await confirmDelete(selectedObjectCategory);
                            }
                        }}
                    />

                    <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <ObjectCategoriesModal
                                    onClose={() => {
                                        setSelectedObjectCategory(undefined);
                                        setModalVisible(false);
                                        getObjectCategories();
                                    }}
                                    objectCategoryId={selectedObjectCategory?.id}
                                />
                            </View>
                        </View>
                    </Modal>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            gap: 20
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        circle: {
            width: 15,
            height: 15,
            borderRadius: 7.5,
        },
        green: {
            backgroundColor: 'green',
        },
        yellow: {
            backgroundColor: '#FFD700',
        },
        grey: {
            backgroundColor: 'grey'
        },
        status: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10
        },
    });
}
