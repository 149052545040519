import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { accessService } from '../../services/central-api/access';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../services/window-information';
import MyAppText from '../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../components/DeleteModal';
import { ClientError } from '../../services/central-api/base-service';
import { Tabs } from '../../components/Tabs';
import { ScrollView } from 'react-native-web-hover';

export default function ActingBodiesModal({ onClose, actingBodyId }: { onClose: () => void, actingBodyId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [roles, setRoles] = useState<Role[]>([{ name: '' }]);
    const [selectedRoleForDeletion, setSelectedRoleForDeletion] = useState<Role>();
    const [units, setUnits] = useState<ActingBodyUnit[]>([{ name: '' }]);
    const [isDeleteRoleModalVisible, setIsDeleteRoleModalVisible] = useState(false);
    const [isDeleteUnitModalVisible, setIsDeleteUnitModalVisible] = useState(false);
    const [selectedUnitForDeletion, setSelectedUnitForDeletion] = useState<ActingBodyUnit>();

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    async function deleteUnit(unit: ActingBodyUnit) {
        try {
            if (!unit.id) {
                return;
            }
            await accessService.deleteUnit(unit.id);
            const index = units.findIndex(u => u.id == unit.id);
            units.splice(index, 1);
            setUnits([...units]);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteRole(role: Role) {
        try {
            if (!role.id) {
                return;
            }
            await accessService.deleteRole(role.id);
            const index = roles.findIndex(r => r.id == role.id);
            roles.splice(index, 1);
            setRoles([...roles]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getActingBody(actingBodyId: number) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);
            setName(actingBody.name);
            setDescription(actingBody.description);
            setRoles(actingBody.roles || []);
            setUnits(actingBody.actingBodyUnits || []);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (actingBodyId) {
            getActingBody(actingBodyId);
        }
    }, []);

    async function formSubmit() {
        try {
            if (actingBodyId) {
                await accessService.updateActingBody({
                    id: actingBodyId,
                    name,
                    description,
                    roles,
                    actingBodyUnits: units,
                });
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                onClose();
                return;
            }

            await accessService.createActingBody({
                name,
                description,
                roles,
                actingBodyUnits: units,
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <>
            <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                <Tabs tabs={[{
                    key: 'details',
                    label: translate('details')
                }, {
                    key: 'roles',
                    label: translate('roles')
                }, {
                    key: 'units',
                    label: translate('units')
                }]}>
                    {({ selectedTab }) => {
                        if (selectedTab == 'details') {
                            return (<>
                                <FormInput
                                    label={translate('name')}
                                    placeholder={translate('name')}
                                    value={name}
                                    onChangeText={setName}
                                    invalid={isNameInvalid}
                                />
                                <FormInput
                                    label={translate('description')}
                                    placeholder={translate('description')}
                                    value={description}
                                    onChangeText={setDescription}
                                    invalid={() => false}
                                />
                            </>);
                        }
                        if (selectedTab == 'roles') {
                            return <ScrollView>
                                <View style={{ gap: 8 }}>
                                    <MyAppText style={styles.label}>{translate('roles')}</MyAppText>

                                    {roles.map((role, index) => (
                                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                            <TextInput
                                                placeholder={translate('role')}
                                                style={[styles.input, {
                                                    color: role.name == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222')
                                                }]}
                                                value={role.name}
                                                onChangeText={(value) => {
                                                    roles[index].name = value;
                                                    setRoles([...roles]);
                                                }}
                                            />
                                            <TouchableOpacity onPress={() => {
                                                if (roles[index].id) {
                                                    setSelectedRoleForDeletion(roles[index]);
                                                    setIsDeleteRoleModalVisible(true);
                                                    return;
                                                }
                                                roles.splice(index, 1);
                                                setRoles([...roles]);
                                            }}>
                                                <FontAwesomeIcon fontSize={24} icon={faTimes} color={getThemedColor(theme, '#222222')} />
                                            </TouchableOpacity>
                                        </View>))
                                    }

                                    <TouchableOpacity onPress={() => {
                                        roles.push({ name: '' });
                                        setRoles([...roles]);
                                    }}>
                                        <MyAppText style={styles.addRole}>+ {translate('addRole')}</MyAppText>
                                    </TouchableOpacity>
                                </View>
                            </ScrollView>;
                        }
                        if (selectedTab == 'units') {
                            return <ScrollView>
                                <View style={{ gap: 8 }}>
                                    <MyAppText style={styles.label}>{translate('units')}</MyAppText>

                                    {units.map((unit, index) => (
                                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                            <TextInput
                                                placeholder={translate('unit')}
                                                style={[styles.input, {
                                                    color: unit.name == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222')
                                                }]}
                                                value={unit.name}
                                                onChangeText={(value) => {
                                                    units[index].name = value;
                                                    setUnits([...units]);
                                                }}
                                            />
                                            <TouchableOpacity onPress={() => {
                                                if (units[index].id) {
                                                    setSelectedUnitForDeletion(units[index]);
                                                    setIsDeleteUnitModalVisible(true);
                                                    return;
                                                }
                                                units.splice(index, 1);
                                                setUnits([...units]);
                                            }}>
                                                <FontAwesomeIcon fontSize={24} icon={faTimes} color={getThemedColor(theme, '#222222')} />
                                            </TouchableOpacity>
                                        </View>))
                                    }

                                    <TouchableOpacity onPress={() => {
                                        units.push({ name: '' });
                                        setUnits([...units]);
                                    }}>
                                        <MyAppText style={styles.addRole}>+ {translate('addUnit')}</MyAppText>
                                    </TouchableOpacity>
                                </View>;
                            </ScrollView>;

                        }
                    }}
                </Tabs>

                <FormActions
                    onSubmit={formSubmit}
                    onClose={onClose}
                    disabled={!isFormValid()} />
            </View>

            <DeleteModal
                setModalVisible={setIsDeleteRoleModalVisible}
                isModalVisible={isDeleteRoleModalVisible}
                itemName={selectedRoleForDeletion?.name}
                onSubmit={async () => {
                    if (selectedRoleForDeletion) {
                        await deleteRole(selectedRoleForDeletion);
                    }
                }}
            />

            <DeleteModal
                setModalVisible={setIsDeleteUnitModalVisible}
                isModalVisible={isDeleteUnitModalVisible}
                itemName={selectedUnitForDeletion?.name}
                onSubmit={async () => {
                    if (selectedUnitForDeletion) {
                        await deleteUnit(selectedUnitForDeletion);
                    }
                }}
            />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            minHeight: 150,
            maxHeight: '90%',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 25
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        addRole: {
            color: getThemedColor(theme, '#000028')
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
            flex: 1
        },
    });
}
