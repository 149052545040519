import React, { useContext, useState } from 'react';
import { View, TextInput, StyleSheet, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from "../services/translate";
import MyAppText from './MyAppText';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface PasswordInputProps {
    label: string,
    placeholder?: string,
    errorMessage?: string,
    value: string,
    onChangeText: (value: string) => void,
    invalid: (value: string) => boolean;
    disabled?: boolean;
    onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
}

export default function PasswordInput({ label, placeholder, errorMessage, value, onChangeText, invalid, disabled, onKeyPress }: PasswordInputProps) {
    const { theme } = useContext(ThemeContext);
    const [isDirty, setIsDirty] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const styles = getStyles(theme);

    disabled = !!disabled;

    return (
        <View style={{rowGap: 5}}>
            <MyAppText style={styles.label}>{label}</MyAppText>
            <View style={{ flexDirection: 'row' }}>
                <TextInput
                    editable={!disabled}
                    placeholder={placeholder ? placeholder : label}
                    style={[styles.input, {
                        color: value == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222')
                    }, disabled ? styles.disabled : (isInvalid ? styles.invalid : null)]}
                    value={value}
                    onBlur={() => {
                        setIsInvalid(isDirty && invalid(value));
                    }}
                    onChangeText={(value) => {
                        if (!invalid(value)) {
                            setIsInvalid(false);
                        }
                        setIsDirty(true);
                        onChangeText(value);
                    }}
                    secureTextEntry={showContent ? false : true}
                    onKeyPress={onKeyPress}
                />
                <TouchableOpacity onPress={() => setShowContent(!showContent) } style={[styles.eyeBox, isInvalid ? styles.invalid : null]} delayPressIn={100}>
                    <FontAwesomeIcon icon={showContent ? faEyeSlash : faEye} color={getThemedColor(theme, '#222222')} fontSize={16}></FontAwesomeIcon>
                </TouchableOpacity>
            </View>
            {isInvalid ?
                <MyAppText style={styles.error}>{errorMessage ? errorMessage : translate("required_field")}</MyAppText> : null}
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        input: {
            flexGrow: 1,
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
            borderEndEndRadius: 0,
            borderTopEndRadius: 0,
        },
        eyeBox: {
            width: 40,
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            borderRadius: 4,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeftWidth: 0
        },
        error: {
            color: '#FF0000',
            fontSize: 10
        },
        invalid: {
            borderColor: '#FF0000',
        },
        disabled: {
            backgroundColor: getThemedColor(theme, '#EEEEEE')
        }
    });
}
