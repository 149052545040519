import React, { useContext, useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLocationDot, faVideo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import DefaultPageContainer from "../../components/DefaultPageContainer";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import personDefaultPicture from '../../../assets/person-default-picture.png';
import Map from "../../components/Map";
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { useNavigationState } from '@react-navigation/native';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { facialService } from '../../services/central-api/facial';
import { ScrollView } from 'react-native-web-hover';
import { TextMask } from 'react-native-masked-text';

interface EventDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            eventId: string;
            page: 'liveEvents' | 'liveAlerts' | 'search' | 'smartSearchFacial';
        };
    };
}

function EventDetailPageLayout({ navigation, route, authenticatedUser, children }: EventDetailParams & { children: React.ReactNode | undefined; }) {
    if (route.params.page === 'smartSearchFacial') {
        return <DefaultPageLayout navigation={navigation} selectedMenu={route.params.page} lateralMenu={'smartSearchFacial'} authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
            {children}
        </DefaultPageLayout>;
    }
    return <DefaultPageLayout navigation={navigation} selectedMenu={route.params.page} lateralMenu={'facial'} authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
        {children}
    </DefaultPageLayout>;
}

export default function EventDetail({ navigation, route, authenticatedUser }: EventDetailParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [event, setEvent] = useState<FacialDetection>();
    const index = useNavigationState(state => state);

    async function getDetection(eventId: string) {
        try {
            const event = await facialService.getFacialDetection(Number(eventId));
            setEvent(event);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDetection(route.params.eventId);
    }, [route]);

    if (!event) {
        return <DefaultPageContainer>
            <EventDetailPageLayout navigation={navigation} route={route} authenticatedUser={authenticatedUser}>
                <></>
            </EventDetailPageLayout>
        </DefaultPageContainer>;
    }

    function PersonInfoInRow({ title, desc }: { title: string; desc: string; }) {
        return <View style={{ flexDirection: 'row', columnGap: 5 }}>
            <MyAppText style={styles.personInfoTitleText}>
                {title}:
            </MyAppText>
            <MyAppText style={styles.personInfoText}>
                {desc}
            </MyAppText>
        </View>;
    }

    return (<DefaultPageContainer>
        <EventDetailPageLayout navigation={navigation} route={route} authenticatedUser={authenticatedUser}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <TouchableOpacity onPress={() => {
                        if (index.routes.length > 1) {
                            return navigation.goBack();
                        } else {
                            if (route.params.page == 'liveAlerts') {
                                window.location.href = '/facial/live-alerts';
                            }
                            if (route.params.page == 'liveEvents') {
                                window.location.href = '/facial/live-events';
                            }
                            if (route.params.page == 'search') {
                                window.location.href = '/facial/search';
                            }
                            if (route.params.page == 'smartSearchFacial') {
                                window.location.href = '/smart-search-facial/search';
                            }
                        }
                    }}>
                        <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                    </TouchableOpacity>
                    <MyAppText style={styles.headerText}>
                        {translate('detection')}
                    </MyAppText>
                </View>
                <View style={styles.cardList}>
                    <ScrollView contentContainerStyle={{
                        columnGap: 20,
                        rowGap: 5,
                        flex: 1,
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}>
                        <View style={{ rowGap: 10, flexBasis: '25%', minWidth: 350, minHeight: 550 }}>
                            <View style={{ flexDirection: 'row', columnGap: 15, flexBasis: '30%' }}>
                                <View style={{ flex: 1 }}>
                                    <MyAppText style={styles.imageText}>
                                        {translate('detection')}
                                    </MyAppText>
                                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={event.imageUrl}
                                    />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <MyAppText style={styles.imageText}>
                                        {translate('reference')}
                                    </MyAppText>
                                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        src={event.person?.facePictures.length ? event.person.facePictures[0].imageUrl : personDefaultPicture}
                                    />
                                </View>
                            </View>
                            <MyAppText style={styles.confidenceText}>
                                {event.confidence ?
                                    translate('confidence') + ": " + (Number(event.confidence) * 100).toFixed(0) + "%"
                                    : null
                                }
                            </MyAppText>
                            <View style={{ rowGap: 5 }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                                    <FontAwesomeIcon icon={faVideo} style={{ width: 22 }} color={getThemedColor(theme, '#222222')} />
                                    <TouchableOpacity onPress={() => {
                                        window.open(`/cameras/${event.camera.id}`);
                                    }} style={styles.link}>
                                        <MyAppText style={[styles.detectionInfoText, { textDecorationLine: 'underline' }]}>
                                            {event.camera.title}
                                        </MyAppText>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                                    <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 22 }} color={getThemedColor(theme, '#222222')} />
                                    <MyAppText style={styles.detectionInfoText}>
                                        {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                                    </MyAppText>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                                    <FontAwesomeIcon icon={faLocationDot} style={{ width: 22 }} color={getThemedColor(theme, '#222222')} />
                                    <View style={styles.link}>
                                        <MyAppText style={styles.detectionInfoText}>
                                            {event.camera.address}
                                        </MyAppText>
                                    </View>
                                </View>
                            </View>
                            <Map pinnedMarker={{
                                lat: event.camera.latitude,
                                lng: event.camera.longitude
                            }} />
                        </View>
                        {event.person ?
                            <ScrollView style={{ flex: 1 }}>
                                <View style={{ rowGap: 10 }}>
                                    <MyAppText style={styles.nameText}>
                                        {event.person.name}
                                    </MyAppText>
                                    {event.person.nickname ?
                                        <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                            <MyAppText style={styles.personInfoTitleText}>
                                                {translate('nickname')}:
                                            </MyAppText>
                                            <MyAppText style={styles.personInfoText}>
                                                {event.person.nickname}
                                            </MyAppText>
                                        </View>
                                        : null
                                    }
                                    <View style={{ flexDirection: 'row' }}>
                                        {event?.person?.markers?.map((marker) =>
                                            <View key={marker.id} style={[styles.marker, { backgroundColor: marker.color }]}>
                                                <MyAppText style={styles.markerText}>
                                                    {marker.name}
                                                </MyAppText>
                                            </View>
                                        )}
                                    </View>
                                    <View style={{ flexDirection: 'row', columnGap: 35, flexWrap: 'wrap', rowGap: 5 }}>
                                        <View>
                                            <MyAppText style={styles.personDocumentInfoText}>CPF:</MyAppText>
                                            <TextMask
                                                style={[styles.personDocumentInfoText, { fontFamily: 'Open Sans' }]}
                                                value={event.person.document}
                                                type={'cpf'}
                                            />
                                        </View>
                                        <View>
                                            <MyAppText style={styles.personDocumentInfoText}>
                                                {translate('personRegister')}:
                                            </MyAppText>
                                            <MyAppText style={styles.personDocumentInfoText}>
                                                {event.person.register}
                                            </MyAppText>
                                        </View>
                                        <View>
                                            <MyAppText style={styles.personDocumentInfoText}>
                                                {translate('registeredOn')}:
                                            </MyAppText>
                                            <MyAppText style={styles.personDocumentInfoText}>
                                                {moment(new Date(event.person.createdAt || 0)).format('DD/MM/YYYY HH:mm:ss')}
                                            </MyAppText>
                                        </View>
                                    </View>
                                    {event.person.comment ?
                                        <View style={{ marginVertical: 20 }}>
                                            <MyAppText style={styles.personInfoTitleText}>
                                                {translate('additionalComments')}:
                                            </MyAppText>
                                            <MyAppText style={styles.personInfoText}>
                                                {event.person.comment}
                                            </MyAppText>
                                        </View>
                                        : null
                                    }
                                    <View style={{ marginVertical: 20 }}>
                                        {event.person.city ?
                                            <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                                <MyAppText style={styles.personInfoTitleText}>
                                                    {translate('city')}:
                                                </MyAppText>
                                                <MyAppText style={styles.personInfoText}>
                                                    {event.person.city}/{event.person.state}
                                                </MyAppText>
                                            </View>
                                            : null
                                        }
                                    </View>
                                    <MyAppText style={[styles.nameText]}>
                                        {translate('arrestWarrants')}:
                                    </MyAppText>
                                    {event?.person?.lawsuits?.map((lawsuit) => (
                                        <View key={lawsuit.id} style={{ rowGap: 5 }}>
                                            <PersonInfoInRow
                                                title={translate('situation')}
                                                desc={lawsuit.situation}
                                            />
                                            <PersonInfoInRow
                                                title={translate('arrestWarrantNumber')}
                                                desc={lawsuit.arrestWarrantNumber}
                                            />
                                            <PersonInfoInRow
                                                title={translate('shippingDate')}
                                                desc={lawsuit.shippingDate}
                                            />
                                            <PersonInfoInRow
                                                title={translate('expirationDate')}
                                                desc={lawsuit.expirationDate}
                                            />
                                            <PersonInfoInRow
                                                title={translate('lawsuitNumber')}
                                                desc={lawsuit.lawsuitNumber}
                                            />
                                            <PersonInfoInRow
                                                title={translate('prisonKind')}
                                                desc={lawsuit.prisonKind}
                                            />
                                            <PersonInfoInRow
                                                title={translate('magistrate')}
                                                desc={lawsuit.magistrate}
                                            />
                                            <PersonInfoInRow
                                                title={translate('issuingBody')}
                                                desc={lawsuit.issuingBody}
                                            />
                                            <PersonInfoInRow
                                                title={translate('county')}
                                                desc={lawsuit.lawsuitCity}
                                            />
                                        </View>
                                    ))}
                                </View>
                            </ScrollView>
                            : null
                        }
                    </ScrollView>
                </View>
            </View>
        </EventDetailPageLayout>
    </DefaultPageContainer >);
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10,
            minHeight: 65
        },
        headerText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
        },
        imageText: {
            color: getThemedColor(theme, '#888888'),
            marginBottom: 5,
            fontSize: 11
        },
        detectionInfoText: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
        },
        confidenceText: {
            color: getThemedColor(theme, '#888888'),
            fontSize: 14,
        },
        nameText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 20
        },
        personInfoTitleText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 16
        },
        personInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 16
        },
        personDocumentInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 14
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        link: {
            flexDirection: 'row',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flex: 1
        }
    });
}
