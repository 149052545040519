import React, { useContext, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import MyAppText from './MyAppText';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';
import { TextInputMask, TextInputMaskMethods } from 'react-native-masked-text';
import { translate } from '../services/translate';

interface MyDateTimeInputProps {
    label: string;
    date: string;
    onChangeDate: (value: string) => void;
    time: string;
    onChangeTime: (value: string) => void;
    editable?: boolean;
}

type InputMask = TextInputMask & TextInputMaskMethods

export default function MyDatetimeInput({ label, date, time, onChangeDate, onChangeTime, editable = true }: MyDateTimeInputProps) {
    const { theme } = useContext(ThemeContext);
    const [isInvalid, setIsInvalid] = useState(false);
    const dateInputRef = useRef<InputMask>(null);
    const hourInputRef = useRef<InputMask>(null);

    const styles = getStyles(theme);

    return (
        <View style={{ rowGap: 5 }}>
            <MyAppText style={styles.label}>{label}</MyAppText>
            <View style={{ flexDirection: 'row', columnGap: 5 }}>
                <TextInputMask
                    value={date}
                    onChangeText={(text: string) => {
                        onChangeDate(text);
                    }}
                    keyboardType={'numeric'}
                    type={'datetime'}
                    style={[styles.input, { maxWidth: 115 }, {
                        color: date == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222'),
                    }, !editable ? styles.disabled : undefined, isInvalid ? styles.invalid : undefined]}
                    options={{
                        format: 'DD/MM/YYYY'
                    }}
                    onBlur={() => {
                        setIsInvalid(!dateInputRef.current?.isValid() || false);
                    }}
                    ref={dateInputRef}
                    editable={editable}
                />
                <TextInputMask
                    value={time}
                    onChangeText={onChangeTime}
                    keyboardType={"numeric"}
                    type={'datetime'}
                    style={[styles.input, { maxWidth: 90 }, {
                        color: time == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222'),
                    }, !editable ? styles.disabled : undefined, isInvalid ? styles.invalid : undefined]}
                    options={{
                        format: 'HH:mm:ss'
                    }}
                    onBlur={() => {
                        setIsInvalid(!hourInputRef.current?.isValid() || false);
                    }}
                    ref={hourInputRef}
                    editable={editable}
                />
            </View>
            {isInvalid ?
                <MyAppText style={styles.error}>{translate("invalidDateTime")}</MyAppText> : null}
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            maxHeight: 40,
            padding: 10,
            borderRadius: 4,
            color: getThemedColor(theme, '#222222'),
        },
        error: {
            color: '#FF0000',
            fontSize: 10
        },
        invalid: {
            borderColor: '#FF0000',
        },
        disabled: {
            backgroundColor: getThemedColor(theme, '#EEEEEE')
        }
    });
}
