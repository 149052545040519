import React, { useEffect, useState } from 'react';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { TagPageContent } from '../../components/TagPageContent';
import Toast from 'react-native-toast-message';
import { translate } from '../../services/translate';
import { View, StyleSheet } from 'react-native';
import { cameraService } from '../../services/central-api/cameras';

interface TagsParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function Tags({ navigation, authenticatedUser }: TagsParams) {
    const styles = getStyles();
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);

    async function getTags() {
        try {
            setLoading(true);

            const tags = await cameraService.getCameraTags();
            setTags(tags);
        } catch (err) {
            // TODO: add proper error validation
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getTags();
    }, []);

    async function deleteTag(tag: Tag) {
        try {
            if (tag.id) {
                await cameraService.deleteCameraTag(tag.id);
            }
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function createTag(params: CreateTag) {
        const newTag = await cameraService.createCameraTag(params);
        return newTag;
    }

    async function updateTag(id: number, params: UpdateTag) {
        await cameraService.updateCameraTag(id, params);
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='tags' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <TagPageContent
                        tags={tags}
                        setTags={setTags}
                        getTags={getTags}
                        loading={loading}
                        deleteTag={deleteTag}
                        createTag={createTag}
                        updateTag={updateTag}
                        hasRequiredPermission={true}
                        associatedLabel={translate('associatedCameras')}
                    />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>);
}

function getStyles() {
    return StyleSheet.create({
        container: {
            flex: 1,
        },
    });
}
