import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import MyAppText from './MyAppText';
import { Hoverable } from 'react-native-web-hover';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';

interface PaginationParameters {
    totalPages: number;
    currentPage: number;
    setPage: (value: number) => void;
}

export function Pagination({ totalPages, currentPage, setPage }: PaginationParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const pages = [];

    if (currentPage == 0) {
        // first page
        pages.push(0);
        if (totalPages > 1) {
            pages.push(1);
        }
        if (totalPages > 2) {
            pages.push(2);
        }

    } else if (currentPage + 1 == totalPages) {
        // last page
        if (currentPage - 2 >= 0) {
            pages.push(currentPage - 2);
        }
        if (currentPage - 1 >= 0) {
            pages.push(currentPage - 1);
        }
        pages.push(currentPage);
    } else {
        // any other page
        pages.push(currentPage - 1);
        pages.push(currentPage);
        pages.push(currentPage + 1);
    }


    return (
        <View style={{ flexDirection: 'row', height: 20, justifyContent: 'center', padding: 15 }}>
            {/* arrows */}
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setPage(0)}>
                <Hoverable>
                    {({ hovered }) => (
                        <View style={[styles.arrow, {
                            borderBottomWidth: hovered ? 1 : 0
                        }]}>
                            <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} fontSize={9} icon={faAngleDoubleLeft}></FontAwesomeIcon>
                        </View>
                    )}
                </Hoverable>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setPage(currentPage == 0 ? currentPage : currentPage - 1)} >
                <Hoverable>
                    {({ hovered }) => (
                        <View style={[styles.arrow, {
                            borderBottomWidth: hovered ? 1 : 0
                        }]}>
                            <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} fontSize={9} icon={faAngleLeft}></FontAwesomeIcon>
                        </View>
                    )}
                </Hoverable>
            </TouchableOpacity>
            {/* numbers */}
            {pages.map((page) => (
                <TouchableOpacity style={{ alignSelf: 'center' }} key={page} onPress={() => setPage(page)}>
                    <Hoverable>
                        {({ hovered }) => (
                            <MyAppText style={[styles.number, {
                                color: currentPage == page ? getThemedColor(theme, '#58595B') : getThemedColor(theme, '#888888'),
                                fontWeight: currentPage == page ? 'bold' : undefined,
                                borderBottomWidth: hovered ? 1 : 0
                            }]}>{page + 1}</MyAppText>
                        )}
                    </Hoverable>
                </TouchableOpacity>
            ))}
            {/* arrows */}
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setPage(currentPage + 1 == totalPages ? currentPage : currentPage + 1)}>
                <Hoverable>
                    {({ hovered }) => (
                        <View style={[styles.arrow, {
                            borderBottomWidth: hovered ? 1 : 0
                        }]}>
                            <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} fontSize={9} icon={faAngleRight}></FontAwesomeIcon>
                        </View>
                    )}
                </Hoverable>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setPage(totalPages - 1)}>
                <Hoverable>
                    {({ hovered }) => (
                        <View style={[styles.arrow, {
                            borderBottomWidth: hovered ? 1 : 0
                        }]}>
                            <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} fontSize={9} icon={faAngleDoubleRight}></FontAwesomeIcon>
                        </View>
                    )}
                </Hoverable>
            </TouchableOpacity >
        </View >
    );

}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        arrow: {
            paddingRight: 5,
            paddingLeft: 5,
            height: 19,
            borderBottomColor: getThemedColor(theme, '#58595B'),
            borderBottomWidth: 0,
            justifyContent: 'center'
        },
        number: {
            textAlign: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomColor: getThemedColor(theme, '#58595B'),
            color: getThemedColor(theme, '#888888'),
            borderBottomWidth: 0
        }
    });
}
