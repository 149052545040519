import React from 'react';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { OccurrenceDetail } from '../../components/occurrence/OccurrenceDetail';


interface DispatchOccurrenceDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params?: {
            occurrenceId: string;
        };
    };
}

export default function DispatchOccurrenceDetail({ navigation, route, authenticatedUser }: DispatchOccurrenceDetailParams) {
    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='occurrences' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <OccurrenceDetail navigation={navigation} authenticatedUser={authenticatedUser} occurrenceId={route.params?.occurrenceId} page={'dispatch'} />
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}
