import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import { SelectableItem, SelectableList } from '../../components/SelectableList';
import { Tabs } from '../../components/Tabs';
import { accessService } from '../../services/central-api/access';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { WindowInformation } from '../../services/window-information';
import { dispatchService } from '../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';

export default function SkillModal({ onClose, skillId }: { onClose: () => void, skillId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);
    const [skillUserIds, setSkillUserIds] = useState<number[]>([]);

    async function getSkill(id: number, users: AccessSimplifiedUserData[]) {
        const skill = await dispatchService.getSkill(id);
        setName(skill.name);
        setDescription(skill.description);

        const selectedUsers = skill.Users.map((user) => {
            return {
                id: user.id || 0,
                name: user.name
            };
        });
        setSelectedUsers(selectedUsers);
        setSkillUserIds(skill.Users.map((user) => user.id || 0));
        setSelectableUsers(users
            .filter((user) => !selectedUsers.find((skillUser) => skillUser.id == user.id))
            .map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            })
        );
    }

    async function loadPageInfo() {
        try {
            const users = await accessService.getAccessAllUsers({});

            if (skillId) {
                await getSkill(skillId, users);
            } else {
                setSelectableUsers(users.map((user) => {
                    return {
                        id: user.id || 0,
                        name: user.name
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name);
    }

    async function formSubmit() {
        try {
            if (skillId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !skillUserIds.includes(selectedUserId));
                const usersToRm = skillUserIds.filter((skillUserId) => !selectedUserIds.includes(skillUserId));

                await dispatchService.updateSkill(skillId, {
                    name,
                    description,
                    usersToAdd,
                    usersToRm,
                });
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                onClose();
                return;
            }

            await dispatchService.createSkill({
                name,
                description,
                usersToAdd: selectedUsers.map(item => Number(item.id)),
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 635 }]}>
            <Tabs tabs={[{
                key: 'details',
                label: translate('details')
            }, {
                key: 'users',
                label: translate('users')
            }]}>
                {({ selectedTab }) => {
                    if (selectedTab == 'details') {
                        return (<>
                            <FormInput
                                label={translate('name')}
                                placeholder={translate('name')}
                                value={name}
                                onChangeText={setName}
                                invalid={isEmpty}
                            />
                            <FormInput
                                label={translate('description')}
                                placeholder={translate('description')}
                                value={description}
                                onChangeText={setDescription}
                                invalid={() => false}
                            />
                        </>);
                    }
                    if (selectedTab == 'users') {
                        return <SelectableList
                            selectableLabel={translate('users')}
                            selectableContent={selectableUsers}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedUsers}
                        />;
                    }
                }}
            </Tabs >
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            height: 500,
            justifyContent: 'space-between',
            rowGap: 10,
        },
    });
}
