import React, { useContext } from 'react';
import { FlexAlignType, View } from 'react-native';
import { useSafeAreaInsets, SafeAreaProvider } from 'react-native-safe-area-context';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';

interface DefaultPageContainerProps {
    children: React.ReactNode | undefined;
    customBackgroundColor?: string;
    customTopInsetColor?: string;
    excludeBottomPadding?: boolean;
    justifyContent?: | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
    alignItems?: FlexAlignType;
}

export default function DefaultPageContainer(props: DefaultPageContainerProps) {
    const { theme } = useContext(ThemeContext);
    const insets = useSafeAreaInsets();

    return (
        <>
            <SafeAreaProvider
                initialSafeAreaInsets={insets}
                style={{ flex: 0, backgroundColor: props.customTopInsetColor || theme === 'light' ? '#000028' : '#181A1B', paddingTop: insets.top }}
            />
            <SafeAreaProvider
                initialSafeAreaInsets={insets}
                style={{
                    flex: 1,
                    backgroundColor: getThemedColor(theme, "#F7F7F7"),
                    paddingBottom: props.excludeBottomPadding ? 0 : insets.bottom,
                }}
            >
                <View style={{ flex: 1, backgroundColor: props.customBackgroundColor || getThemedColor(theme, "#F7F7F7"), justifyContent: props.justifyContent, alignItems: props.alignItems }}>
                    <meta name="theme-color" content={props.customTopInsetColor || theme === 'light' ? '#000028' : '#181A1B'} />
                    <meta name="apple-mobile-web-app-status-bar-style" content={props.customTopInsetColor || theme === 'light' ? '#000028' : '#181A1B'} />
                    <>
                        {props.children}
                    </>
                </View>
            </SafeAreaProvider>
        </>
    );
}

