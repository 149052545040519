import AsyncStorage from "@react-native-async-storage/async-storage";

export class ExtendableError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

export class UnexpectedError extends ExtendableError { }

export class Unauthorized extends ExtendableError { }

export class ClientError extends ExtendableError { }

export class NotFound extends ExtendableError { }

export class InvalidValue extends ExtendableError {
    field: string | undefined;
    constructor(field?: string) {
        super(`Invalid value used on '${field}' field.`);
        this.field = field;
    }
}

export class BaseService {
    centralEndpoint: string;

    constructor() {
        if (!process.env.CENTRAL_API_ENDPOINT) {
            throw new Error('Missing CENTRAL_API_ENDPOINT environment variable');
        }
        this.centralEndpoint = process.env.CENTRAL_API_ENDPOINT;
    }

    /**
     * @returns Returns the registered token on cache.
     */
    async getToken() {
        return await AsyncStorage.getItem("BEARER_TOKEN");
    }

    /**
     * @returns Returns values converted into query params
     */
    encodeQueryParams(params: { [key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined; }) {
        const esc = encodeURIComponent;

        const query = Object.keys(params).map(k => {
            const value = params[k];
            if (value === undefined) {
                return;
            }
            if (Array.isArray(value)) {
                return value.map((param) => `${esc(k)}=${esc(param)}`).join('&');
            }
            return `${esc(k)}=${esc(value)}`;
        }).filter(each => each).join('&');

        return "?" + query;
    }

    /**
     * @returns Returns the headers with authentication and content-type
     */
    getHeaders(token: string | null, contentType?: string) {
        const headers = new Headers();

        if (token) {
            headers.set("Authorization", "Bearer " + token);
        }

        if (contentType) {
            headers.set("Content-Type", contentType);
        }

        return headers;
    }

}

export const baseService = new BaseService();
