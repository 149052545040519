import { useWindowDimensions } from "react-native";

export function WindowInformation() {
    const { width, height } = useWindowDimensions();

    const info = {
        isLaptopOrDesktop: true,
        isMobile: false,
        isMobileOrTablet: false,
        isTablet: false,
        portrait: false,
        width,
        height
    };

    if (height > width) {
        info.portrait = true;
    }

    if (width < 1224) {
        info.isMobileOrTablet = true;
        info.isTablet = true;
        info.isLaptopOrDesktop = false;
    }

    if (width <= 768) {
        info.isMobile = true;
    }

    return info;
}
