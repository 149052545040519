import React, { useContext } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import RadioButtonGroup, { RadioButtonItem } from 'expo-radio-button';
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';

interface MyCheckboxParameters<T> {
    options: { label: string; value: T; }[];
    selected: string;
    editable?: boolean;
    setSelected: (value: T) => void;
    style?: StyleProp<ViewStyle>;
}

export default function MyRadio<T>({ options, selected, setSelected, style, editable = true }: MyCheckboxParameters<T>) {
    const { theme } = useContext(ThemeContext);

    return (
        <View style={!style ? styles.radioContainer : style}>
            <RadioButtonGroup
                containerStyle={!style ? styles.radioContainer : style}
                selected={selected}
                onSelected={(value: T) => {
                    if (editable) {
                        setSelected(value);
                    }
                }}
                radioBackground={getThemedColor(theme, '#222222')}
            >
                {
                    options.map((obj, i) => (
                        <RadioButtonItem
                            key={i}
                            value={obj.value}
                            label={<MyAppText style={styles.label}>{obj.label}</MyAppText>}
                        />
                    ))
                }
            </RadioButtonGroup>
        </View >
    );
}

const styles = StyleSheet.create({
    radioContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10
    },
    label: {
        fontSize: 14,
        fontFamily: 'Open Sans',
    }
});
