import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { translate } from "../../../services/translate";
import FormInput from "../../../components/formInput";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { centralAPI } from '../../../services/central-api';
import MyAppText from '../../MyAppText';
import MyDropDownPicker from '../../MyDropDownPicker';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyPhoneInput from '../../MyPhoneInput';

let timer: NodeJS.Timeout;

export default function OccurrenceFormDefault({
    setRequester,
    setPhone,
    setOccurrenceTypeId,
    setPin,
    requester,
    phone,
    currentAddress,
    setCurrentAddress,
    occurrenceTypeId,
}: {
    setRequester: (name: string) => void;
    setPhone: (phone: string) => void;
    setOccurrenceTypeId: React.Dispatch<React.SetStateAction<number | undefined>>;
    setPin: (pos: LatLng) => void;
    requester: string;
    phone: string;
    currentAddress?: string;
    setCurrentAddress: (value: string) => void;
    occurrenceTypeId: number | null;
}) {
    const { theme } = useContext(ThemeContext);
    const [address, setAddress] = useState<string>();
    const [occurrenceTypeList, setOccurrenceTypeList] = useState<OccurrenceType[]>([]);
    const [occurrenceTypePickerOpen, setOccurrenceTypePickerOpen] = useState(false);
    const styles = getStyles(theme);


    async function getOccurrenceTypes() {
        try {
            const response = await dispatchService.getOccurrenceTypes({ page: 0, limit: 9999, isActive: true });
            setOccurrenceTypeList(response.rows);
        } catch (err) {
            console.error(err);
        }
    }

    function getPlacesByAddress(delay: number) {
        clearTimeout(timer);

        timer = setTimeout(async () => {
            try {
                if (address) {
                    setCurrentAddress(address);
                    const response = await centralAPI.getPlaceByAddress(address);
                    setPin(response?.candidates[0]?.geometry?.location);
                }
            } catch (err) {
                console.error(err);
            }
        }, delay);

    }

    useEffect(() => {
        getOccurrenceTypes();
    }, []);


    useEffect(() => {
        getPlacesByAddress(700);
    }, [address]);

    return (
        <View style={{ rowGap: 20 }}>
            <FormInput
                label={translate('requester')}
                placeholder={translate('requester')}
                value={requester}
                onChangeText={setRequester}
                invalid={(value: string) => {
                    return value == '';
                }}
            />

            <MyPhoneInput
                label={translate('phone')}
                placeholder={translate('phone')}
                value={phone}
                onChangeText={setPhone}
                invalid={(value: string) => {
                    return value == '' || value.length < 10;
                }}
            />
            <View style={{ rowGap: 5, zIndex: 4 }}>
                <MyAppText style={styles.label}>{translate('vehicle_metadata_type')}</MyAppText>
                <MyDropDownPicker
                    open={occurrenceTypePickerOpen}
                    setOpen={setOccurrenceTypePickerOpen}
                    onOpen={() => {
                    }}
                    value={occurrenceTypeId}
                    setValue={setOccurrenceTypeId}
                    items={occurrenceTypeList.map((item: OccurrenceType) => {
                        return {
                            label: item.name, value: item.id
                        };
                    })}
                    searchable={true}
                    zIndex={4}
                />
            </View>

            <View style={{ rowGap: 5, zIndex: 3 }}>
                <MyAppText style={styles.label}>{translate('location')}</MyAppText>
                <MyAppText style={{ fontSize: 16 }}>
                    <GooglePlacesAutocomplete
                        apiOptions={{
                            language: "pt-BR",
                            region: "BR",
                        }}
                        autocompletionRequest={{
                            bounds: [
                                { lat: -24, lng: -47 },
                                { lat: -22, lng: -45 }
                            ],
                            componentRestrictions: { country: "br" }
                        }}
                        selectProps={{
                            onChange: (value) => {
                                setAddress(value?.label);
                            },
                            onInputChange: (value) => {
                                setAddress(value);
                            },
                            value: new Option(currentAddress),
                            styles: {
                                singleValue(base) {
                                    base.color = getThemedColor(theme, '#222222');
                                    return base;
                                },
                                placeholder(base) {
                                    base.color = getThemedColor(theme, '#CBCBCB');
                                    return base;
                                },
                                input(base) {
                                    base.color = getThemedColor(theme, '#222222');
                                    return base;
                                },
                                control(base) {
                                    base.backgroundColor = getThemedColor(theme, '#FFFFFF');
                                    base.borderColor = getThemedColor(theme, '#CCCCCC');
                                    return base;
                                },
                                menuList(base) {
                                    base.color = getThemedColor(theme, '#222222');
                                    base.backgroundColor = getThemedColor(theme, '#FFFFFF');
                                    return base;
                                },

                            }
                        }}

                    />
                </MyAppText>
            </View>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
    });
}
