import { BaseService, Unauthorized, UnexpectedError } from "./base-service";


export interface GetEventsParameters {
    sinceHours: number;
    limit?: number;
    triggerType?: TriggerType | 'manual';
    priority?: OccurrencePriorities
}

class EventsService extends BaseService {
    async getEvents(params: GetEventsParameters): Promise<OccurrenceEvent[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/events${this.encodeQueryParams({
            ...params,
            limit: 1000,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }
}

export const eventsService = new EventsService();
