import React, { useContext, useEffect, useState } from "react";
import DefaultPageContainer from "../components/DefaultPageContainer";
import { View, StyleSheet, TouchableOpacity, ActivityIndicator, Image, NativeSyntheticEvent, TextInputKeyPressEventData, Modal } from "react-native";
import { translate } from "../services/translate";
import { centralAPI } from "../services/central-api";
import { invalidFirebaseToken, notificationService, tryingToCreateFirebaseTokenBeforeAuth } from "../services/notification";
import MyAppText from "../components/MyAppText";
import ThemeContext from "../context/Theme";
import getThemedColor from "../services/get-themed-color";
import app from '../services/version.json';
import FormInput from "../components/formInput";
import FormActions from "../components/formActions";
import PasswordInput from "../components/passwordInput";
import { ClientError, UnexpectedError } from "../services/central-api/base-service";

interface LoginComponentProps {
    navigation: Navigation;
}

export default function Login({ navigation }: LoginComponentProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [isModalVisible, setModalVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const isAuthenticated = await centralAPI.isAuthenticated();
                if (isAuthenticated) {
                    await centralAPI.saveLocationToken();
                    navigation.navigate('Menu');
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);


    async function login() {
        if (email == '' || password == '') {
            return;
        }
        setLoading(true);
        try {
            await centralAPI.authenticate({ email, password });
            navigation.navigate('Menu');

            try {
                await notificationService.registerToken();
                console.log("firebase token registered.");
                await centralAPI.saveLocationToken();
            } catch (err) {
                if (err instanceof invalidFirebaseToken || err instanceof tryingToCreateFirebaseTokenBeforeAuth) {
                    console.warn(err);
                    return;
                }
                console.error(err);
                return;
            }
        } catch (err) {
            console.log(err);
            if (err instanceof ClientError) {
                setErrorMessage(translate(err.message));
            } else if (err instanceof UnexpectedError || err instanceof TypeError) {
                setErrorMessage(translate('unexpectedError'));
            } else if (err instanceof DOMException) {
                setErrorMessage(translate('timeoutLoginException'));
            }

        } finally {
            setLoading(false);
        }
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            if (email == '' || password == '') {
                return;
            }
            login();
        }
    }

    async function forgotPassword() {
        try {
            await centralAPI.forgotPassword(email);
            setModalVisible(false);
            setPassword('');
            setEmail('');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <DefaultPageContainer>
            <View style={styles.container}>
                <View style={styles.imageView}>
                    <Image source={require(`../../assets/smartsampa_logo_${theme}.png`)} style={styles.image}></Image>
                </View>
                <View style={styles.formContainer}>
                    <FormInput
                        label={translate('email')}
                        placeholder=''
                        value={email}
                        onChangeText={setEmail}
                        invalid={() => false}
                        keyboardType="email-address"
                        onKeyPress={handleKeyDown}
                    />
                    <PasswordInput
                        label={translate('password')}
                        placeholder=''
                        value={password}
                        onChangeText={setPassword}
                        invalid={() => password === ''}
                        onKeyPress={handleKeyDown}
                    />
                    {errorMessage ? <MyAppText style={styles.error}>{errorMessage}</MyAppText> : null}
                    {loading ?
                        <View style={{ height: 40 }}>
                            <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                        </View>
                        : <TouchableOpacity style={[{ height: 40 }, styles.loginButton]} onPress={login}>
                            <MyAppText style={styles.loginButtonText}>{translate('login')}</MyAppText>
                        </TouchableOpacity>
                    }
                    <TouchableOpacity onPress={() => setModalVisible(true)}>
                        <MyAppText style={[styles.label, { textDecorationLine: 'underline' }]}>
                            {translate('forgotPassword')}
                        </MyAppText>
                    </TouchableOpacity>
                    <MyAppText style={{ alignSelf: 'center' }}>{translate('version')}: {app[0].version}</MyAppText>
                </View>
            </View>
            <Modal visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)} transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalBody}>
                        <View style={{ rowGap: 10 }}>
                            <MyAppText style={styles.modalHeaderText}>
                                {translate('forgotYourPassword')}
                            </MyAppText>
                            <MyAppText style={styles.modalBodyText}>
                                {translate('emailToRedefinePassword')}
                            </MyAppText>
                        </View>
                        <View style={{ height: 65, maxWidth: 380, minWidth: 300 }}>
                            <FormInput
                                label={translate('email')}
                                placeholder={translate('email')}
                                value={email}
                                onChangeText={setEmail}
                                invalid={() => false}
                            />
                        </View>
                        <FormActions
                            onSubmit={forgotPassword}
                            onClose={() => setModalVisible(false)}
                            disabled={email === ''}
                            confirmText={'send'} />
                    </View>
                </View>
            </Modal>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            gap: 50,
        },
        formContainer: {
            flexDirection: 'column',
            gap: 30,
            maxWidth: 375,
            width: '100%',
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
            alignSelf: 'center',
        },
        loginButton: {
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 5,
            justifyContent: 'center'
        },
        loginButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 18,
            textAlign: 'center',
        },
        error: {
            color: 'red',
            alignSelf: 'center',
            height: 20
        },
        image: {
            width: '100%',
            resizeMode: 'contain',
            height: '100%',
        },
        imageView: {
            width: 260,
            height: 40,
        },
        modalContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#31313199',
            flex: 1,
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            paddingHorizontal: 90,
            paddingVertical: 50,
            rowGap: 20,
            maxWidth: 570,
            height: 325
        },
        modalHeaderText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        modalBodyText: {
            fontSize: 14,
            color: getThemedColor(theme, '#888888'),
        },
    });
}
