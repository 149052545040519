import React from 'react';
import LateralMenu from "../LateralMenu";
import { faFileContract, faFolderOpen, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import logoEvent from '../../../assets/logo_logs.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'logs' | 'reports';

interface LogsLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function LogsLateralMenu({ navigation, selectedMenu }: LogsLateralMenuProps) {
    return <LateralMenu
        topImage={logoEvent}
        items={[{
            icon: faFileContract,
            text: translate('logRegistries'),
            action: () => navigation.navigate('Logs', { screen: 'LogList' }),
            selected: selectedMenu == 'logs'
        }, {
            icon: faFolderOpen,
            text: translate('reports'),
            action: () => navigation.navigate('Logs', { screen: 'Reports' }),
            selected: selectedMenu == 'reports'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
