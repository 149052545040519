import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    Image,
} from 'react-native';


import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import EventList from '../../components/facial/EventList';
import { Slider } from '@miblanchard/react-native-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../components/Tooltip';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { MoreThanOneFaceOnPhoto, NoFaceOnPhoto } from '../../services/central-api/facial';
import { smartSearchFacialService } from '../../services/central-api/smart-search-facial';
import Toast from 'react-native-toast-message';

interface SmartSearchFacialParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}


export default function SmartSearchFacial({ navigation, authenticatedUser }: SmartSearchFacialParams) {
    const { theme } = useContext(ThemeContext);

    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [file, setFile] = useState<{ file: File, objectURI: string; }>();
    const [thresholdSlider, setThresholdSlider] = useState(834);
    const [threshold, setThreshold] = useState(thresholdSlider / 1000);
    const [slidingTimeout, setSlidingTimeout] = useState<NodeJS.Timeout>();


    const [isLoading, setIsLoading] = useState(false);
    const [occurrences, setOccurrences] = useState<FacialDetection[]>([]);

    async function getFacialDetections(file: File) {
        try {
            setIsLoading(true);

            const response = await smartSearchFacialService.findDetectionByPicture({ photo: file, threshold });
            setOccurrences(response.detections);
            forceUpdate();
        } catch (err) {
            if (err instanceof NoFaceOnPhoto || err instanceof MoreThanOneFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxWarning',
                    text1: translate(err.message),
                });
            }
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        // debounce
        if (slidingTimeout) {
            clearTimeout(slidingTimeout);
        }

        const timeout = setTimeout(() => {
            setThreshold(thresholdSlider / 1000);
        }, 1000);

        setSlidingTimeout(timeout);

    }, [thresholdSlider]);

    useEffect(() => {
        if (file) {
            getFacialDetections(file.file);
        }
    }, [threshold, file]);


    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='smartSearchFacial' lateralMenu='smartSearchFacial' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <TouchableOpacity
                            style={{
                                borderWidth: 0,
                                borderRadius: 2,
                                backgroundColor: getThemedColor(theme, '#000028'),
                                width: 256,
                                height: 40,
                                justifyContent: 'center',
                            }}
                        >
                            <label style={{ display: 'grid', justifyContent: 'center' }}>
                                <input
                                    style={{ display: 'none' }}
                                    type='file'
                                    onChange={(e) => {
                                        if (!e.target.files) {
                                            return;
                                        }
                                        const file = e.target.files[0];
                                        setFile({ file, objectURI: URL.createObjectURL(file) });
                                    }}
                                    accept='image/*'
                                />
                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', columnGap: 20 }}>
                                    <FontAwesomeIcon icon={faFileUpload} color={getThemedColor(theme, '#FFFFFF')} />
                                    <MyAppText style={{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }}>
                                        {translate('chooseFile')}
                                    </MyAppText>
                                </View>
                            </label>
                        </TouchableOpacity>

                        {file ?
                            <Image
                                style={{
                                    height: 100,
                                    width: 90,
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                }}
                                source={{
                                    uri: file.objectURI
                                }}
                            />
                            :
                            <View style={{
                                height: 100,
                                width: 90,
                                borderRadius: 5,
                                overflow: 'hidden',
                                backgroundColor: getThemedColor(theme, '#000028'),
                            }} />
                        }
                        <Tooltip tooltipDirection='right' tooltipContent={translate('thresholdDetail')} width={300}>
                            <View style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderWidth: 1,
                                borderColor: getThemedColor(theme, '#000000'),
                                paddingHorizontal: 3,
                                height: 40,
                                width: 256,
                                columnGap: 20
                            }}>
                                <View style={styles.rowSlider}>
                                    <Slider
                                        animateTransitions
                                        minimumValue={0}
                                        maximumValue={1000}
                                        minimumTrackTintColor={getThemedColor(theme, '#000000')}
                                        thumbTintColor={getThemedColor(theme, '#000000')}
                                        value={thresholdSlider}
                                        step={1}
                                        onValueChange={(value) => setThresholdSlider(value[0])}
                                    />
                                </View>
                                <MyAppText>
                                    {(thresholdSlider / 1000).toFixed(3)}
                                </MyAppText>
                            </View>
                        </Tooltip>
                    </View>
                    {occurrences.length ?
                        <EventList
                            events={occurrences}
                            navigation={navigation}
                            hasMoreResults={false}
                            isLoading={isLoading}
                            isPolling={false}
                            page='smartSearchFacial'
                        /> : null
                    }
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        rowGap: 15
    },
    rowSlider: {
        width: 150,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        flexWrap: 'wrap'
    },
});
