import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { cameraService } from '../../services/central-api/cameras';
import moment from 'moment';
import MyDataTable from '../../components/MyDataTable';
import MyAppText from '../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../typings/Params';

export default function Downloads({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const route = useRoute<RouteProp<CamerasParamList, 'Downloads'>>();

    // filters
    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number }>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: 25
    });

    const [pageCount, setPageCount] = useState(1);
    const [downloads, setDownloads] = useState<Download[]>([]);
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [isDownloading, setIsDownloading] = useState(false);
    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    async function getDownloads() {
        try {
            const response = await cameraService.getDownloads(filters);
            setDownloads(response.rows);
            setPageCount(Math.ceil(response.count / filters.limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getDownloads();
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('cameras/downloads')) {
                return;
            }
            getDownloads();
        }, 15000);

        return () => clearInterval(interval);
    }, [filters]);

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter
        });

        navigation.setParams({
            page: 1,
            textFilter
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='downloads' lateralMenu='cameras' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                            <MyAppText style={styles.filterText}>{translate('searchDownload')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onKeyPress={handleKeyDown}
                                onChangeText={setTextFilter}
                                placeholder={translate('typeToSearch')}
                            />
                        </View>

                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.button} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.buttonText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true,
                                },
                                {
                                    name: translate('user'),
                                    selector: row => row.user?.name || '',
                                },
                                {
                                    name: translate('camera'),
                                    selector: row => row.camera?.title || '',
                                    wrap: true,
                                },
                                {
                                    name: translate('status'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center' }}>
                                            {row.status == 'pending' ?
                                                <ActivityIndicator size={16} color={getThemedColor(theme, '#000000')} /> :
                                                <FontAwesomeIcon style={row.status == 'success' ? styles.success : styles.failed} fontSize={16} icon={row.status == 'success' ? faCheckCircle : faTimesCircle}></FontAwesomeIcon>
                                            }

                                            <MyAppText style={row.status == 'success' ? styles.success : row.status == 'failed' ? styles.failed : {}}>{translate(row.status)}</MyAppText>
                                        </View>
                                },
                                {
                                    name: translate('createdAt'),
                                    selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                                },
                                {
                                    name: translate('tags'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                            {row.tags ?
                                                row.tags.map((tag) =>
                                                    <View key={tag.id} style={[styles.tagMarker, { backgroundColor: tag.color }]}>
                                                        <MyAppText style={[styles.tagMarkerText]}>
                                                            {tag.name}
                                                        </MyAppText>
                                                    </View>
                                                ) : null
                                            }</View>
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <TouchableOpacity disabled={isDownloading} onPress={async () => {
                                            try {
                                                setIsDownloading(true);
                                                const video = await cameraService.getDownload(row.id);
                                                window.open(video.resultUrl);
                                            } catch (error) {
                                                Toast.show({
                                                    type: 'sentinelxError',
                                                    text1: translate('unexpectedError'),
                                                });
                                            } finally {
                                                setIsDownloading(false);
                                            }
                                        }}>
                                            {row.status == 'success' && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_watch_download) ? <FontAwesomeIcon fontSize={16} color={getThemedColor(theme, '#58595B')} icon={faDownload}></FontAwesomeIcon> : null}
                                        </TouchableOpacity>
                                }
                            ]}
                            data={downloads}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            flexWrap: 'wrap'
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        success: {
            color: '#017B27'
        },
        failed: {
            color: '#B40119'
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        tagMarker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 8,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        tagMarkerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        buttonsContainer: {
            marginTop: 20,
            marginLeft: 'auto',
        },
        button: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        buttonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
    });
}
