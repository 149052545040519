import React, { useContext } from 'react';
import ThemeContext from '../context/Theme';
import DataTable, { TableProps, createTheme } from 'react-data-table-component';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';

export default function MyDataTable<T>(props: TableProps<T>) {
    const { theme } = useContext(ThemeContext);
    createTheme('dark', {
        text: {
            primary: '#D3CFC9',
        },
        background: {
            default: '#181A1B',
        },
        context: {
            color: '#D3CFC9',
        }
    }, 'dark');

    return <ScrollView>
        <DataTable
            {...props}
            noDataComponent={<MyAppText style={{ color: getThemedColor(theme, '#58595B'), paddingTop: 10 }}>
                {translate('noResults')}.
            </MyAppText>}
            customStyles={{
                rows: {
                    style: {
                        fontFamily: 'Open Sans',
                        fontSize: 14,
                    },
                },
                headCells: {
                    style: {
                        fontFamily: 'Open Sans',
                        fontSize: 13,
                        fontWeight: 'normal'
                    },
                },
            }}
            progressComponent={<View style={{ paddingTop: 20 }}><ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} /></View>}
            theme={theme}
        />
    </ScrollView>;
}
