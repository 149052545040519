import { BaseService, ClientError, InvalidValue, NotFound, Unauthorized, UnexpectedError } from "./base-service";

export interface getUsersParameters {
    page: number;
    limit?: number;
    textFilter?: string;
}

interface createGroupParameters {
    name: string;
    description: string;
    permissions: GroupPermissions;
    /**
     * List of user IDs to be added
     */
    usersToAdd: number[];
    /**
     * List of cameras IDs to be added
     */
    camerasToAdd: string[];
}

interface createInstallationCompany {
    name: string;
    phone: string;
}

interface updateInstallationCompany {
    name?: string;
    phone?: string;
}

interface createInternetCompany {
    name: string;
    phone: string;
    responsible: string;
}

interface updateInternetCompany {
    name?: string;
    phone?: string;
    responsible?: string;
}

interface updateGroupParameters extends createGroupParameters {
    /**
     * List of user IDs to be removed
     */
    usersToRm: number[];
    /**
     * List of cameras IDs to be removed
     */
    camerasToRm: string[];
}

interface UserParameters {
    name: string;
    email: string;
    registry: string;
    phone: string;
    /**
     * List of group IDs to be added
     */
    groupsToAdd: number[];
    /**
     * List of permission level IDs to be added
     */
    permissionLevelsToAdd: number[];
    /**
     * List of skills IDs to be added
     */
    skillsToAdd: number[];
    roleId: number;
    unitId: number;
    patent?: string;
    warName?: string;
    tags?: number[];
}

interface createUserParameters extends UserParameters {
    password: string;
    actingBodyId?: number;
}

interface updateUserParameters extends UserParameters {
    /**
     * List of group IDs to be removed
     */
    groupsToRm: number[];
    /**
     * List of permission level IDs to be removed
     */
    permissionLevelsToRm: number[];
    /**
     * List of skills IDs to be removed
     */
    skillsToRm: number[];
    password?: string;
    actingBodyId?: number;
}

interface createPermissionLevelParameters {
    name: string;
    description: string;
    permissions: UserPermissions;
    /**
     * List of user IDs to be added
     */
    usersToAdd: number[];
}

interface updatePermissionLevelParameters extends createPermissionLevelParameters {
    /**
     * List of user IDs to be removed
     */
    usersToRm: number[];
}

interface updateTurnstileParameters {
    /**
     * List of user IDs to be added
     */
    usersToAdd: number[];
    /**
     * List of user IDs to be removed
     */
    usersToRm: number[];
}

class AccessService extends BaseService {

    async getAccessAllUsers({ textFilter, actingBodyId, actingBodyUnitId }: {
        textFilter?: string;
        actingBodyId?: number;
        actingBodyUnitId?: number;
    }): Promise<AccessSimplifiedUserData[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/access/users${this.encodeQueryParams({ textFilter, actingBodyId, actingBodyUnitId })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });
        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getActingBodies(params: {
        page: number;
        limit: number;
    }): Promise<{ count: number; rows: ActingBody[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/acting-bodies${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getAllCameras({ textFilter }: { textFilter?: string; }): Promise<AccessSimplifiedCameraData[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/access/cameras${this.encodeQueryParams({ textFilter })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        const cameraList = await res.json();

        return cameraList.map((camera: AccessSimplifiedCameraData) => {
            if (!camera.tagList) return camera;

            //We do not want to filter on the back, but it must be done somewhere
            camera.tagList = JSON.parse(String(camera.tagList));
            return camera;
        });

    }

    async getUsers({ page, limit, textFilter }: getUsersParameters): Promise<{ count: number; rows: UserData[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/users${this.encodeQueryParams({ page, limit, textFilter })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUser(id: number): Promise<UserData & { Groups: GroupData[]; PermissionLevels: PermissionLevelData[]; Skills: Skill[]; tags: Tag[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} EmailAlreadyExists
     * @returns
     */
    async createUser(params: createUserParameters): Promise<UserData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} EmailAlreadyExists
     * @returns
     */
    async updateUser(id: number, params: updateUserParameters): Promise<UserData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteUser(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getGroups({ page, limit, actingBodyUnitId }: { page?: number; limit?: number; actingBodyUnitId?: number; }): Promise<{ count: number; rows: GroupData[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/groups${this.encodeQueryParams({ page, limit, actingBodyUnitId })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getGroup(id: number): Promise<AccessSimplifiedGroupData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/group/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createGroup({ name, description, usersToAdd, camerasToAdd, permissions, unitId }: (createGroupParameters & { unitId: number; })): Promise<GroupData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/group`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description, usersToAdd, camerasToAdd, permissions, unitId }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteUnit(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async updateGroup(id: number, { name, description, usersToAdd, camerasToAdd, usersToRm, camerasToRm, permissions }: updateGroupParameters): Promise<GroupData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/group/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description, usersToAdd, camerasToAdd, usersToRm, camerasToRm, permissions }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteGroup(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/group/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async deleteRole(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/role/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async getPermissionLevels({ page, limit, actingBodyUnitId }: { page?: number; limit?: number; actingBodyUnitId?: number; }): Promise<{ count: number; rows: PermissionLevelData[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/permission-levels${this.encodeQueryParams({ page, limit, actingBodyUnitId })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getHikTurnstiles({ page, limit }: { page?: number; limit?: number; }): Promise<{ count: number; rows: HikTurnstile[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/hik-turnstiles${this.encodeQueryParams({ page, limit })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getHikTurnstile(id: number): Promise<HikTurnstile & { Users: UserData[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/hik-turnstile/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateHikTurnstile(id: number, { usersToAdd, usersToRm }: updateTurnstileParameters): Promise<HikTurnstile> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/hik-turnstile/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                usersToAdd,
                usersToRm,
            }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPermissionLevel(id: number): Promise<PermissionLevelData & { Users: UserData[]; permissionObj: UserPermissions; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/permission-level/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createPermissionLevel({ name, description, usersToAdd, permissions, unitId }: (createPermissionLevelParameters & { unitId: number; })): Promise<PermissionLevelData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/permission-level`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description, usersToAdd, permissions, unitId }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updatePermissionLevel(id: number, { name, description, usersToAdd, usersToRm, permissions }: updatePermissionLevelParameters): Promise<PermissionLevelData> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/permission-level/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description, usersToAdd, usersToRm, permissions }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deletePermissionLevel(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/permission-level/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getActingBody(id: number): Promise<ActingBody> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/acting-body/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteActingBody(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/acting-body/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createActingBody(actingBody: ActingBody): Promise<ActingBody> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/acting-body`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(actingBody)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateActingBody(actingBody: ActingBody): Promise<ActingBody> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/acting-body/${actingBody.id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(actingBody)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getInstallationCompanies({ page, limit }: { page?: number; limit?: number; }): Promise<{ count: number; rows: InstallationCompanyList[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/installation-companies${this.encodeQueryParams({ page, limit })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {NotFound}
     * @returns
     */
    async getInstallationCompany(id: number): Promise<InstallationCompany> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/installation-company/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {InvalidValue}
     * @returns
     */
    async createInstallationCompany({ name, phone }: createInstallationCompany): Promise<InstallationCompany> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/installation-company`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, phone }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {NotFound}
     * @returns
     */
    async updateInstallationCompany(id: number, { name, phone }: updateInstallationCompany): Promise<InstallationCompany> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/installation-company/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, phone }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {NotFound}
     * @returns
     */
    async deleteInstallationCompany(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/installation-company/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getInstallationCompaniesSimplified(): Promise<SimplifiedInstallationCompany[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/installation-companies-simplified`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getInternetCompanies({ page, limit }: { page?: number; limit?: number; }): Promise<{ count: number; rows: InternetCompanyList[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/internet-companies${this.encodeQueryParams({ page, limit })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {NotFound}
     * @returns
     */
    async getInternetCompany(id: number): Promise<InternetCompany> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/internet-company/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {InvalidValue}
     * @returns
     */
    async createInternetCompany({ name, phone, responsible }: createInternetCompany): Promise<InternetCompany> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/internet-company`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, phone, responsible }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {NotFound}
     * @returns
     */
    async updateInternetCompany(id: number, { name, phone, responsible }: updateInternetCompany): Promise<InternetCompany> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/internet-company/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, phone, responsible }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {NotFound}
     * @returns
     */
    async deleteInternetCompany(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/internet-company/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getInternetCompaniesSimplified(): Promise<SimplifiedInternetCompany[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/internet-companies-simplified`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUserTags(): Promise<Tag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/tags`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createUserTag(params: CreateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/tag`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateUserTag(id: number, params: UpdateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/tag/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteUserTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/user/tag/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

}

export const accessService = new AccessService();
