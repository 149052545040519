import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import MyAppText from '../../../components/MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Hoverable } from 'react-native-web-hover';

interface Props {
    onPress: VoidFunction;
    value: string;
}

function TimeButton({ onPress, value }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <TouchableOpacity onPress={onPress}>
            <Hoverable>
                {({ hovered }) => (
                    <View style={styles.buttonContainer(hovered)}>
                        <MyAppText style={styles.buttonText}>{value}</MyAppText>
                    </View>
                )}
            </Hoverable>
        </TouchableOpacity>
    );
}

const stylesheet = createStyleSheet(theme => ({
    buttonContainer: (hovered: boolean) => ({
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 50,
        paddingHorizontal: 10,
        width: '100%',
        height: 20,
        backgroundColor: hovered ? theme.colors.buttonHover : theme.colors.buttonBackground,
        justifyContent: 'center'
    }),

    buttonText: {
        color: theme.colors.color,
    },
}));

export default TimeButton;
