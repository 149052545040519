import React from 'react';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { OccurrenceDetail } from '../../components/occurrence/OccurrenceDetail';


interface EventsOccurrenceDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            occurrenceId: string;
        };
    };
}

export default function EventsOccurrenceDetail({ navigation, route, authenticatedUser }: EventsOccurrenceDetailParams) {
    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'events'} selectedMenu='events' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <OccurrenceDetail navigation={navigation} authenticatedUser={authenticatedUser} occurrenceId={route.params.occurrenceId} page={'events'} />
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}
