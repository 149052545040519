import React from 'react';
import LateralMenu from "../LateralMenu";
import { faCar, faClipboardList, faFolderOpen, faRightFromBracket, faSearch, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import logoLpr from '../../../assets/logo_lpr.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'search' | 'plates' | 'liveEvents' | 'liveAlerts' | 'reports';

interface LprLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function LprLateralMenu({ navigation, selectedMenu }: LprLateralMenuProps) {
    return <LateralMenu
        topImage={logoLpr}
        items={[{
            icon: faClipboardList,
            text: translate('liveEvents'),
            action: () => navigation.navigate('LPR', { screen: 'LiveEvents' }),
            selected: selectedMenu == 'liveEvents'
        }, {
            icon: faTriangleExclamation,
            text: translate('liveAlerts'),
            action: () => navigation.navigate('LPR', { screen: 'LiveAlerts' }),
            selected: selectedMenu == 'liveAlerts'
        }, {
            icon: faSearch,
            text: translate('search'),
            action: () => navigation.navigate('LPR', { screen: 'LPRSearch' }),
            selected: selectedMenu == 'search'
        }, {
            icon: faCar,
            text: translate('plates'),
            action: () => navigation.navigate('LPR', { screen: 'Plates' }),
            selected: selectedMenu == 'plates'
        }, {
            icon: faFolderOpen,
            text: translate('reports'),
            action: () => navigation.navigate('LPR', { screen: 'Reports' }),
            selected: selectedMenu == 'reports'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
