import React, { useEffect, useState } from "react";
import MainRoutes from './src/Routes';
import { getDefaultLanguage, ChangeLanguage } from './src/services/translate';
import * as serviceWorkerRegistration from "./src/services/service-worker-registration";
import { invalidFirebaseToken, notificationService, tryingToCreateFirebaseTokenBeforeAuth } from "./src/services/notification";
import { View } from "react-native";
import { ThemeProvider } from "./src/context/Theme";
import { SafeAreaProvider } from "react-native-safe-area-context";
import ToastComponent from "./src/components/ToastComponent";
import { MyMessage } from "./src/components/MyMessage";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import './src/styles/scroll-styles.css';
import AsyncStorage from "@react-native-async-storage/async-storage";

import './src/styles/unistyles';


const mapsApiKey = process.env.GOOGLE_MAPS_API || "";
export default function App() {
    const [apiLoadedAmount, setApiLoadedAmount] = useState<number>(0);
    const [didLoadApi, setDidLoadApi] = useState<boolean>(true);

    const apiList = [
        `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places,maps`,
        "https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js",
    ];

    async function loadApi(url: string) {
        return new Promise(function (resolve, reject) {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    }

    async function loadApis() {
        for (const api of apiList) {
            try {
                await loadApi(api);
            } catch (e) {
                console.error("Error loading api: ", api, ": ", e);
            } finally {
                setDidLoadApi(!didLoadApi);
            }
        }
    }

    useEffect(() => {
        loadApis();
    }, []);

    useEffect(() => {
        setApiLoadedAmount(apiLoadedAmount + 1);
    }, [didLoadApi]);


    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"></link>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet"></link>
            <DndProvider backend={HTML5Backend}>
                <ThemeProvider>
                    {apiLoadedAmount >= apiList.length ? <MainRoutes /> : <></>}
                    <View style={{ zIndex: 2147483646, width: '100%', position: 'absolute' }}>
                        <SafeAreaProvider>
                            <ToastComponent />
                            <MyMessage />
                        </SafeAreaProvider>
                    </View>
                </ThemeProvider>
            </DndProvider>

        </>
    );
}

(async () => {

    try {
        const urlParams = new URLSearchParams(window.location.search);
        const locationToken = urlParams.get('locationToken');
        if (locationToken) {
            await AsyncStorage.setItem("LOCATION_TOKEN", locationToken);
        }
    } catch (e) {
        console.error("Failed to set location token" + e);
    }

    try {
        await serviceWorkerRegistration.register();
        console.log("service worker registered.");
        await notificationService.registerToken();
        console.log("firebase token registered.");
    } catch (err) {
        if (err instanceof invalidFirebaseToken || err instanceof tryingToCreateFirebaseTokenBeforeAuth) {
            return console.warn(err);
        }
        console.error(err);
    }
})();

(async () => {
    try {
        const lang = await getDefaultLanguage();
        await ChangeLanguage(lang);
    } catch (err) {
        console.error(err);
    }
})();
