import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { View, TouchableOpacity, ImageSourcePropType } from 'react-native';
import { Hoverable, ScrollView } from 'react-native-web-hover';

import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import { lateralMenuWidth } from '../services/lateral-menu-width';

interface Item {
    icon: IconDefinition;
    action?: () => void;
    text: string;
    selected?: boolean;
    style?: React.CSSProperties;
}

interface LateralMenuParams {
    topImage?: ImageSourcePropType;
    items: Item[];
    bottomItems: Item[];
}

export default function LateralMenu({ topImage, items, bottomItems }: LateralMenuParams) {
    const { theme } = useContext(ThemeContext);

    return (
        <View style={{
            paddingHorizontal: 15,
            paddingVertical: 30,
            flex: 1,
            backgroundColor: theme === 'light' ? '#000028' : '#222426',
            width: lateralMenuWidth.getOriginalWidth(),
        }}>
            <ScrollView contentContainerStyle={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <View style={{ rowGap: 50 }}>
                    <View style={{
                        height: 24,
                        paddingLeft: 25,
                    }}>
                        {/* TODO: rollback to required when designer send a account image */}
                        {topImage ? <img src={topImage as string} style={{
                            height: '100%',
                            objectFit: 'contain',
                            width: '100%',
                            objectPosition: 'left'
                        }} /> : null}
                    </View>
                    <View>
                        {items.map(item => (
                            <TouchableOpacity
                                onPress={() => {
                                    if (!item.action) {
                                        return;
                                    }
                                    item.action();
                                }}
                                key={item.text}
                                style={{ width: '100%' }}
                            >
                                <Hoverable>
                                    {({ hovered }) => (
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                backgroundColor: item.selected ? '#fff' : (hovered ? getThemedColor(theme, '#040745') : undefined),
                                                borderRadius: 5,
                                                alignItems: 'center',
                                                paddingLeft: 25,
                                                height: 40,
                                                width: '100%',
                                                columnGap: 10
                                            }}
                                        >
                                            <FontAwesomeIcon style={{
                                                color: item.selected ? theme === 'light' ? '#000028' : '#222426' : undefined,
                                                width: 16
                                            }} fontSize={16} icon={item.icon} color='white' />
                                            <MyAppText style={{
                                                fontSize: 16,
                                                color: item.selected ? theme === 'light' ? '#000028' : '#222426' : '#fff'
                                            }}>{item.text}</MyAppText>
                                        </View>
                                    )}
                                </Hoverable>
                            </TouchableOpacity>
                        ))}
                    </View>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    {bottomItems.map(item => (
                        <TouchableOpacity
                            onPress={item.action}
                            key={item.text}
                            style={{ width: '100%' }}
                        >
                            <Hoverable>
                                {({ hovered }) => (
                                    <View style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        backgroundColor: hovered ? getThemedColor(theme, '#040745') : undefined,
                                        borderRadius: 5,
                                        height: 40,
                                        paddingLeft: 25,
                                        width: '100%',
                                        columnGap: 10
                                    }}>
                                        <FontAwesomeIcon fontSize={16} icon={item.icon} color='white' style={Object.assign({ width: 16 }, item.style)} />
                                        <MyAppText style={{
                                            fontSize: 16,
                                            color: "white"
                                        }}>{item.text}</MyAppText>
                                    </View>
                                )}
                            </Hoverable>
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
        </View >
    );
}
