import React, { useEffect, useState } from 'react';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { translate } from '../../../services/translate';
import { View } from 'react-native';
import MyAppText from '../../../components/MyAppText';

export const VIDEO_ANALYSIS_STATUS: Record<VideoAnalysisStatus, string> = {
    failed: 'failed',
    pending: 'pending',
    success: 'success'
};


interface Props {
    value?: string;
    onChange: (value: string) => void;
}

function StatusDropdown({ value, onChange }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>(value ?? '');

    useEffect(() => {
        onChange(selectedStatus);
    }, [selectedStatus]);


    const DROPDOWN_VALUES = Object.entries(VIDEO_ANALYSIS_STATUS).map(([key, value]) => ({
        label: translate(value),
        value: key
    }));

    return (
        <View style={{ gap: 5, minWidth: 200, flexGrow: 1 }}>
            <MyAppText>{translate('status')}</MyAppText>
            <MyDropDownPicker
                items={DROPDOWN_VALUES}
                value={selectedStatus}
                setValue={setSelectedStatus}
                setOpen={setIsOpen}
                open={isOpen}
                multiple={false}
                mode='BADGE'
                borderColor='#888'
            />
        </View>
    );
}

export default StatusDropdown;
