export default function stringToFileImage(imageData: string) {
    const base64ImageData = imageData.split(',')[1];

    const binaryData = atob(base64ImageData);
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    return new File([blob], 'facePicture.jpeg', { type: 'image/jpeg' });
}
