
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    TextInput,
    ActivityIndicator,
    FlatList,
    ScrollView
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { facialService } from '../../services/central-api/facial';
import { HexColorPicker } from 'react-colorful';
import DeleteModal from '../../components/DeleteModal';

interface MarkersParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

const mainContainerPadding = 10;

export default function Markers({ navigation, authenticatedUser }: MarkersParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [, setColor] = useState("#aabbcc");
    const [loading, setLoading] = useState(false);
    const [markers, setMarkers] = useState<FacialMarker[]>([]);
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [markerToDelete, setMarkerToDelete] = useState<Tag & { dirty?: boolean; activated?: boolean; }>();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

    function addItem() {
        setMarkers(markers.concat({
            name: '',
            color: `#${Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0')}`
        }));
    }

    async function getFacialMarkers() {
        try {
            setLoading(true);

            const markers = await facialService.getFacialMarkers();
            setMarkers(markers);
        } catch (err) {
            // TODO: add proper error validation
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getFacialMarkers();
    }, []);

    function renderItem({ item }: { item: FacialMarker & { dirty?: boolean; activated?: boolean; }; }) {
        return (
            <View style={{ flexDirection: 'column', rowGap: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 8 }}>
                    <TouchableOpacity
                        style={styles.circle}
                        onPress={async () => {
                            item.activated = !item.activated;
                            forceUpdate();
                        }}>

                        <View style={[styles.circle, { backgroundColor: item.color }]}>
                        </View>
                    </TouchableOpacity>

                    <TextInput
                        style={styles.input}
                        placeholder=""
                        value={item.name}
                        onChangeText={(value) => {
                            item.dirty = true;
                            item.name = value;
                            forceUpdate();
                        }}
                    />
                    <TouchableOpacity onPress={() => {
                        if (item.id) {
                            setMarkerToDelete(item);
                            setIsDeleteModalVisible(true);
                        } else {
                            setMarkers(markers.filter(marker => marker.name !== item.name || marker.color !== item.color));
                        }
                    }}>
                        <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                    </TouchableOpacity>

                    {item.dirty ?
                        <TouchableOpacity onPress={async () => {
                            try {
                                if (!item.id) {
                                    await facialService.createFacialMarker({ name: item.name, color: item.color });
                                } else {
                                    await facialService.updateFacialMarker(item.id, { name: item.name, color: item.color });
                                }
                            } catch (err) {
                                console.error(err);
                            } finally {
                                item.dirty = false;
                                item.activated = false;
                                forceUpdate();
                                await getFacialMarkers();
                            }
                        }}>
                            <FontAwesomeIcon icon={faCheck} fontSize={16} color={getThemedColor(theme, '#000028')} />
                        </TouchableOpacity> : null
                    }
                </View >

                {item.activated ?
                    <HexColorPicker color={item.color} onChange={(newColor: string) => {
                        setColor(newColor);
                        item.color = newColor;
                        item.dirty = true;
                    }} /> : null
                }
            </View >

        );
    }


    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='markers' lateralMenu='facial' contentContainerStyle={{ padding: mainContainerPadding }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                addItem();
                            }} style={styles.newButton}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newButtonText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <ScrollView contentContainerStyle={{ flex: 1 }}>
                            {loading ?
                                <ActivityIndicator size="small" color={getThemedColor(theme, '#000000')} />
                                : <FlatList contentContainerStyle={{ rowGap: 10, flex: 1, padding: 24 }} renderItem={renderItem} data={markers} />
                            }
                        </ScrollView>
                    </View>
                </View>
                <DeleteModal onSubmit={async () => {
                    try {
                        if (markerToDelete?.id) {
                            await facialService.deleteFacialMarker(markerToDelete.id);
                            await getFacialMarkers();
                            markerToDelete.dirty = false;
                            forceUpdate();
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }} isModalVisible={isDeleteModalVisible} itemName={markerToDelete?.name} setModalVisible={() => setIsDeleteModalVisible(false)} />
            </DefaultPageLayout>
        </DefaultPageContainer>);

}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        input: {
            height: 32,
            width: 300,
            borderColor: getThemedColor(theme, '#58595B'),
            borderRadius: 4,
            borderWidth: 1,
            padding: 8,
            fontFamily: 'Open Sans',
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
        },
        circle: {
            borderRadius: 30,
            height: 16,
            width: 16,
        },
        newButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 5
        },
        newButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1
        },
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
    });
}
