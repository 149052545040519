import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { dispatchService } from '../../services/central-api/dispatch';
import { WindowInformation } from '../../services/window-information';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';

export default function EquipmentsModal({ onClose, equipment }: { onClose: () => void, equipment?: Equipment; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState(equipment?.name || '');
    const [actingBodyId, setActingBodyId] = useState<number>(equipment?.actingBodyId || 0);
    const [actingBodyList, setActingBodyList] = useState<ActingBodySimplified[]>([]);
    const [actingBodyPickerOpen, setActingBodyPickerOpen] = useState(false);

    async function getActingBodies() {
        try {
            const actingBodies = await dispatchService.getActingBodies();
            setActingBodyList(actingBodies);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getActingBodies();
    }, []);

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isActingBodyInvalid(value: number) {
        return isNaN(value) || value <= 0;
    }

    function isFormValid() {
        return !isNameInvalid(name) && !isActingBodyInvalid(actingBodyId);
    }

    async function formSubmit() {
        try {
            if (equipment?.id) {
                await dispatchService.updateEquipment({
                    id: equipment.id,
                    actingBodyId: actingBodyId,
                    name,
                    registry: name
                });
                onClose();
                return;
            }

            await dispatchService.createEquipment({
                actingBodyId: actingBodyId,
                name,
                registry: name
            });
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
            <View style={{ zIndex: 3, rowGap: 5 }}>
                <MyAppText style={styles.label}>{translate('actingBodies')}</MyAppText>
                <MyDropDownPicker
                    open={actingBodyPickerOpen}
                    setOpen={setActingBodyPickerOpen}
                    value={actingBodyId}
                    setValue={setActingBodyId}
                    items={actingBodyList.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    })}
                    searchable={true}
                />
            </View>
            <FormInput
                label={translate('name')}
                placeholder={translate('name')}
                value={name}
                onChangeText={setName}
                invalid={isNameInvalid}
            />
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        formContainer: {
            minWidth: 300,
            minHeight: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 15,
            justifyContent: 'space-between'
        },
    });
}
