import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity, Modal, ActivityIndicator } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { accessService } from '../../services/central-api/access';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import InternetCompanyModal from './InternetCompanyModal';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AccessParamList } from '../../typings/Params';

export default function InternetCompany({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<AccessParamList, 'InternetCompanies'>>();

    const [pageCount, setPageCount] = useState(1);
    const [internetCompanies, setInternetCompanies] = useState<InternetCompanyList[]>([]);
    const [page, setPage] = useState(Math.max(route.params.page - 1, 0));
    const [selectedInternetCompanies, setSelectedInternetCompanies] = useState<InternetCompany>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function getInternetCompanies({ page }: { page: number; }) {
        try {
            setIsLoading(true);
            setPage(page);
            const response = await accessService.getInternetCompanies({ page });
            setInternetCompanies(response.rows);
            setPageCount(Math.ceil(response.count / 10));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(internetCompany: InternetCompany) {
        try {
            if (internetCompany?.id) {
                await accessService.deleteInternetCompany(internetCompany.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setDeleteModalVisible(false);
                getInternetCompanies({ page });

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getInternetCompanies({ page });
    }, [page]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='internetCompanies' lateralMenu='access' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                setSelectedInternetCompanies(undefined);
                                setModalVisible(true);
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <View style={{ rowGap: 7 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ flexBasis: '50%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('name')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '45%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('cameraCount')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '5%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('actions')}</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableLine}></View>
                        </View>
                        <ScrollView>
                            <View style={{ gap: 10 }}>
                                {isLoading ?
                                    <View>
                                        <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                                    </View> : internetCompanies.length ?
                                        internetCompanies.map((internetCompany, internetCompanyIndex) => (
                                            <View key={internetCompanyIndex} style={{ rowGap: 9 }}>
                                                <View style={styles.row}>
                                                    <View style={{ flexBasis: '50%' }}>
                                                        <MyAppText style={styles.headerCell}>{internetCompany.name}</MyAppText>
                                                    </View>
                                                    <View style={{ flexBasis: '45%' }}>
                                                        <MyAppText style={styles.headerCell}>{internetCompany.cameraCount}</MyAppText>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', flexBasis: '5%', columnGap: 10 }}>
                                                        <TouchableOpacity style={{ marginRight: 10 }} onPress={() => {
                                                            setSelectedInternetCompanies(internetCompany);
                                                            setModalVisible(true);
                                                        }}>
                                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => {
                                                            setSelectedInternetCompanies(internetCompany);
                                                            setDeleteModalVisible(true);
                                                        }}>
                                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={styles.tableLine}></View>
                                            </View>
                                        )) :
                                        <View>
                                            <MyAppText style={{ textAlign: 'center', color: getThemedColor(theme, '#58595B'), marginTop: 10 }}>
                                                {translate('noResults')}.
                                            </MyAppText>
                                        </View>
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    {/* Delete Modal */}
                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedInternetCompanies?.name) || ''}
                        onSubmit={async () => {
                            if (selectedInternetCompanies) {
                                await confirmDelete(selectedInternetCompanies);
                            }
                        }}
                    />

                    {/* Create/Edit Modal */}
                    <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <InternetCompanyModal
                                    onClose={() => {
                                        setSelectedInternetCompanies(undefined);
                                        setModalVisible(false);
                                        getInternetCompanies({ page });
                                    }}
                                    internetCompanyId={selectedInternetCompanies?.id}
                                />
                            </View>
                        </View>
                    </Modal>

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B')
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
    });
}
