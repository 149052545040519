import React, { useContext, useEffect, useState } from "react";
import DefaultPageContainer from "../../components/DefaultPageContainer";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import getThemedColor from "../../services/get-themed-color";
import ThemeContext from "../../context/Theme";
import MyAppText from "../../components/MyAppText";
import { translate } from "../../services/translate";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import { Tabs } from "../../components/Tabs";
import { RichTextEditor } from "../../components/RichTextEditor";
import { centralAPI } from "../../services/central-api";
import Toast from "react-native-toast-message";
import { platformSettingsService } from "../../services/central-api/platform-settings";
import { TextInputMask } from "react-native-masked-text";
import { ScrollView } from "react-native-web-hover";

export default function PlatformSettings({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const [termsOfUse, setTermsOfUse] = useState<string>();
    const [sessionValidityTime, setSessionValidityTime] = useState(0);
    const [sessionQuantityLimit, setSessionQuantityLimit] = useState(0);
    const [mobileSessionValidityTime, setMobileSessionValidityTime] = useState(0);
    const [mobileSessionQuantityLimit, setMobileSessionQuantityLimit] = useState(0);

    const styles = getStyles(theme);

    async function getTermsOfUse() {
        try {
            const termsOfUse = await centralAPI.getTermsOfUse();
            setTermsOfUse(termsOfUse.termsOfUse);
        } catch (err) {
            console.error(err);
        }
    }

    async function saveTermsOfUse() {
        try {
            await platformSettingsService.updateTermsOfUse({ termsOfUse: termsOfUse || '' });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function getSessionValidityTime() {
        try {
            const session = await platformSettingsService.getSession();
            setSessionValidityTime(session.sessionValidityTime / 60);
            setSessionQuantityLimit(session.sessionQuantityLimit);
            setMobileSessionValidityTime(session.mobileSessionValidityTime / 60);
            setMobileSessionQuantityLimit(session.mobileSessionQuantityLimit);
        } catch (err) {
            console.error(err);
        }
    }

    async function saveSessionValidityTime() {
        try {
            await platformSettingsService.updateSession({
                sessionValidityTime: sessionValidityTime * 60,
                sessionQuantityLimit,
                mobileSessionValidityTime: mobileSessionValidityTime * 60,
                mobileSessionQuantityLimit,

            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getTermsOfUse();
        getSessionValidityTime();
    }, []);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='platformSettings' lateralMenu='access' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>

                            <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                                {translate('platformSettings')}
                            </MyAppText>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <Tabs tabs={[{
                            key: 'terms-of-use',
                            label: translate('termsOfUse')
                        }, {
                            key: 'sessions',
                            label: translate('sessions')
                        }]}>
                            {({ selectedTab }) => {
                                if (selectedTab === 'terms-of-use') {
                                    return (
                                        <>
                                            <RichTextEditor text={termsOfUse} onChangeText={setTermsOfUse} />
                                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <TouchableOpacity
                                                    onPress={saveTermsOfUse}
                                                    style={{
                                                        borderWidth: 0,
                                                        borderRadius: 2,
                                                        backgroundColor: getThemedColor(theme, '#000028'),
                                                        width: 128,
                                                        height: 40,
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <MyAppText style={{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }}>
                                                        {translate('save')}
                                                    </MyAppText>
                                                </TouchableOpacity>
                                            </View>
                                        </>
                                    );
                                }
                                if (selectedTab === 'sessions') {
                                    return <>
                                        <ScrollView >
                                            <View style={{ flex: 1, gap: 30 }}>
                                                <View style={{ flex: 1, gap: 10 }}>
                                                    <MyAppText style={styles.sessionTitle}>{translate('desktop')}</MyAppText>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 14, fontWeight: 'bold' }}>
                                                        {translate('sessionDuration')}
                                                    </MyAppText>
                                                    <View style={[{ flexDirection: 'row' }]}>
                                                        <TextInputMask
                                                            value={String(sessionValidityTime)}
                                                            includeRawValueInChangeText={true}
                                                            onChangeText={(maskedText, rawText) => {
                                                                setSessionValidityTime(Number(rawText));
                                                            }}
                                                            type={'only-numbers'}
                                                            style={[styles.inputAttached, { maxWidth: 70, textAlign: 'center' }]}
                                                        />
                                                        <MyAppText style={styles.inputAttach}>{translate('minutes')}</MyAppText>
                                                    </View>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#888888'), fontSize: 14 }}>{
                                                        translate('sessionDurationExplanation')}
                                                    </MyAppText>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 14, fontWeight: 'bold' }}>
                                                        {translate('sessionLimit')}
                                                    </MyAppText>
                                                    <View style={[{ flexDirection: 'row' }]}>
                                                        <TextInputMask
                                                            value={String(sessionQuantityLimit)}
                                                            includeRawValueInChangeText={true}
                                                            onChangeText={(maskedText, rawText) => {
                                                                setSessionQuantityLimit(Number(rawText));
                                                            }}
                                                            type={'only-numbers'}
                                                            style={[styles.input, { maxWidth: 70, textAlign: 'center' }]}
                                                        />
                                                    </View>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#888888'), fontSize: 14 }}>{
                                                        translate('sessionLimitExplanation')}
                                                    </MyAppText>
                                                </View>
                                                <View style={{ flex: 1, gap: 10 }}>
                                                    <MyAppText style={styles.sessionTitle}>{translate('mobile')}</MyAppText>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 14, fontWeight: 'bold' }}>
                                                        {translate('sessionDuration')}
                                                    </MyAppText>
                                                    <View style={[{ flexDirection: 'row' }]}>
                                                        <TextInputMask
                                                            value={String(mobileSessionValidityTime)}
                                                            includeRawValueInChangeText={true}
                                                            onChangeText={(maskedText, rawText) => {
                                                                setMobileSessionValidityTime(Number(rawText));
                                                            }}
                                                            type={'only-numbers'}
                                                            style={[styles.inputAttached, { maxWidth: 70, textAlign: 'center' }]}
                                                        />
                                                        <MyAppText style={styles.inputAttach}>{translate('minutes')}</MyAppText>
                                                    </View>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#888888'), fontSize: 14 }}>{
                                                        translate('sessionDurationExplanation')}
                                                    </MyAppText>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 14, fontWeight: 'bold' }}>
                                                        {translate('sessionLimit')}
                                                    </MyAppText>
                                                    <View style={[{ flexDirection: 'row' }]}>
                                                        <TextInputMask
                                                            value={String(mobileSessionQuantityLimit)}
                                                            includeRawValueInChangeText={true}
                                                            onChangeText={(maskedText, rawText) => {
                                                                setMobileSessionQuantityLimit(Number(rawText));
                                                            }}
                                                            type={'only-numbers'}
                                                            style={[styles.input, { maxWidth: 70, textAlign: 'center' }]}
                                                        />
                                                    </View>
                                                    <MyAppText style={{ color: getThemedColor(theme, '#888888'), fontSize: 14 }}>{
                                                        translate('sessionLimitExplanation')}
                                                    </MyAppText>
                                                </View>
                                            </View>
                                        </ScrollView>
                                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <TouchableOpacity
                                                onPress={saveSessionValidityTime}
                                                style={{
                                                    borderWidth: 0,
                                                    borderRadius: 2,
                                                    backgroundColor: getThemedColor(theme, '#000028'),
                                                    width: 128,
                                                    height: 40,
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <MyAppText style={{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }}>
                                                    {translate('save')}
                                                </MyAppText>
                                            </TouchableOpacity>
                                        </View>
                                    </>;
                                }
                            }}
                        </Tabs>
                    </View>
                </View>
            </DefaultPageLayout>

        </DefaultPageContainer >
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15,
        },
        sessionTitle: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold',
            fontFamily: 'Open Sans',
        },
        headerBar: {
            height: 64,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            border: `1px solid ${getThemedColor(theme, '#E6E6E6')}`,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 14
        },
        headerLogo: {
            height: 40,
            width: 275,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
            flexDirection: 'row',
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            maxHeight: 40,
            padding: 10,
            color: getThemedColor(theme, '#222222'),
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderRadius: 4
        },
        inputAttached: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            maxHeight: 40,
            padding: 10,
            color: getThemedColor(theme, '#222222'),
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderTopStartRadius: 4,
            borderBottomStartRadius: 4,
        },
        inputAttach: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 13,
            backgroundColor: getThemedColor(theme, '#E6E6E6'),
            maxHeight: 40,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderTopEndRadius: 4,
            borderBottomEndRadius: 4,
            padding: 11,
        },
    });
}
