import React, { useContext, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TabInfo {
    key: string;
    label: string;
    icon?: IconDefinition;
}

interface TabsParameters {
    tabs: TabInfo[];
    children: ({ selectedTab }: { selectedTab: string; }) => React.ReactNode | undefined;
}

export function Tabs({ children, tabs }: TabsParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [selectedTab, setSelectedTab] = useState(tabs[0].key);

    return (
        <View style={{ rowGap: 15, flex: 1 }}>
            <View style={styles.container}>
                {tabs.map((tab) => (
                    <View key={tab.key} style={{
                        borderBottomWidth: selectedTab == tab.key ? 2 : 0,
                        borderColor: selectedTab == tab.key ? getThemedColor(theme, '#000028') : undefined,
                        padding: 6
                    }}>
                        <TouchableOpacity onPress={() => setSelectedTab(tab.key)} style={{
                            flexDirection: 'row',
                            columnGap: 5,
                            alignItems: 'center',
                            paddingHorizontal: tab.icon ? 5 : 0
                        }}>
                            {tab.icon ?
                                <FontAwesomeIcon icon={tab.icon} fontSize={14} color={selectedTab == tab.key ? getThemedColor(theme, '#000028') : getThemedColor(theme, '#888888')} />
                                : null
                            }

                            <MyAppText style={[styles.tabText, {
                                color: selectedTab == tab.key ? getThemedColor(theme, '#000028') : getThemedColor(theme, '#888888')
                            }]}>{tab.label}</MyAppText>
                        </TouchableOpacity>
                    </View>
                ))}
            </View >
            {children({ selectedTab })}
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flexDirection: 'row',
            borderBottomWidth: 1,
            borderColor: getThemedColor(theme, '#888888'),
            columnGap: 8,
        },
        tabText: {
            fontWeight: 'bold',
            fontSize: 16,
        }
    });
}
