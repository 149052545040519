import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator, Modal, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import moment from 'moment';
import MyDataTable from '../../components/MyDataTable';
import MyAppText from '../../components/MyAppText';
import Toast from 'react-native-toast-message';
import FormActions from '../../components/formActions';
import { MyCheckbox } from '../../components/MyCheckbox';
import { crmService } from '../../services/central-api/crm';
import { WindowInformation } from '../../services/window-information';
import FormInput from '../../components/formInput';
import { dispatchService } from '../../services/central-api/dispatch';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../styles/date-ranger-picker.css';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function Reports({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'Reports'>>();

    // filters
    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: 25
    });

    const [pageCount, setPageCount] = useState(1);
    const [reports, setReports] = useState<Report[]>([]);
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [isDownloading, setIsDownloading] = useState(false);
    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const windowInfo = WindowInformation();

    const [isPdf, setIsPdf] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [isOpenTags, setIsOpenTags] = useState<boolean>(false);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>([]);
    const [selectableTags, setSelectableTags] = useState<{ label: string, value: number; }[]>([]);
    const [dateFrom, setDateFrom] = useState<ValuePiece>(new Date());
    const [dateTo, setDateTo] = useState<ValuePiece>(new Date());

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    async function getReports() {
        try {
            const response = await dispatchService.getReports(filters);
            setReports(response.rows);
            setPageCount(Math.ceil(response.count / filters.limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function getReportTags() {
        try {
            const tags = await dispatchService.getReportTags();
            setSelectableTags(tags.map((tag) => {
                return {
                    value: tag.id || 0,
                    label: tag.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getReports();
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('dispatch/reports')) {
                return;
            }
            getReports();
        }, 15000);

        return () => clearInterval(interval);
    }, [filters]);

    function isFormValid() {
        return name !== '' && moment(dateTo).isSameOrAfter(moment(dateFrom));
    }

    function resetReportForm() {
        setName("");
        setIsPdf(false);
        setIsOpenTags(false);
        setSelectedTagsIds([]);
        setSelectableTags([]);
    }

    function onCloseNewReportModal() {
        setModalVisible(false);
        resetReportForm();
    }

    function onOpenNewReportModal() {
        setModalVisible(true);
        getReportTags();
    }

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter,
        });

        navigation.setParams({
            page: 1,
            textFilter,
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='reports' lateralMenu='dispatch' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                            <MyAppText style={styles.filterText}>{translate('searchReport')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                            {authenticatedUser?.permissions.dispatch_generate_report || authenticatedUser?.isAdmin ?
                                <TouchableOpacity onPress={() => onOpenNewReportModal()} style={styles.new}>
                                    <FontAwesomeIcon icon={faDownload} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                    <MyAppText style={styles.newText}>{translate('occurrenceReport')}</MyAppText>
                                </TouchableOpacity> : undefined
                            }
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true
                                },
                                {
                                    name: translate('user'),
                                    selector: row => row.user?.name || '',
                                    wrap: true
                                },
                                {
                                    name: 'Token',
                                    selector: row => row.token,
                                    wrap: true
                                },
                                {
                                    name: translate('status'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center', width: '100%' }}>
                                            {row.status == 'pending' ?
                                                <ActivityIndicator size={16} color={getThemedColor(theme, '#000000')} /> :
                                                <FontAwesomeIcon style={row.status == 'success' ? styles.success : styles.failed} fontSize={16} icon={row.status == 'success' ? faCheckCircle : faTimesCircle}></FontAwesomeIcon>
                                            }

                                            <MyAppText style={row.status == 'success' ? styles.success : row.status == 'failed' ? styles.failed : {}}>{translate(row.status)}</MyAppText>
                                        </View>
                                },
                                {
                                    name: translate('filters'),
                                    cell: row => <View style={{ width: '100%' }}>
                                        {row.type == 'occurrences' ?
                                            <View style={{ flexDirection: 'column', rowGap: 5 }}>
                                                <MyAppText>{translate('format')}: {row.filters.isPdf ? 'PDF' : 'CSV'}</MyAppText>
                                                {row.filters.startDate ?
                                                    <MyAppText>{translate('start')}: {moment(row.filters.startDate).format('DD/MM/YYYY')}</MyAppText> : undefined}
                                                {row.filters.endDate ?
                                                    <MyAppText>{translate('end')}: {moment(row.filters.endDate).format('DD/MM/YYYY')}</MyAppText> : undefined}
                                            </View> : <></>}
                                    </View>
                                },
                                {
                                    name: translate('createdAt'),
                                    selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                                },
                                {
                                    name: translate('tags'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                            {row.tags ?
                                                row.tags.map((tag) =>
                                                    <View key={tag.id} style={[styles.tagsMarker, { backgroundColor: tag.color }]}>
                                                        <MyAppText style={[styles.tagsMarkerText]}>
                                                            {tag.name}
                                                        </MyAppText>
                                                    </View>
                                                ) : null
                                            }
                                        </View>
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View>
                                            {authenticatedUser?.permissions.dispatch_download_report || authenticatedUser?.isAdmin ? <TouchableOpacity disabled={isDownloading} onPress={async () => {
                                                try {
                                                    setIsDownloading(true);
                                                    const video = await crmService.getReport(row.id);
                                                    window.open(video.resultUrl);
                                                } catch (error) {
                                                    Toast.show({
                                                        type: 'sentinelxError',
                                                        text1: translate('unexpectedError'),
                                                    });
                                                } finally {
                                                    setIsDownloading(false);
                                                }
                                            }}>
                                                {row.status === 'success' ? <FontAwesomeIcon fontSize={16} color={getThemedColor(theme, '#58595B')} icon={faDownload}></FontAwesomeIcon> : null}
                                            </TouchableOpacity> : undefined}
                                        </View>
                                }
                            ]}
                            data={reports}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                </View>
                <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => onCloseNewReportModal()} >
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : {}]}>
                                <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: getThemedColor(theme, '#58595B') }}>{translate('occurrenceReport')}</MyAppText>
                                <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 12 }}>{translate('occurrenceReportInformation')}</MyAppText>
                                <FormInput label={translate('name')} invalid={() => false} value={name} onChangeText={setName} />
                                <View style={{ zIndex: 3 }}>
                                    <View style={{ flex: 1, gap: 5 }}>
                                        <MyAppText style={styles.tagsLabel}>{translate('tags')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableTags}
                                            multiple={true}
                                            mode='BADGE'
                                            value={selectedTagsIds}
                                            setValue={setSelectedTagsIds}
                                            setOpen={setIsOpenTags}
                                            open={isOpenTags}
                                        />
                                    </View>
                                </View>
                                <View style={{ maxWidth: 240, zIndex: 2, gap: 5 }}>
                                    <MyAppText style={styles.tagsLabel}>{translate('period')}</MyAppText>
                                    <DateRangePicker calendarProps={{ locale: 'pt-BR' }} maxDate={moment().toDate()} onChange={(value: Value) => {
                                        if (Array.isArray(value)) {
                                            setDateFrom(value[0]);
                                            setDateTo(value[1]);
                                        }
                                    }} autoFocus={false} shouldOpenCalendar={({ reason }) => reason !== 'focus'} value={[dateFrom, dateTo]} clearIcon={null} locale='pt-BR' className={theme === 'light' ? 'react-daterange-picker__wrapper-light' : 'react-daterange-picker__wrapper-dark'} />
                                </View>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('PDF')}
                                    checked={isPdf}
                                    setChecked={(value) => setIsPdf(value)} />
                                <FormActions
                                    onSubmit={async () => {
                                        try {
                                            await dispatchService.createReport({
                                                name: name,
                                                isPdf: isPdf,
                                                startDate: moment(dateFrom).format('YYYY-MM-DD'),
                                                endDate: moment(dateTo).format('YYYY-MM-DD'),
                                                tags: selectedTagsIds
                                            });

                                            onCloseNewReportModal();

                                            Toast.show({
                                                type: 'sentinelxSuccess',
                                                text1: translate('ActionSuccessfully'),
                                            });
                                            await getReports();
                                        } catch (error) {
                                            Toast.show({
                                                type: 'sentinelxError',
                                                text1: translate('unexpectedError'),
                                            });
                                        }
                                    }}
                                    onClose={() => onCloseNewReportModal()}
                                    isLoading={isDownloading}
                                    disabled={!isFormValid()}
                                    confirmText={'download'} />
                            </View>
                        </View>
                    </View>
                </Modal>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 20
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        success: {
            color: '#017B27'
        },
        failed: {
            color: '#B40119'
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            paddingHorizontal: 8,
            height: 40
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        formContainer: {
            minWidth: 350,
            maxWidth: 400,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 200,
            rowGap: 15
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 40,
            paddingHorizontal: 30,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 10
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        tagsLabel: {
            color: getThemedColor(theme, '#58595B'),
        },
        tagsMarker: {
            borderRadius: 8,
            minHeight: 16,
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: '100%'
        },
        tagsMarkerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        buttonsContainer: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            marginTop: 20
        }
    });
}
