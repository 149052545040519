import React, { useContext, useEffect, useRef, useState } from 'react';
import { Animated, TouchableOpacity, View, Image, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { WindowInformation } from '../services/window-information';
import FacialLateralMenu, { SelectedMenu as FacialSelectedMenu } from './facial/FacialLateralMenu';
import CamerasLateralMenu, { SelectedMenu as CamerasSelectedMenu } from './cameras/CamerasLateralMenu';
import LprLateralMenu, { SelectedMenu as LprSelectedMenu } from './lpr/LprLateralMenu';
import DispatchLateralMenu, { SelectedMenu as DispatchSelectedMenu } from './dispatch/DispatchLateralMenu';
import AccessLateralMenu, { SelectedMenu as AccessSelectedMenu } from './access/AccessLateralMenu';
import EventsLateralMenu, { SelectedMenu as EventsSelectedMenu } from './events/EventsLateralMenu';
import ContentContainer from "./ContentContainer";
import GcmAgentLateralMenu, { SelectedMenu as GcmAgentSelectedMenu } from './gcm-agent/GcmAgentLateralMenu';
import DashboardLateralMenu, { SelectedMenu as DashboardSelectedMenu } from './dashboard/DashboardLateralMenu';
import LogsLateralMenu, { SelectedMenu as LogsSelectedMenu } from './logs/LogsLateralMenu';
import CrmLateralMenu, { SelectedMenu as CrmSelectedMenu } from './crm/CrmLateralMenu';
import SmartSearchFacialLateralMenu, { SelectedMenu as SmartSearchFacialSelectedMenu } from './smart-search-facial/SmartSearchFacialLateralMenu';
import UserLateralMenu, { SelectedMenu as UserSelectedMenu } from './user/UserLateralMenu';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';
import { lateralMenuWidth } from '../services/lateral-menu-width';
import UserInformationModal from './UserInformationModal';

type DefaultPageLayoutProps = {
    navigation: Navigation;
    children: React.ReactNode | undefined;
    contentContainerStyle?: StyleProp<ViewStyle>;
    authenticatedUser?: AuthenticatedUser;
    onShowLateralMenuChange?: () => void;
} & ({
    lateralMenu: 'facial';
    selectedMenu: FacialSelectedMenu;
} | {
    lateralMenu: 'cameras';
    selectedMenu: CamerasSelectedMenu;
} | {
    lateralMenu: 'lpr';
    selectedMenu: LprSelectedMenu;
} | {
    lateralMenu: 'dispatch';
    selectedMenu: DispatchSelectedMenu;
} | {
    lateralMenu: 'access';
    selectedMenu: AccessSelectedMenu;
} | {
    lateralMenu: 'events';
    selectedMenu: EventsSelectedMenu;
} | {
    lateralMenu: 'gcmAgent';
    selectedMenu: GcmAgentSelectedMenu;
} | {
    lateralMenu: 'dashboard';
    selectedMenu: DashboardSelectedMenu;
} | {
    lateralMenu: 'logs';
    selectedMenu: LogsSelectedMenu;
} | {
    lateralMenu: 'smartSearchFacial';
    selectedMenu: SmartSearchFacialSelectedMenu;
} | {
    lateralMenu: 'crm';
    selectedMenu: CrmSelectedMenu;
} | {
    lateralMenu: 'user';
    selectedMenu: UserSelectedMenu;
});


const headerHeight = 64;
const animationTime = 200;

export default function DefaultPageLayout(props: DefaultPageLayoutProps) {
    const { theme } = useContext(ThemeContext);
    const [showDropDown, setShowDropDown] = useState(false);
    const windowInfo = WindowInformation();
    const insets = useSafeAreaInsets();
    const [showLateralMenu, setShowLateralMenu] = useState<boolean>(true);
    const translateXAnim = useRef(new Animated.Value(0)).current;
    const translateXInvertAnim = useRef(new Animated.Value(250)).current;
    const opacityAnim = useRef(new Animated.Value(0.2)).current;
    const zIndexAnim = useRef(new Animated.Value(9999)).current;
    const leftAnim = useRef(new Animated.Value(lateralMenuWidth.getOriginalWidth())).current;

    const styles = getStyles(theme);

    useEffect(() => {
        if (windowInfo.isMobile) {
            setShowLateralMenu(false);
            setStorage(false);
            lateralMenuWidth.setIsOpen(false);
        }
    }, [windowInfo.isMobile]);

    async function getStorage() {
        try {
            setShowLateralMenu(JSON.parse(await AsyncStorage.getItem("HAMBURGER_OPEN") || "true"));
        } catch (e) {
            console.error("Error on parse Storage: ", e);
        }
    }

    async function setStorage(showLateralMenu: boolean) {
        await AsyncStorage.setItem("HAMBURGER_OPEN", String(showLateralMenu));
    }

    function animateMenu() {
        if (showLateralMenu) {
            Animated.timing(translateXInvertAnim, {
                toValue: lateralMenuWidth.getOriginalWidth(),
                duration: animationTime,
                useNativeDriver: true,
            }).start();
            Animated.timing(translateXAnim, {
                toValue: 0,
                duration: animationTime,
                useNativeDriver: true,
            }).start();

            Animated.timing(opacityAnim, {
                toValue: 0.2,
                duration: animationTime,
                useNativeDriver: true,
            }).start();
            Animated.timing(zIndexAnim, {
                toValue: 9999,
                duration: 0,
                useNativeDriver: true,
            }).start();
            Animated.timing(leftAnim, {
                toValue: lateralMenuWidth.getOriginalWidth(),
                duration: animationTime,
                useNativeDriver: true,
            }).start();

        } else {
            Animated.timing(translateXAnim, {
                toValue: -lateralMenuWidth.getOriginalWidth(),
                duration: animationTime,
                useNativeDriver: true,
            }).start();

            Animated.timing(opacityAnim, {
                toValue: 0,
                duration: animationTime,
                useNativeDriver: true,
            }).start();
            Animated.timing(zIndexAnim, {
                toValue: -9999,
                duration: animationTime,
                useNativeDriver: true,
            }).start();
            Animated.timing(leftAnim, {
                toValue: 0,
                duration: animationTime,
                useNativeDriver: true,
            }).start();

            Animated.timing(translateXInvertAnim, {
                toValue: 0,
                duration: animationTime,
                useNativeDriver: true,
            }).start();
        }
    }

    useEffect(() => {
        getStorage();
    }, []);

    useEffect(() => {
        animateMenu();

        setStorage(showLateralMenu);
        lateralMenuWidth.setIsOpen(showLateralMenu);

        if (props.onShowLateralMenuChange) {
            props.onShowLateralMenuChange();
        }
    }, [showLateralMenu]);

    const lateralMenuProps: LateralMenuProps = {
        navigation: props.navigation,
        authenticatedUser: props.authenticatedUser
    };

    return (<>
        <View style={styles.headerBar}>
            <TouchableOpacity onPress={() => {
                setShowLateralMenu(!showLateralMenu);
            }} style={{ width: 24, height: 24 }}>
                <FontAwesomeIcon icon={faBars} color={getThemedColor(theme, '#000000')} fontSize={24}></FontAwesomeIcon>
            </TouchableOpacity>
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {windowInfo.isMobile ?
                    <Image source={require(`../../assets/smartsampa_logo_${theme}.png`)} style={styles.headerLogo} />
                    : <Image source={require(`../../assets/big_smartsampa_logo_${theme}.png`)} style={styles.bigHeaderLogo} />
                }
            </View>
            <TouchableOpacity onPress={() => setShowDropDown(!showDropDown)}>
                {
                    props.authenticatedUser?.facePictureImageUrl ?
                        <Image source={{ uri: props.authenticatedUser?.facePictureImageUrl }} style={styles.profilePic} />
                        :
                        <FontAwesomeIcon icon={faCircleUser} color={getThemedColor(theme, '#000000')} fontSize={24} />
                }
            </TouchableOpacity>
        </View >
        <View style={{
            flexDirection: 'row',
            overflow: 'hidden'
        }}>
            <Animated.View style={{
                height: windowInfo.height - headerHeight - insets.top - insets.bottom,
                position: windowInfo.isMobile ? 'absolute' : 'relative',
                zIndex: 1000,
                transform: [{
                    translateX: translateXAnim
                }]
            }}>
                {props.lateralMenu == 'facial' ?
                    <FacialLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'cameras' ?
                    <CamerasLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} authenticatedUser={props.authenticatedUser} /> : null}
                {props.lateralMenu == 'lpr' ?
                    <LprLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'dispatch' ?
                    <DispatchLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'access' ?
                    <AccessLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'events' ?
                    <EventsLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'gcmAgent' ?
                    <GcmAgentLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'dashboard' ?
                    <DashboardLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'logs' ?
                    <LogsLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'smartSearchFacial' ?
                    <SmartSearchFacialLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'crm' ?
                    <CrmLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
                {props.lateralMenu == 'user' ?
                    <UserLateralMenu {...lateralMenuProps} selectedMenu={props.selectedMenu} /> : null}
            </Animated.View>

            {
                windowInfo.isMobile ?
                    <Animated.View onTouchEnd={() => setShowLateralMenu(false)} style={{
                        position: 'absolute',
                        top: 0,
                        transform: [{
                            translateX: translateXInvertAnim
                        }],
                        height: '100%',
                        backgroundColor: '#000',
                        opacity: opacityAnim,
                        width: '100%',
                        zIndex: zIndexAnim
                    }}></Animated.View> : null
            }

            <Animated.View style={{
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                height: windowInfo.height - headerHeight - insets.top - insets.bottom,
                width: windowInfo.isMobile ? '100%' : windowInfo.width - lateralMenuWidth.getWidth(),
                transform: [{
                    translateX: !windowInfo.isMobile ? translateXAnim : 0
                }]
            }}>
                <ContentContainer showLateralMenu={showLateralMenu} style={props.contentContainerStyle}>
                    {props.children}
                </ContentContainer>
            </Animated.View>
            <UserInformationModal acceptedTermsOfUse={props.authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={props.navigation} />
        </View >
    </>);
}

function getStyles(theme: Theme) {
    const insets = useSafeAreaInsets();
    return StyleSheet.create({
        headerBar: {
            height: headerHeight,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            border: `1px solid ${getThemedColor(theme, '#E6E6E6')}`,
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 14
        },
        headerLogo: {
            height: 40,
            width: 275,
        },
        bigHeaderLogo: {
            height: 40,
            width: 700,
        },
        userModal: {
            top: 35,
            right: 0,
            position: 'absolute',
            minWidth: 200,
            zIndex: 70000,
            display: 'flex',
            rowGap: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            padding: 10,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        logoutText: {
            color: 'rgb(240, 54, 103)',
            fontSize: 15,
        },
        footerText: {
            fontSize: 13,
        },
        modalContainer: {
            flexDirection: 'column',
            flex: 1
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            flexDirection: 'column',
            borderRadius: 4,
            paddingHorizontal: 20,
            paddingTop: 20,
            position: 'absolute',
            top: 55 + insets.top,
            right: 5 + insets.right,
            minWidth: 300
        },
        optionsBody: {
            rowGap: 15,
        },

        profilePic: {
            width: 24,
            aspectRatio: 1,
            borderRadius: 100
        }
    });
}
