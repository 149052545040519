import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import Toast from 'react-native-toast-message';
import { WindowInformation } from '../../services/window-information';
import MyAppText from '../../components/MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../components/DeleteModal';
import { ClientError } from '../../services/central-api/base-service';
import { dispatchService } from '../../services/central-api/dispatch';
import { ScrollView } from 'react-native-web-hover';

export default function ObjectCategoryModal({ onClose, objectCategoryId }: { onClose: () => void, objectCategoryId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [objectSubCategories, setObjectSubCategories] = useState<ObjectSubCategory[]>([{ name: '' }]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedObjectSubCategoryForDeletion, setSelectedObjectSubCategoryForDeletion] = useState<ObjectSubCategory>();

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    async function deleteObjectSubCategory(objectSubCategory: ObjectSubCategory) {
        try {
            if (!objectSubCategory.id) {
                return;
            }
            await dispatchService.deleteObjectSubCategory(objectSubCategory.id);
            const index = objectSubCategories.findIndex(r => r.id == objectSubCategory.id);
            objectSubCategories.splice(index, 1);
            setObjectSubCategories([...objectSubCategories]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getObjectCategory(objectCategoryId: number) {
        try {
            const objectCategory = await dispatchService.getObjectCategory(objectCategoryId);
            setName(objectCategory.name);
            setObjectSubCategories(objectCategory.objectSubCategories || []);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (objectCategoryId) {
            getObjectCategory(objectCategoryId);
        }
    }, []);

    async function formSubmit() {
        try {
            if (objectCategoryId) {
                await dispatchService.updateObjectCategory(objectCategoryId, {
                    name,
                    objectSubCategories
                });
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                onClose();
                return;
            }

            await dispatchService.createObjectCategory({ name, objectSubCategories });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <>
            <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
                <FormInput
                    label={translate('name')}
                    placeholder={translate('name')}
                    value={name}
                    onChangeText={setName}
                    invalid={isNameInvalid}
                />
                <View style={{ gap: 8, maxHeight: 400 }}>
                    <MyAppText style={styles.label}>{translate('objectSubCategories')}</MyAppText>
                    <ScrollView contentContainerStyle={{ gap: 5 }}>
                        {objectSubCategories.map((objectSubCategory, index) => (
                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                                <TextInput
                                    placeholder={translate('subCategory')}
                                    style={[styles.input, {
                                        color: objectSubCategory.name == "" ? getThemedColor(theme, '#CBCBCB') : getThemedColor(theme, '#222222')
                                    }]}
                                    value={objectSubCategory.name}
                                    onChangeText={(value) => {
                                        objectSubCategories[index].name = value;
                                        setObjectSubCategories([...objectSubCategories]);
                                    }}
                                />
                                <TouchableOpacity onPress={() => {
                                    if (objectSubCategories[index].id) {
                                        setSelectedObjectSubCategoryForDeletion(objectSubCategories[index]);
                                        setIsDeleteModalVisible(true);
                                        return;
                                    }
                                    objectSubCategories.splice(index, 1);
                                    setObjectSubCategories([...objectSubCategories]);
                                }}>
                                    <FontAwesomeIcon fontSize={24} icon={faTimes} color={getThemedColor(theme, '#222222')} />
                                </TouchableOpacity>
                            </View>))
                        }
                    </ScrollView>
                    <TouchableOpacity onPress={() => {
                        objectSubCategories.push({ name: '' });
                        setObjectSubCategories([...objectSubCategories]);
                    }}>
                        <MyAppText style={styles.addObjectSubCategory}>+ {translate('addObjectSubCategory')}</MyAppText>
                    </TouchableOpacity>
                </View>


                <FormActions
                    onSubmit={formSubmit}
                    onClose={onClose}
                    disabled={!isFormValid()} />
            </View>

            <DeleteModal
                setModalVisible={setIsDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={selectedObjectSubCategoryForDeletion?.name}
                onSubmit={async () => {
                    if (selectedObjectSubCategoryForDeletion) {
                        await deleteObjectSubCategory(selectedObjectSubCategoryForDeletion);
                    }
                }}
            />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            minHeight: 150,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 25
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        addObjectSubCategory: {
            color: getThemedColor(theme, '#000028')
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
            flex: 1
        },
    });
}
