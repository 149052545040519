import React, { useContext, useEffect, useRef, useState } from 'react';
import DefaultPageContainer from "../../components/DefaultPageContainer";
import DefaultPageLayout from "../../components/DefaultPageLayout";
import { TouchableOpacity, View, StyleSheet, TextInput } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faImage, faLocationDot, faPencil, faVideo, faXmark } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../components/MyAppText';
import PlateImage from '../../components/lpr/PlateImage';
import Map from "../../components/Map";
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { translate } from '../../services/translate';
import { useNavigationState } from '@react-navigation/native';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';
import { downloadImage } from '../../services/download-image';
import LazyLoad from 'react-lazy-load';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ClientError } from '../../services/central-api/base-service';
import Toast from 'react-native-toast-message';

const regPlate = new RegExp(/[A-Z]{3}[-]?[0-9][0-9A-Z][0-9]{2}/);

interface DetectionDetailsProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            detectionId: string;
            page: 'liveEvents' | 'liveAlerts' | 'search' | 'map';
        };
    };
}

function DetectionDetailPageLayout({ navigation, route, authenticatedUser, children }: DetectionDetailsProps & { children: React.ReactNode | undefined; }) {
    if (route.params.page === 'map') {
        return <DefaultPageLayout navigation={navigation} selectedMenu={route.params.page} lateralMenu={'cameras'} authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
            {children}
        </DefaultPageLayout>;
    }
    return <DefaultPageLayout navigation={navigation} selectedMenu={route.params.page} lateralMenu={'lpr'} authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
        {children}
    </DefaultPageLayout>;
}

export default function DetectionDetails({ navigation, route, authenticatedUser }: DetectionDetailsProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [editingPlate, setEditingPlate] = useState(false);
    const [newPlate, setNewPlate] = useState('');
    const [detection, setDetection] = useState<LprDetection>();
    const index = useNavigationState(state => state);

    async function getDetection(detectionId: string) {
        try {
            const detection = await licensePlateRecognitionService.getLprDetection(Number(detectionId));
            setDetection(detection);
            setNewPlate(detection.plate);
        } catch (err) {
            console.error(err);
        }
    }

    const canvasRef = useRef<HTMLCanvasElement>(null);

    function isPlateInvalid(value: string) {
        return value == "" || value.length > 7 || !regPlate.test(value);
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!detection) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 4;

            const rect = {
                minX: Infinity,
                maxX: 0,
                minY: Infinity,
                maxY: 0
            };

            if (detection.platePosition) {
                for (const position of detection.platePosition) {
                    if (position.x < rect.minX) {
                        rect.minX = position.x;
                    }

                    if (position.x > rect.maxX) {
                        rect.maxX = position.x;
                    }

                    if (position.y < rect.minY) {
                        rect.minY = position.y;
                    }

                    if (position.y > rect.maxY) {
                        rect.maxY = position.y;
                    }
                }

                ctx.beginPath();
                ctx.moveTo(rect.minX, rect.minY);
                ctx.lineTo(rect.minX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.maxY);
                ctx.lineTo(rect.maxX, rect.minY);
                ctx.closePath();
                ctx.stroke();
            }

        };

        image.src = detection.imageUrl;
    });

    useEffect(() => {
        getDetection(route.params.detectionId);
    }, [route]);

    async function saveNewPlate() {
        if (!detection) {
            return;
        }

        if (isPlateInvalid(newPlate)) {
            return Toast.show({
                type: 'sentinelxError',
                text1: translate('invalidPlate'),
            });
        }

        try {
            await licensePlateRecognitionService.editLprDetection(detection.id, newPlate);
            await getDetection(String(detection.id));
            setEditingPlate(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    if (!detection) {
        return <DefaultPageContainer>
            <DetectionDetailPageLayout navigation={navigation} route={route} authenticatedUser={authenticatedUser}>
                <></>
            </DetectionDetailPageLayout>
        </DefaultPageContainer>;
    }

    return (
        <DefaultPageContainer>
            <DetectionDetailPageLayout navigation={navigation} route={route} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <TouchableOpacity onPress={() => {
                            if (index.routes.length > 1) {
                                return navigation.goBack();
                            } else {
                                if (route.params.page == 'liveAlerts') {
                                    window.location.href = '/lpr/live-alerts';
                                }
                                if (route.params.page == 'liveEvents') {
                                    window.location.href = '/lpr/live-events';
                                }
                                if (route.params.page == 'search') {
                                    window.location.href = '/lpr/search';
                                }
                                if (route.params.page == 'map') {
                                    window.location.href = `/cameras/${detection.camera.id}`;
                                }
                            }
                        }}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                        </TouchableOpacity>
                        <MyAppText style={styles.headerText}>
                            {translate('detection')}
                        </MyAppText>
                    </View>
                    <View style={styles.cardList}>
                        <View style={{ flex: 1, rowGap: 15, minWidth: 300 }}>
                            <TransformWrapper>
                                <TransformComponent>
                                    <canvas style={{ width: '100%' }} ref={canvasRef}></canvas>
                                </TransformComponent>
                            </TransformWrapper>
                            <View style={{ flexDirection: 'row', gap: 15, flexWrap: 'wrap' }}>
                                <View style={{ rowGap: 10 }}>
                                    <LazyLoad>
                                        <PlateImage
                                            src={detection.imageUrl}
                                            platePosition={detection.platePosition}
                                            width={150}
                                            height={75}
                                        />
                                    </LazyLoad>
                                    <TouchableOpacity
                                        style={styles.downloadButton}
                                        onPress={() => downloadImage({
                                            fileName: `${detection.plate}_${moment(detection.time).format("DDMMYYYYhmmssSS")}`,
                                            imageUrl: detection.imageUrl
                                        })}
                                    >
                                        <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} icon={faImage} />
                                        <MyAppText style={styles.downloadButtonText}>
                                            {translate('downloadImage')}
                                        </MyAppText>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={styles.downloadButton}
                                        onPress={() => window.location.href = `/LPR/search?page=1&endIn=${new Date().getTime()}&plate=${detection.plate}&irregularSituation=false&isMotorcycle=false&cameras=`}
                                    >
                                        <MyAppText style={styles.downloadButtonText}>
                                            {translate('searchForSamePlate')}
                                        </MyAppText>
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <View style={{ flexDirection: 'row', gap: 5 }}>
                                        {editingPlate ?
                                            <>
                                                <TextInput
                                                    placeholder=""
                                                    style={{
                                                        color: getThemedColor(theme, '#58595B'),
                                                        fontSize: 16,
                                                        fontWeight: 'bold',
                                                        width: 80
                                                    }}
                                                    value={newPlate}
                                                    onChangeText={(value) => {
                                                        if (value.length > 7) {
                                                            return;
                                                        }
                                                        setNewPlate(value.toUpperCase());
                                                    }}
                                                />
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setNewPlate(detection.plate);
                                                        setEditingPlate(false);
                                                    }}
                                                >
                                                    <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} icon={faXmark} fontSize={16} />
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    onPress={saveNewPlate}
                                                >
                                                    <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} icon={faCheck} fontSize={16} />
                                                </TouchableOpacity>
                                            </>
                                            :
                                            <>
                                                <MyAppText style={styles.plateText}>
                                                    {detection.plate}
                                                </MyAppText>
                                                {authenticatedUser?.isAdmin || authenticatedUser?.permissions.lpr_edit_detection ?
                                                    <TouchableOpacity
                                                        onPress={() => setEditingPlate(true)}
                                                    >
                                                        <FontAwesomeIcon color={getThemedColor(theme, '#58595B')} icon={faPencil} fontSize={12} />
                                                    </TouchableOpacity>
                                                    : null
                                                }
                                            </>
                                        }
                                    </View>
                                    <View style={{ flexDirection: 'row', gap: 30 }}>
                                        <View>
                                            <View style={styles.visualizedRow} >
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {translate('brand')}:
                                                </MyAppText>
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection['vehicleDescription.brand']}
                                                </MyAppText>
                                            </View>
                                            <View style={styles.visualizedRow} >
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {translate('model')}:
                                                </MyAppText>
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection['vehicleDescription.model']}
                                                </MyAppText>
                                            </View>
                                            <View style={styles.visualizedRow} >
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {translate('color')}:
                                                </MyAppText>
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection['vehicleDescription.color']}
                                                </MyAppText>
                                            </View>
                                            <View style={styles.visualizedRow} >
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {translate('vehicleType')}:
                                                </MyAppText>
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection['vehicleDescription.type']}
                                                </MyAppText>
                                            </View>
                                            <View style={styles.visualizedRow} >
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {translate('origin')}:
                                                </MyAppText>
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection['vehicleDescription.city']}
                                                </MyAppText>
                                            </View>
                                            <View style={styles.visualizedRow} >
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {translate('year')}:
                                                </MyAppText>
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection['vehicleDescription.modelYear']}
                                                </MyAppText>
                                            </View>
                                        </View>
                                        <View style={{ gap: 5 }}>
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}>
                                                <FontAwesomeIcon style={{ width: 22 }} fontSize={12} color={getThemedColor(theme, '#000028')} icon={faLocationDot} />
                                                <MyAppText style={styles.cameraInfoText}>{detection.camera.address}</MyAppText>
                                            </View>
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}>
                                                <FontAwesomeIcon style={{ width: 22 }} fontSize={10} color={getThemedColor(theme, '#000028')} icon={faVideo} />
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {detection.camera.title}
                                                </MyAppText>
                                            </View>
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}>
                                                <FontAwesomeIcon style={{ width: 22 }} fontSize={12} color={getThemedColor(theme, '#000028')} icon={faCalendarCheck} />
                                                <MyAppText style={styles.cameraInfoText}>
                                                    {moment(detection.time).format('DD/MM/YYYY HH:mm:ss')}
                                                </MyAppText>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ flex: 1, minWidth: 300, minHeight: 200 }}>
                            <Map pinnedMarker={{
                                lat: detection.camera.latitude,
                                lng: detection.camera.longitude
                            }} />
                        </View>
                    </View>
                </View>
            </DetectionDetailPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10,
            minHeight: 65
        },
        headerText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            columnGap: 20,
            rowGap: 5,
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        downloadButton: {
            backgroundColor: getThemedColor(theme, '#EEEEEE'),
            borderRadius: 2,
            padding: 9,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 150,
            columnGap: 5
        },
        downloadButtonText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 13
        },
        plateText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            fontWeight: 'bold'
        },
        cameraInfoText: {
            color: getThemedColor(theme, '#000028'),
            fontSize: 12
        }
    });
}
