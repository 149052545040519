const lightToDark = {
    '#FFFFFF': '#181A1B',
    '#000000': '#FFFFFF',
    '#E6E6E6': '#373C3E',
    '#000028': '#FFFFFF',
    '#CBCBCB': '#3F4447',
    '#222222': '#D3CFC9',
    '#D3CFC9': '#222222',
    '#58595B': '#D3CFC9',
    '#F7F7F7': '#1D1F20',
    '#CCCCCC': '#3F4447',
    '#040745': '#888888',
    '#888888': '#888888',
    '#EEEEEE': '#2A2A2A',
    '#B5B5B5': '#666666',
    '#008E2C': '#00BA39',
    '#D50000': '#D50000'
};

type color = keyof typeof lightToDark;

export default function getThemedColor(theme: Theme, color: color) {
    if (theme == 'light') {
        return color;
    }
    if (color in lightToDark) {
        return lightToDark[color];
    }

    return color;
}
