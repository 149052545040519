import React, { CSSProperties, useEffect, useRef } from 'react';
import { View } from 'react-native';
import MyAppText from '../MyAppText';
import { translate } from '../../services/translate';

interface PlateImageProps {
    src: string | null;
    platePosition?: { x: number, y: number; }[];
    style?: CSSProperties | undefined;
    width?: number;
    height?: number;
}

export default function PlateImage({ style, src, platePosition, width, height }: PlateImageProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const canvasWidth = width || 100;
    const canvasHeight = height || 50;

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!src || !platePosition) {
            return;
        }

        const image = new Image();

        const rect = {
            minX: Infinity,
            maxX: 0,
            minY: Infinity,
            maxY: 0
        };

        for (const position of platePosition) {
            if (position.x < rect.minX) {
                rect.minX = position.x;
            }

            if (position.x > rect.maxX) {
                rect.maxX = position.x;
            }

            if (position.y < rect.minY) {
                rect.minY = position.y;
            }

            if (position.y > rect.maxY) {
                rect.maxY = position.y;
            }
        }

        image.onload = () => {
            const resizeImageBy = Math.min(canvasWidth / (rect.maxX - rect.minX), canvasHeight / (rect.maxY - rect.minY));
            image.width *= resizeImageBy;
            image.height *= resizeImageBy;

            const xRatio = image.width / image.naturalWidth;
            const yRatio = image.height / image.naturalHeight;

            ctx.drawImage(
                image,
                rect.minX * xRatio * -1,
                rect.minY * yRatio * -1,
                image.width,
                image.height
            );
        };

        image.src = src;
    });

    return (
        <View style={{ width: canvasWidth, height: canvasHeight, justifyContent: 'center', alignContent: 'center' }}>
            {platePosition ?
                <canvas style={style} width={canvasWidth} height={canvasHeight} ref={canvasRef}></canvas>
                : <MyAppText style={{ textAlign: 'center', color: '#f00' }}>{translate('noImage')}</MyAppText>}
        </View>
    );
}
