import React, { useContext, useState } from 'react';
import { ScrollView, View, TouchableOpacity, Image, StyleSheet, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faLocationDot, faPlay, faVideo, faSearchPlus, faTimes, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import personDefaultPicture from '../../../../assets/person-default-picture.png';
import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import { TextMask } from 'react-native-masked-text';
import OccurrenceVideoModal from './OccurrenceVideoModal';
import { dispatchService } from '../../../services/central-api/dispatch';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../../../services/window-information';
import OccurrenceCaptureHistoryModal from './OccurrenceCaptureHistoryModal';

export default function OccurrenceFormFaceDetected({
    event, navigation, occurrenceId, imageUrl
}: {
    event: FacialDetection;
    navigation: Navigation;
    occurrenceId: number;
    imageUrl: string;
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [isVideoModalVisible, setVideoModalVisible] = useState(false);
    const [isCaptureHistoryModalVisible, setCaptureHistoryModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();

    function PersonInfoInRow({ title, desc }: { title: string; desc: string; }) {

        return <View style={{ flexDirection: 'row' }}>
            <MyAppText style={styles.personInfoTitleText}>
                {title}:
            </MyAppText>
            <MyAppText style={styles.personInfoText}>
                {desc}
            </MyAppText>
        </View>;
    }

    return (
        <ScrollView>
            <View>
                <View style={{ flexDirection: 'row' }}>
                    <MyAppText style={styles.nameText}>
                        {event.person?.name}
                    </MyAppText>
                    {event.person?.nickname ?
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <MyAppText style={styles.personInfoTitleText}>
                                {translate('nickname')}:
                            </MyAppText>
                            <MyAppText style={styles.personInfoText}>
                                {event.person.nickname}
                            </MyAppText>
                        </View>
                        : null
                    }
                </View>
                <View style={{ flexDirection: 'row', paddingVertical: 20, gap: 8 }}>
                    {event.person?.markers?.map((marker) =>
                        <View key={marker.id} style={[styles.marker, { backgroundColor: marker.color }]}>
                            <MyAppText style={styles.markerText}>
                                {marker.name}
                            </MyAppText>
                        </View>
                    )}
                </View>
                <Hoverable>
                    {({ hovered }) => (<>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }} >
                            <View>
                                <MyAppText style={styles.imageText}>
                                    {translate('detection')}
                                </MyAppText>
                                <Image
                                    style={{
                                        width: 180,
                                        height: 180,
                                        resizeMode: 'cover',
                                        borderRadius: 5,
                                        overflow: "hidden"
                                    }}
                                    source={{ uri: `${imageUrl}` }}
                                />
                            </View>
                            <View style={{ paddingLeft: 15 }}>
                                <MyAppText style={styles.imageText}>
                                    {translate('reference')}
                                </MyAppText>
                                <Image
                                    style={{
                                        height: 180,
                                        width: 180,
                                        borderRadius: 5,
                                        overflow: "hidden",
                                    }}
                                    source={event.person?.facePictures.length ? { uri: event.person?.facePictures[0].imageUrl } : personDefaultPicture}
                                />
                            </View>
                        </View>

                        {hovered &&
                            <View style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#00000080',
                            }}>
                                <TouchableOpacity
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center'
                                    }}
                                    onPress={async () => {
                                        setIsModalVisible(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                                </TouchableOpacity>
                            </View>
                        }
                    </>)}
                </Hoverable>
                <MyAppText style={styles.confidenceText}>
                    {event.confidence ?
                        translate('confidence') + ': ' + (Number(event.confidence) * 100).toFixed(0) + '%'
                        : null
                    }
                </MyAppText>
                <View style={{ rowGap: 5 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                            <FontAwesomeIcon icon={faPlay} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                            <TouchableOpacity onPress={() => setVideoModalVisible(true)}>
                                <MyAppText style={styles.watchVideoText}>
                                    {translate('watchVideo')}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                            <FontAwesomeIcon icon={faUserFriends} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                            <TouchableOpacity onPress={() => setCaptureHistoryModalVisible(true)}>
                                <MyAppText style={styles.watchVideoText}>
                                    {translate('captureHistory')}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }} >
                        <FontAwesomeIcon icon={faVideo} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('CameraPlayer', { id: event.camera.id });
                        }}>
                            <MyAppText style={styles.detectionInfoText}>
                                {event.camera.title}
                            </MyAppText>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 5 }}>
                        <FontAwesomeIcon icon={faLocationDot} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                        <MyAppText style={styles.detectionInfoText}>
                            {event.camera.address}
                        </MyAppText>
                    </View>
                </View>

                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.documentContainer}>
                        <MyAppText style={styles.personDocumentInfoText}>CPF:</MyAppText>
                        <TextMask
                            style={[styles.personDocumentInfoText, { fontFamily: 'Open Sans' }]}
                            value={event.person?.document}
                            type={'cpf'}
                        />
                    </View>
                    <View style={styles.documentContainer}>
                        <MyAppText style={styles.personDocumentInfoText}>
                            {translate('personRegister')}:
                        </MyAppText>
                        <MyAppText style={styles.personDocumentInfoText}>
                            {event.person?.register}
                        </MyAppText>
                    </View>
                    <View style={styles.documentContainer}>
                        <MyAppText style={styles.personDocumentInfoText}>
                            {translate('registeredOn')}:
                        </MyAppText>
                        <MyAppText style={styles.personDocumentInfoText}>
                            {moment(new Date(event.person?.createdAt || '')).format('DD/MM/YYYY HH:mm:ss')}
                        </MyAppText>
                    </View>
                </View>
                {
                    event.person?.comment ?
                        <View style={{ paddingVertical: 20 }}>
                            <MyAppText style={styles.personInfoTitleText}>
                                {translate('additionalComments')}:
                            </MyAppText>
                            <MyAppText style={styles.personInfoText}>
                                {event.person.comment}
                            </MyAppText>
                        </View>
                        : null
                }
                <View style={{ paddingVertical: 20 }}>
                    {event.person?.city ?
                        <View style={{ flexDirection: 'row' }}>
                            <MyAppText style={styles.personInfoTitleText}>
                                {translate('city')}:
                            </MyAppText>
                            <MyAppText style={styles.personInfoText}>
                                {event.person.city}/{event.person.state}
                            </MyAppText>
                        </View>
                        : null
                    }
                </View>

                <MyAppText style={[styles.nameText, { paddingBottom: 5 }]}>
                    {translate('arrestWarrants')}:
                </MyAppText>
                {
                    event.person?.lawsuits?.map((lawsuit) => (
                        <View key={lawsuit.id} style={{ paddingBottom: 20 }}>
                            <PersonInfoInRow
                                title={translate('situation')}
                                desc={lawsuit.situation}
                            />
                            <PersonInfoInRow
                                title={translate('arrestWarrantNumber')}
                                desc={lawsuit.arrestWarrantNumber}
                            />
                            <PersonInfoInRow
                                title={translate('shippingDate')}
                                desc={lawsuit.shippingDate}
                            />
                            <PersonInfoInRow
                                title={translate('expirationDate')}
                                desc={lawsuit.expirationDate}
                            />
                            <PersonInfoInRow
                                title={translate('lawsuitNumber')}
                                desc={lawsuit.lawsuitNumber}
                            />
                            <PersonInfoInRow
                                title={translate('prisonKind')}
                                desc={lawsuit.prisonKind}
                            />
                            <PersonInfoInRow
                                title={translate('magistrate')}
                                desc={lawsuit.magistrate}
                            />
                            <PersonInfoInRow
                                title={translate('issuingBody')}
                                desc={lawsuit.issuingBody}
                            />
                            <PersonInfoInRow
                                title={translate('county')}
                                desc={lawsuit.lawsuitCity}
                            />
                        </View>
                    ))
                }
            </View >
            <OccurrenceVideoModal
                occurrenceId={occurrenceId || 0}
                occurrenceTime={new Date(event.time).getTime()}
                isModalVisible={isVideoModalVisible}
                setModalVisible={setVideoModalVisible}
                apiService={dispatchService}
            />
            <OccurrenceCaptureHistoryModal
                occurrenceId={occurrenceId || 0}
                isModalVisible={isCaptureHistoryModalVisible}
                setModalVisible={setCaptureHistoryModalVisible}
            />

            {/* Zoom image modal */}
            <Modal
                animationType="fade"
                transparent={true}
                visible={isModalVisible}
                onRequestClose={() => setIsModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : null]}>
                            <View style={{ alignItems: 'flex-end' }}>
                                <TouchableOpacity
                                    style={{ width: 20, height: 20 }}
                                    onPress={() => setIsModalVisible(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={getThemedColor(theme, '#58595B')} />
                                </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'row', gap: 15 }} >
                                <View style={{ flex: 1 }}>
                                    <MyAppText style={styles.imageText}>
                                        {translate('detection')}
                                    </MyAppText>
                                    <Image
                                        style={{
                                            width: 360,
                                            height: 360,
                                            resizeMode: 'cover',
                                            borderRadius: 5,
                                            overflow: "hidden"
                                        }}
                                        source={{ uri: `${event.imageUrl}` }}
                                    />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <MyAppText style={styles.imageText}>
                                        {translate('reference')}
                                    </MyAppText>
                                    <Image
                                        style={{
                                            height: 360,
                                            width: 360,
                                            borderRadius: 5,
                                            overflow: "hidden",
                                        }}
                                        source={event.person?.facePictures.length ? { uri: event.person?.facePictures[0].imageUrl } : personDefaultPicture}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </ScrollView >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            padding: 16,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            borderWidth: 1,
        },
        imageText: {
            color: getThemedColor(theme, '#888888'),
            paddingBottom: 5,
            fontSize: 11,
        },
        detectionInfoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        confidenceText: {
            color: getThemedColor(theme, '#888888'),
            fontSize: 14,
            paddingVertical: 10
        },
        nameText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 20
        },
        personInfoTitleText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 16
        },
        personInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 16
        },
        documentContainer: {
            paddingRight: 35
        },
        personDocumentInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 14
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        watchVideoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            textDecorationLine: 'underline'
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199',
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            padding: 30,
        },
    });
}
