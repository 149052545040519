import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, View, StyleSheet, ActivityIndicator, TextInput, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from "../../components/DefaultPageContainer";
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from "../../services/translate";
import MyAppText from '../../components/MyAppText';
import { Pagination } from '../../components/Pagination';
import moment from 'moment';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyCheckbox } from '../../components/MyCheckbox';
import { cameraService, getCamerasPaginatedParameters } from '../../services/central-api/cameras';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../typings/Params';

const limit = 100;

export default function CamerasList({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cameras, setCameras] = useState<PaginatedCamera[]>([]);
    const styles = getStyles(theme);

    const route = useRoute<RouteProp<CamerasParamList, 'CamerasList'>>();

    // filters
    const [filters, setFilters] = useState<getCamerasPaginatedParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit,
        offline: route.params.offline,
        showDisabled: false,
        hideChildren: false,
    });
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [offlineFilter, setOfflineFilter] = useState(filters.offline ?? false);

    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    useEffect(() => {
        if (!loaders.current.didTextFilterLoad) {
            loaders.current.didTextFilterLoad = true;
            return;
        }
    }, [textFilter]);

    async function getCamerasList(filters: getCamerasPaginatedParameters) {
        try {
            setIsLoading(true);
            const response = await cameraService.getCamerasPaginated(filters);
            setCameras(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter,
            offline: offlineFilter
        });

        navigation.setParams({
            page: 1,
            textFilter, offline: offlineFilter
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    useEffect(() => {
        getCamerasList(filters);
    }, [filters]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='cameras' lateralMenu='cameras' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={{ flex: 1, rowGap: 15 }}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                            <MyAppText style={styles.filterText}>{translate('searchCamera')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                onKeyPress={handleKeyDown}
                                placeholder={translate('cameraTitleIdOrSerialNumber')}
                            />
                        </View>
                        <View style={{ justifyContent: 'flex-end', flexGrow: 1 }}>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={translate('offline')}
                                checked={offlineFilter}
                                setChecked={(value) => {
                                    if (!value && !filters.offline) {
                                        return;
                                    }

                                    setOfflineFilter(value);
                                }}
                            />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cameraTable}>
                        <View style={{ rowGap: 7 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ flexBasis: '30%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('title')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '10%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('serialNo')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '10%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('type')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '10%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('status')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '25%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('tags')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '10%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('registeredAt')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '5%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('action')}</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableLine}></View>
                        </View>
                        <ScrollView>
                            <View style={{ gap: 10 }}>
                                {isLoading ?
                                    <View>
                                        <ActivityIndicator size="small" color={getThemedColor(theme, '#000000')} />
                                    </View> : cameras.length ?
                                        cameras.map((camera, cameraIndex) => (
                                            <View key={cameraIndex} style={{ rowGap: 9 }}>
                                                <View style={styles.row}>
                                                    <View style={{ flexBasis: '30%' }}>
                                                        <MyAppText style={styles.cell}>{camera.title}</MyAppText>
                                                    </View>
                                                    <View style={{ flexBasis: '10%' }}>
                                                        <MyAppText style={styles.cell}>{camera.serialNo}</MyAppText>
                                                    </View>
                                                    <View style={{ flexBasis: '10%' }}>
                                                        <MyAppText style={styles.cell}>{translate(camera.type)}</MyAppText>
                                                    </View>
                                                    <View style={{ flexBasis: '10%' }}>
                                                        <MyAppText style={[styles.cell, (camera.isConnected || camera.type == 'lpr') ? {} : { color: '#FF0027' }]}>
                                                            {(camera.isConnected || camera.type == 'lpr') ? translate('online') : translate('offline')}
                                                        </MyAppText>
                                                    </View>
                                                    <View style={{ flexBasis: '25%', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5 }}>
                                                        {camera.tags ?
                                                            camera.tags.map((tag) =>
                                                                <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                                    <MyAppText style={[styles.markerText]}>
                                                                        {tag.name}
                                                                    </MyAppText>
                                                                </View>
                                                            ) : null
                                                        }
                                                    </View>
                                                    <View style={{ flexBasis: '10%' }}>
                                                        <MyAppText style={styles.cell}>{moment(camera.createdAt).format('DD/MM/YYYY HH:mm')}</MyAppText>
                                                    </View>
                                                    <TouchableOpacity onPress={() => navigation.navigate('CameraDetails', { id: camera.id })} style={{ flexBasis: '5%', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                        <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={styles.tableLine}></View>
                                            </View>
                                        )) :
                                        <View>
                                            <MyAppText style={{ textAlign: 'center', color: getThemedColor(theme, '#58595B'), marginTop: 10 }}>
                                                {translate('noResults')}.
                                            </MyAppText>
                                        </View>
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({ ...route.params, page: page + 1 });
                    }} />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        cameraTable: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1 // If you want to have a scrollable in the middle of the code the wrapped div must have this
        },
        headerContent: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 10,
            flexWrap: 'wrap',
            rowGap: 10
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            paddingLeft: 8,
            paddingRight: 8,
            height: 38
        },
        marker: {
            borderRadius: 8,
            minHeight: 16,
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: '100%'
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        buttonsContainer: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10
        }
    });
}
