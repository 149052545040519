import VehiclesModal from './VehiclesModal';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { Pagination } from '../../components/Pagination';
import { dispatchService, getVehiclesPaginatedParameters } from '../../services/central-api/dispatch';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import MyDataTable from '../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';

const limit = 200;

export default function Vehicles({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const loaders = useRef({
        didTextFilterLoad: false,
        didStatusFilterLoad: false,
        didPageLoad: false
    });
    const route = useRoute<RouteProp<DispatchParamList, 'Vehicles'>>();

    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);
    const [isStatusPickerOpen, setIsStatusPickerOpen] = useState(false);
    const [filters, setFilters] = useState<getVehiclesPaginatedParameters>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        status: route.params.status ?? 'active',
        limit
    });
    const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>(filters.status || 'active');
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);

    async function getVehicles(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getVehicles(filters);
            setVehicles(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    useEffect(() => {
        getVehicles();
    }, [filters]);

    function handleEditClick(vehicle: Vehicle) {
        setSelectedVehicle(vehicle);
        setModalVisible(true);
        getVehicles();
    }

    function handleFormClose() {
        setSelectedVehicle(undefined);
        setModalVisible(false);
        getVehicles();
    }

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter,
            status: selectedStatusFilter
        });

        navigation.setParams({
            page: 1,
            textFilter,
            status: selectedStatusFilter
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='vehicles' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 100, maxWidth: 250 }}>
                            <MyAppText style={styles.filterText}>{translate('searchVehicle')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('searchVehiclePlateOrRegistry')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ flexGrow: 1, minWidth: 100, maxWidth: 250, zIndex: 3 }}>
                            <MyAppText style={styles.filterText}>{translate('availability')}</MyAppText>
                            <MyDropDownPicker
                                open={isStatusPickerOpen}
                                value={selectedStatusFilter}
                                items={['active', 'inactive', 'based', 'maintenance'].map((item: string) => {
                                    return { label: translate(item), value: item };
                                })}
                                setOpen={setIsStatusPickerOpen}
                                setValue={(value) => {
                                    setSelectedStatusFilter(value(selectedStatusFilter));
                                }}
                                multiple={false}
                                searchable={true}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>

                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.button} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.buttonText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('vehicle_metadata_registry'),
                                    selector: row => row.prefix || '',
                                },
                                {
                                    name: translate('actingBody'),
                                    selector: row => row.actingBody?.name || '',
                                },
                                {
                                    name: translate('vehicle_metadata_plate'),
                                    selector: row => row.plate,
                                },
                                {
                                    name: translate('vehicle_metadata_type'),
                                    selector: row => row.type ? translate(row.type) : ''
                                },
                                {
                                    name: translate('availability'),
                                    cell: row =>
                                        <View style={{ width: '100%' }}>
                                            <View style={styles.status}>
                                                <View style={[styles.circle, row.status === 'active' ? styles.green : styles.red]} />
                                                <MyAppText>{row.status === 'active' ? translate('active_f') : translate(row.status || 'inactive')}</MyAppText>
                                            </View>
                                        </View>
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity style={{ alignItems: 'flex-start' }} onPress={() => handleEditClick(row)}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={vehicles}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <VehiclesModal
                                    onClose={handleFormClose}
                                    vehicle={selectedVehicle}
                                />
                            </View>
                        </View>
                    </Modal>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 15,
            flexWrap: 'wrap',
            rowGap: 10,
            zIndex: 1
        },
        circle: {
            width: 15,
            height: 15,
            borderRadius: 7.5,
        },
        red: {
            backgroundColor: 'red',
        },
        green: {
            backgroundColor: 'green',
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        status: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        buttonsContainer: {
            marginTop: 20,
            marginLeft: 'auto',
        },
        button: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        buttonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
    });
}
