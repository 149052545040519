import React, { useContext, useState } from 'react';
import DefaultPageContainer from '../components/DefaultPageContainer';
import { View, Image, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../components/MyAppText';
import { translate } from '../services/translate';
import { useNavigationState } from '@react-navigation/native';
import { WindowInformation } from '../services/window-information';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import UserInformationModal from '../components/UserInformationModal';
import releases from '../services/version.json';

const headerHeight = 64;

interface Release {
    version: string;
    content: string[];
}

interface ChangeLogsComponentProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function ChangeLogs({ navigation, authenticatedUser }: ChangeLogsComponentProps) {
    const { theme } = useContext(ThemeContext);
    const index = useNavigationState(state => state);
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const windowInfo = WindowInformation();
    const insets = useSafeAreaInsets();

    const styles = getStyles(theme);

    function goBack() {
        if (index.routes.length > 1) {
            return navigation.goBack();
        }
        window.location.href = '/menu';
    }

    function renderItem({ item }: { item: Release; }) {
        return (
            <View style={{ rowGap: 15 }}>
                <MyAppText style={styles.versionText}>{translate('version')}: {item.version}</MyAppText>
                <View style={{ rowGap: 5 }}>
                    {item.content.map((change, index) => (
                        <MyAppText key={index}>{`\u2022 ${change}`}</MyAppText>
                    ))}
                </View>
            </View>
        );
    }

    return (
        <DefaultPageContainer>
            <View style={styles.headerBar}>
                <View style={{ width: 24, height: 24 }}>
                </View>
                <View style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {windowInfo.isMobile ?
                        <Image source={require(`../../assets/smartsampa_logo_${theme}.png`)} style={styles.headerLogo} />
                        : <Image source={require(`../../assets/big_smartsampa_logo_${theme}.png`)} style={styles.bigHeaderLogo} />
                    }                </View>
                <TouchableOpacity onPress={() => setShowDropDown(!showDropDown)}>
                    <FontAwesomeIcon icon={faCircleUser} color={getThemedColor(theme, '#000000')} fontSize={24} />
                </TouchableOpacity>
            </View>
            <View style={[styles.container, {
                width: windowInfo.width,
                height: windowInfo.height - headerHeight - insets.top - insets.bottom,
            }]}>
                <View style={styles.header}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        <TouchableOpacity onPress={goBack}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                        </TouchableOpacity>
                        <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('changeLogs')}
                        </MyAppText>
                    </View>
                </View>
                <View style={styles.cardList}>
                    <FlatList data={releases} contentContainerStyle={{ rowGap: 30 }} renderItem={renderItem} />
                </View>
            </View>
            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            rowGap: 15,
            padding: 10
        },
        headerBar: {
            height: headerHeight,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            border: `1px solid ${getThemedColor(theme, '#E6E6E6')}`,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 14
        },
        headerLogo: {
            height: 40,
            width: 275,
        },
        bigHeaderLogo: {
            height: 40,
            width: 700,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
        },
        versionText: {
            fontWeight: 'bold',
            fontSize: 24
        },
        releasedDateText: {
            color: getThemedColor(theme, '#888888'),
            fontSize: 12
        }
    });
}
