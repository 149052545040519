import React from 'react';
import { TextStyle, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyAppText from '../../../components/MyAppText';

interface Props {
    value: string;
    title: string;
    maxWidth?: TextStyle['maxWidth'];
}

function Label({ title, value, maxWidth }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <View style={styles.container}>
            <MyAppText style={styles.title}>{title}</MyAppText>
            <MyAppText style={styles.value(maxWidth)}>{value}</MyAppText>
        </View>
    );
}

const stylesheet = createStyleSheet(() => ({
    container: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        flexShrink: 1
    },

    title: {
        fontSize: 16,
        fontWeight: '700'
    },

    value: (maxWidth: TextStyle['maxWidth'] = 200) => ({
        fontSize: 16,
        maxWidth
    })
}));

export default Label;
