/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { WindowInformation } from '../services/window-information';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import MyAppText from './MyAppText';
import { translate } from '../services/translate';

const messageConfig = {
    invalidAuth: (params: MessageOptions) => {
        const { theme } = useContext(ThemeContext);

        return <View style={{
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            padding: 25,
            alignItems: 'center',
            width: 350,
            gap: 20
        }}>
            <MyAppText style={{ color: getThemedColor(theme, '#222222') }}>
                {translate('authError')}
            </MyAppText>
            <TouchableOpacity
                style={{
                    marginLeft: 20,
                    borderRadius: 4,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 120,
                    height: 40,
                    borderWidth: 1,
                    borderColor: getThemedColor(theme, '#CCCCCC'),
                }}
                onPress={() => {
                    params.hide();
                    window.location.href = '/login';
                }}>
                <MyAppText style={{ color: getThemedColor(theme, '#222222') }}>
                    OK
                </MyAppText>
            </TouchableOpacity>
        </View >;
    }
};

interface MessageProps {
    // type: keyof typeof messages;
}

interface MessageOptions extends MessageShowParams {
    hide: () => void;
}

const MessageRoot = React.forwardRef((props: MessageProps, ref) => {

    const [isVisible, setIsVisible] = useState(false);
    const windowInfo = WindowInformation();

    const initialOptions: MessageOptions = {
        type: 'invalidAuth',
        hide: () => setIsVisible(false)
    };

    const [options, setOptions] = React.useState<MessageOptions>(initialOptions);

    const show = React.useCallback(
        (params: MessageShowParams) => {
            setOptions({ ...options, ...params });
            setIsVisible(true);
        },
        []
    );

    React.useImperativeHandle(
        ref,
        React.useCallback(
            () => ({
                show
            }),
            [show]
        )
    );

    if (!isVisible) {
        return <></>;
    }

    return <View style={{
        backgroundColor: '#31313199',
        height: windowInfo.height,
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        {messageConfig[options.type](options)}
    </View>;

});

type MessageRefObj = {
    current: MessageRef | null;
};

let refs: MessageRefObj[] = [];

type MessageRef = {
    show: (params: MessageShowParams) => void;
};

interface MessageShowParams {
    type: keyof typeof messageConfig;
}

function addNewRef(newRef: MessageRef) {
    refs.push({
        current: newRef
    });
}


function removeOldRef(oldRef: MessageRef | null) {
    refs = refs.filter((r) => r.current !== oldRef);
}

export function MyMessage(props: MessageProps) {
    const messageRef = React.useRef<MessageRef>(null);

    const setRef = React.useCallback((ref: MessageRef | null) => {
        if (ref) {
            addNewRef(ref);
        } else {
            removeOldRef(messageRef.current);
        }
    }, []);

    return (
        <MessageRoot ref={setRef} {...props} />
    );
}

function getRef() {
    const reversePriority = [...refs].reverse();
    const activeRef = reversePriority.find((ref) => ref?.current !== null);
    if (!activeRef) {
        return null;
    }
    return activeRef.current;
}


MyMessage.show = (params: MessageShowParams) => {
    getRef()?.show(params);
};
