import React, { useEffect, useState } from 'react';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { TagPageContent } from '../../components/TagPageContent';
import Toast from 'react-native-toast-message';
import { translate } from '../../services/translate';
import { View, StyleSheet } from 'react-native';
import { dispatchService } from '../../services/central-api/dispatch';

interface TagsParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

export default function OccurrenceTags({ navigation, authenticatedUser }: TagsParams) {
    const styles = getStyles();
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);

    async function getTags() {
        try {
            setLoading(true);
            const tags = await dispatchService.getOccurrenceTags();
            setTags(tags);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getTags();
    }, []);

    async function deleteTag(tag: Tag) {
        try {
            if (tag.id) {
                await dispatchService.deleteOccurrenceTag(tag.id);
            }
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function createTag(params: CreateTag) {
        const newTag = await dispatchService.createOccurrenceTag(params);
        return newTag;
    }

    async function updateTag(id: number, params: UpdateTag) {
        await dispatchService.updateOccurrenceTag(id, params);
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='occurrence_tags' lateralMenu='dispatch' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <TagPageContent
                        tags={tags}
                        setTags={setTags}
                        getTags={getTags}
                        loading={loading}
                        deleteTag={deleteTag}
                        createTag={createTag}
                        updateTag={updateTag}
                        hasRequiredPermission={authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_occurrence_tags}
                        associatedLabel={translate('associatedOccurrences')}
                    />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles() {
    return StyleSheet.create({
        container: {
            flex: 1,
        },
    });
}
