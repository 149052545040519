export function downloadImage(params: { fileName: string; imageUrl: string; }) {

    const image = new Image();
    image.crossOrigin = "anonymous";

    image.onload = () => {
        const download = document.createElement('a');
        const canvas = document.createElement('canvas');

        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(image, 0, 0, image.width, image.height);

        download.addEventListener("click", () => {
            const url = canvas.toDataURL('image/jpeg');
            download.download = `${params.fileName}.jpg`;
            download.href = url;

        });

        download.click();
    };

    image.src = params.imageUrl;
}
