import { TranslateOptions, I18n } from "i18n-js";
import en from "../languages/english.json";
import pt from "../languages/portuguese.json";

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';


const i18n = new I18n();

async function ChangeLanguage(lang: string) {

    if (lang == '') {
        i18n.locale = Localization.locale;
    } else {
        i18n.locale = lang;
    }
    await AsyncStorage.setItem('Translate', lang);
}

i18n.translations = { en, pt };
i18n.enableFallback = true;
i18n.locale = Localization.locale;

async function getDefaultLanguage() {
    const defaultLanguage = await AsyncStorage.getItem('Translate');
    if (!defaultLanguage) {
        return "pt-br";
    }
    return defaultLanguage;
}

const translate = (key: string, options?: TranslateOptions) => i18n.t(key, options);
export { getDefaultLanguage, ChangeLanguage, translate };
