import React, { useContext } from 'react';
import { View, StyleSheet, Modal, TouchableOpacity } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';


interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    onCancel?: () => void;
    itemName?: string;
    customMessage?: string;
    customConfirm?: string;
}

export default function DeleteModal({ isModalVisible, setModalVisible, onSubmit, onCancel, itemName, customMessage, customConfirm }: ModalProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    return (
        <Modal visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.container}>
                <View style={styles.body}>
                    <MyAppText style={styles.deleteConfirmationText}>{customMessage ?? (itemName ? `${translate('confirm_delete')}: ${itemName}` : `${translate('confirm_delete')}?`)}</MyAppText>
                    <View style={[styles.deleteButtonsView]}>
                        <TouchableOpacity style={[styles.button, styles.buttonClose]} onPress={() => {
                            onCancel && onCancel();
                            setModalVisible(false);
                        }}>
                            <MyAppText style={styles.buttonTextClose}>{translate('cancel')}</MyAppText>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button, styles.buttonDelete]} onPress={() => {
                            onSubmit();
                            setModalVisible(false);
                        }}>
                            <MyAppText style={styles.buttonTextDelete}>{customConfirm ?? translate('delete')} </MyAppText>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#31313199',
            flex: 1
        },
        body: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 4,
            padding: 25,
            rowGap: 20
        },
        deleteConfirmationText: {
            fontSize: 20,
        },
        deleteButtonsView: {
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: "wrap",
            justifyContent: 'space-between',
        },
        button: {
            marginLeft: 20,
            borderRadius: 4,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonClose: {
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
        },
        buttonTextClose: {
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
        buttonDelete: {
            backgroundColor: '#FE0127',
        },
        buttonTextDelete: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
        },
    });
}
