import { faCheckCircle, faExclamationCircle, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

import Toast, { BaseToastProps } from 'react-native-toast-message';
import MyAppText from '../components/MyAppText';

export default {
    sentinelxError: (props: BaseToastProps) => (
        <View style={[styles.container, { backgroundColor: '#FDC5C4' }]}>
            <View style={styles.content}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#B40119' }} />
                <MyAppText style={styles.overflowWrap}>{props.text1}</MyAppText>
            </View>
            <TouchableOpacity onPress={() => Toast.hide()}>
                <FontAwesomeIcon fontSize={15} icon={faTimes} />
            </TouchableOpacity>
        </View>
    ),
    sentinelxSuccess: (props: BaseToastProps) => (
        <View style={[styles.container, { backgroundColor: '#B2F4CB' }]}>
            <View style={styles.content}>
                <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#017B27' }} />
                <MyAppText style={styles.overflowWrap}>{props.text1}</MyAppText>
            </View>
            <TouchableOpacity onPress={() => Toast.hide()}>
                <FontAwesomeIcon fontSize={15} icon={faTimes} />
            </TouchableOpacity>
        </View>
    ),
    sentinelxWarning: (props: BaseToastProps) => (
        <View style={[styles.container, { backgroundColor: '#FCD3A3' }]}>
            <View style={styles.content}>
                <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#B8401B' }} />
                <MyAppText style={styles.overflowWrap}>{props.text1}</MyAppText>
            </View>
            <TouchableOpacity onPress={() => Toast.hide()}>
                <FontAwesomeIcon fontSize={15} icon={faTimes} />
            </TouchableOpacity>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        zIndex: 2147483646,
        minHeight: 56,
        width: 400,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        borderRadius: 5,
    },
    content: {
        columnGap: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    overflowWrap: {
        color: '#000000',
        overflow: 'visible',
        whiteSpace: 'break-spaces',
        textOverflow: 'ellipsis',
        width: 330
    }
});
