import React, { useContext } from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    ActivityIndicator,
} from 'react-native';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faVideo } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import { Hoverable } from 'react-native-web-hover';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import PlateImage from './PlateImage';
import LazyLoad from 'react-lazy-load';


interface EventListProps {
    events: LprDetection[];
    navigation: Navigation;
    hasMoreResults: boolean;
    isLoading: boolean;
    isPolling: boolean;
    loadMore?: () => void;
    page: 'liveEvents' | 'liveAlerts' | 'search' | 'map';
}


export default function EventList({ events, navigation, isLoading, isPolling, hasMoreResults, loadMore, page }: EventListProps) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    return (
        <View style={styles.container}>
            <View style={styles.cardList}>
                <View style={{ rowGap: 7 }}>
                    <View style={styles.header}>
                        <View style={{ flexBasis: '10%' }}></View>
                        <View style={{ flexBasis: '12%' }}>
                            <MyAppText style={styles.headerCell}>{translate('plate')}</MyAppText>
                        </View>
                        <View style={{ flexBasis: '58%' }}>
                            <MyAppText style={styles.headerCell}>{translate('information')}</MyAppText>
                        </View>
                        <View style={{ flexBasis: '20%' }}>
                            <MyAppText style={styles.headerCell}>{translate('location')}</MyAppText>
                        </View>
                    </View>
                    <View style={styles.tableLine}></View>
                </View>
                <ScrollView>
                    <View style={{ gap: 10 }}>
                        {isPolling || isLoading ?
                            <View>
                                <ActivityIndicator size='small' color={getThemedColor(theme, '#000000')} />
                            </View>
                            : null
                        }
                        {events.length ?
                            events.map((event, eventIndex) => (
                                <TouchableOpacity onPress={() => navigation.navigate('DetectionDetails', { detectionId: event.id, page: page })} key={eventIndex} style={{ rowGap: 9 }}>
                                    <View style={styles.row}>
                                        <View style={{ flexBasis: '10%' }}>
                                            <LazyLoad>
                                                <PlateImage
                                                    src={event.imageUrl}
                                                    platePosition={event.platePosition}
                                                />
                                            </LazyLoad>
                                        </View>
                                        <View style={{ flexBasis: '12%' }}>
                                            <MyAppText style={{ color: getThemedColor(theme, '#000000'), fontWeight: '500', fontSize: 16 }}>
                                                {event.plate}
                                            </MyAppText>
                                        </View>
                                        <View style={{ flexBasis: '58%', rowGap: 10 }}>
                                            {event.description ?
                                                <MyAppText style={styles.headerCell}>{event.description}</MyAppText> : null
                                            }
                                            <View style={{ flexDirection: 'row' }}>
                                                {event.violation ?
                                                    <View style={[styles.marker, { backgroundColor: '#DB5451' }]}>
                                                        <MyAppText style={styles.markerText}>{event.violation === 'on_phone' ? translate('onPhone') : translate('withoutBelt')}</MyAppText>
                                                    </View> : null
                                                }
                                            </View>
                                        </View>
                                        <View style={{ flexBasis: '20%', rowGap: 10 }}>
                                            <View style={styles.locationView} >
                                                <FontAwesomeIcon icon={faCalendarCheck} fontSize={13} style={styles.locationIcon} />
                                                <MyAppText style={styles.locationText}>
                                                    {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                                                </MyAppText>
                                            </View>

                                            <View style={styles.locationView} >
                                                <FontAwesomeIcon icon={faLocationDot} fontSize={13} style={styles.locationIcon} />
                                                <MyAppText style={styles.locationText}>
                                                    {event.camera.address}
                                                </MyAppText>
                                            </View>

                                            <View style={styles.locationView} >
                                                <FontAwesomeIcon icon={faVideo} fontSize={13} style={styles.locationIcon} />
                                                <MyAppText style={styles.locationText}>
                                                    {event.camera.title}
                                                </MyAppText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.tableLine}></View>
                                </TouchableOpacity >
                            ))
                            : null
                        }
                        {events.length && hasMoreResults && !isLoading && !isPolling ?
                            <Hoverable style={styles.loadMore}>
                                {({ hovered }) => (
                                    <TouchableOpacity style={[styles.loadMore, {
                                        borderBottomWidth: hovered ? 1 : 0
                                    }]} onPress={loadMore}>
                                        <MyAppText style={styles.loadMoreText}>{translate('loadMore')}...</MyAppText>
                                    </TouchableOpacity>

                                )}
                            </Hoverable>
                            : null
                        }

                        {!events.length && !isLoading ?
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <MyAppText style={{ color: getThemedColor(theme, '#58595B'), marginTop: 10 }}>
                                    {translate('noResults')}.
                                </MyAppText>
                            </View>
                            : null
                        }
                    </View>
                </ScrollView>
            </View>
        </View>);
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15,
            zIndex: -9999
        },
        header: {
            flexDirection: 'row',
        },
        headerCell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B')
        },
        locationView: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 5
        },
        locationIcon: {
            color: getThemedColor(theme, '#222222'),
        },
        locationText: {
            color: getThemedColor(theme, '#888888'),
            fontWeight: '500',
        },
        row: {
            flexDirection: 'row',
            rowGap: 15,
            alignItems: 'center'
        },
        loadMoreText: {
            color: getThemedColor(theme, '#000028'),
            fontSize: 16,
        },
        loadMore: {
            borderColor: getThemedColor(theme, '#000028'),
            alignItems: 'center',
            justifyContent: 'center'
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 8,
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        }
    });
}
