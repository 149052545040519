import React from 'react';
import LateralMenu from "../LateralMenu";
import { faClipboardList, faIdBadge, faRightFromBracket, faShield } from '@fortawesome/free-solid-svg-icons';
import logoEvent from '../../../assets/logo_gcm_agent.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'personOfInterest' | 'occurrences' | 'units';

interface GcmAgentLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function GcmAgentLateralMenu({ navigation, selectedMenu }: GcmAgentLateralMenuProps) {
    return <LateralMenu
        topImage={logoEvent}
        items={[{
            icon: faClipboardList,
            text: translate('occurrences'),
            action: () => navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' }),
            selected: selectedMenu == 'occurrences'
        }, {
            icon: faIdBadge,
            text: translate('personOfInterest'),
            action: () => navigation.navigate('GcmAgent', { screen: 'PersonOfInterest' }),
            selected: selectedMenu == 'personOfInterest'
        }, {
            icon: faShield,
            text: translate('teams'),
            action: () => navigation.navigate('GcmAgent', { screen: 'AgentUnits' }),
            selected: selectedMenu == 'units'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
