
import React, { useState, useRef, useCallback, useContext } from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    ActivityIndicator,
    ScrollView,
    FlatList,
    ListRenderItemInfo,
    Image,
} from 'react-native';

import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import personDefaultPicture from '../../../assets/person-default-picture.png';
import moment from 'moment';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { smartSearchFacialService } from '../../services/central-api/smart-search-facial';
import Toast from 'react-native-toast-message';
import { MoreThanOneFaceOnPhoto, NoFaceOnPhoto } from '../../services/central-api/facial';

interface PersonOfInterestParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

const videoConstraints = {
    facingMode: 'environment'
};

type PersonOfInterestSteps = 'takeShot' | 'previewImage' | 'listPeople';

export default function PersonOfInterest({ navigation, authenticatedUser }: PersonOfInterestParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const webcamRef = useRef<Webcam>(null);
    const [peopleOfInterest, setPeopleOfInterest] = useState<FacialPerson[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState<PersonOfInterestSteps>('takeShot');
    const [image, setImage] = useState<string | null>(null);

    const capture = useCallback(
        () => {
            if (!webcamRef.current) {
                return;
            }
            const image = webcamRef.current.getScreenshot();
            setImage(image);
            setStep('previewImage');
        },
        [webcamRef]
    );

    function stringToFileImage(imageData: string) {
        const base64ImageData = imageData.split(',')[1];

        const binaryData = atob(base64ImageData);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: 'image/png' });

        return new File([blob], 'sample-image.png', { type: 'image/png' });
    }

    async function facialDetect(image: string) {
        setIsLoading(true);
        try {
            const response = await smartSearchFacialService.gcmAgentFindPeopleByPicture(stringToFileImage(image));
            setStep('listPeople');
            setPeopleOfInterest(response.people);
        } catch (err) {
            if (err instanceof NoFaceOnPhoto || err instanceof MoreThanOneFaceOnPhoto) {
                return Toast.show({
                    type: 'sentinelxWarning',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            setStep('listPeople');
            setPeopleOfInterest([]);
        } finally {
            setIsLoading(false);
        }
    }

    function renderItem({ item }: ListRenderItemInfo<FacialPerson>) {
        return <View style={[styles.itemContainerInfo]}>
            <View style={styles.iconContainer}>
                <Image
                    source={item.facePictures.length ? { uri: item.facePictures[0].imageUrl } : personDefaultPicture}
                    style={styles.image} resizeMode='cover'
                />
            </View>
            <View style={styles.itemContainerUsersInfo}>
                <MyAppText style={styles.title} numberOfLines={3} adjustsFontSizeToFit>
                    {item.name}
                </MyAppText>
                <MyAppText numberOfLines={3} adjustsFontSizeToFit style={styles.subTitle}>
                    {translate('nickname')}: {item.nickname}
                </MyAppText>
                <View style={{ flexDirection: 'row', alignContent: 'center', flexWrap: 'wrap', width: '98%' }}>
                    {item.markers.map((marker) => {
                        return (
                            <View key={`${item.id}-${marker.id}`} style={[styles.marker, { backgroundColor: marker.color }]}>
                                <MyAppText numberOfLines={1} adjustsFontSizeToFit style={styles.markerText}>
                                    {marker.name}
                                </MyAppText>
                            </View>
                        );

                    })}
                </View>
                <MyAppText adjustsFontSizeToFit style={[styles.subTitle, { paddingTop: 10 }]}>
                    {translate('addedOn')} {moment(item.createdAt ? new Date(item.createdAt) : undefined).format('DD/MM/YYYY HH:mm')}
                </MyAppText>
            </View>
        </View>;
    }

    function separator() {
        return <View style={{ width: '99%', borderTopColor: '#E6E6E6', borderTopWidth: 1, alignSelf: 'center' }}></View>;
    }

    return (
        <DefaultPageContainer customBackgroundColor={step === 'takeShot' && !isLoading ? '#000' : undefined}>
            <DefaultPageLayout navigation={navigation} selectedMenu='personOfInterest' lateralMenu='gcmAgent' authenticatedUser={authenticatedUser}>
                {step == 'takeShot' ?
                    <View style={styles.webcamContainer}>
                        <Webcam
                            allowFullScreen
                            audio={false}
                            height={'100%'}
                            screenshotFormat='image/jpeg'
                            width={'100%'}
                            videoConstraints={videoConstraints}
                            ref={webcamRef}
                        />
                        <View style={styles.footer}>
                            <TouchableOpacity onPress={capture}>
                                <FontAwesomeIcon icon={faCircleRegular} color={'#fff'} fontSize={100} style={{
                                    position: 'absolute',
                                    top: -50,
                                    left: -50
                                }} />
                                <FontAwesomeIcon icon={faCircleSolid} color={'#fff'} fontSize={70} style={{
                                    position: 'absolute',
                                    top: -35,
                                    left: -35
                                }} />
                            </TouchableOpacity>
                        </View>
                    </View> : null
                }

                {step === 'previewImage' && !isLoading ?
                    <View style={styles.webcamContainer}>
                        <Image
                            style={{
                                flex: 1
                            }}
                            resizeMode='contain'
                            source={{
                                uri: image ?? undefined
                            }}
                        />
                        <TouchableOpacity onPress={() => {
                            setImage(null);
                            setStep('takeShot');
                        }}>
                            <View style={styles.cancelButton}>
                                <MyAppText style={styles.buttonsText}>{translate('cancel')}</MyAppText>

                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            if (image) {
                                facialDetect(image);
                            }
                        }}>
                            <View style={styles.confirmButton}>
                                <MyAppText style={styles.buttonsText}>{translate('confirm')}</MyAppText>

                            </View>
                        </TouchableOpacity>
                    </View>
                    : null
                }

                {step === 'previewImage' && isLoading ?
                    <View style={styles.loadingView}>
                        <MyAppText style={styles.loadingText}>
                            {translate('processing')}...
                        </MyAppText>
                        <ActivityIndicator
                            animating={isLoading}
                            style={styles.loading}
                            size='large'
                            color='#0071bc'
                        >
                        </ActivityIndicator>
                    </View>
                    : null
                }

                {step == 'listPeople' ? <View style={{
                    width: '100%',
                    height: '100%',
                    margin: 15
                }}>

                    <TouchableOpacity style={{
                        flexDirection: 'row',
                        height: 40,
                        alignItems: 'center',
                    }} onPress={() => setStep('takeShot')}>
                        <FontAwesomeIcon fontSize={30} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                        <MyAppText style={{ marginLeft: 10, fontSize: 24, color: getThemedColor(theme, '#000028'), fontWeight: 'bold' }}>
                            {translate('goBack')}
                        </MyAppText>
                    </TouchableOpacity>
                    {peopleOfInterest.length ?
                        <ScrollView>
                            <FlatList
                                data={peopleOfInterest}
                                renderItem={renderItem}
                                ItemSeparatorComponent={separator}
                            />
                        </ScrollView>
                        :
                        <View style={styles.loadingView}>
                            <MyAppText style={styles.loadingText}>{translate('noPersonFound')}</MyAppText>
                        </View>

                    }
                </View> : null}

            </DefaultPageLayout>
        </DefaultPageContainer >);

}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        itemContainerInfo: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingVertical: 10
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 8,
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        iconContainer: {
            justifyContent: 'center',
            marginRight: 5,
            width: 144,
            height: 144,
        },
        itemContainerUsersInfo: {
            flex: 1,
            justifyContent: 'flex-start',
            flexDirection: 'column',
            gap: 2
        },
        image: {
            width: '100%',
            height: 144,
            borderRadius: 8
        },
        webcamContainer: {
            backgroundColor: '#000',
            width: '100%',
            height: '100%'
        },
        loading: {
            marginHorizontal: 'auto',
            transform: [{ scaleX: 2 }, { scaleY: 2 }],
            width: '20%',
            height: '20%',
        },
        loadingText: {
            color: getThemedColor(theme, '#000000'),
            textAlign: 'center',
            fontSize: 16
        },
        loadingView: {
            display: 'flex',
            flexDirection: 'column',
            marginVertical: 'auto',
            justifyContent: 'space-between',
            height: 150
        },
        title: {
            fontSize: 20,
        },
        subTitle: {
            fontSize: 12,
        },
        footer: {
            position: 'absolute',
            bottom: 80,
            left: '50%',
            transform: [{ translateX: -65 }],
            justifyContent: 'center',
            alignItems: 'center',
            height: 130,
            width: 130,
            borderRadius: 20,
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
        },
        cancelButton: {
            position: 'absolute',
            bottom: 80,
            left: 130,
            transform: [{ translateX: -65 }],
            justifyContent: 'center',
            alignItems: 'center',
            height: 130,
            width: 130,
            borderRadius: 20,
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
        },
        confirmButton: {
            position: 'absolute',
            bottom: 80,
            right: 0,
            transform: [{ translateX: -65 }],
            justifyContent: 'center',
            alignItems: 'center',
            height: 130,
            width: 130,
            borderRadius: 20,
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
        },
        buttonsText: {
            color: '#fff',
            fontSize: 24
        }
    });
}
