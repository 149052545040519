import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import { Pagination } from '../../components/Pagination';
import moment from 'moment';
import { faEdit, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';
import { crmService, getPaginatedAlarmCenterParameters } from '../../services/central-api/crm';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import MyDataTable from '../../components/MyDataTable';
import { CRMParamList } from '../../typings/Params';

const limit = 25;

export default function AlarmCentersList({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [alarmCenters, setAlarmCenters] = useState<AlarmCenter[]>([]);

    const route = useRoute<RouteProp<CRMParamList, 'AlarmCentersList'>>();

    // filters
    const [filters, setFilters] = useState<getPaginatedAlarmCenterParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit,
    });
    const [page, setPage] = useState(filters.page);

    const [textFilter, setTextFilter] = useState(filters.textFilter);

    const loaders = useRef({ didPageLoad: false });

    useFocusEffect(useCallback(() => {
        getAlarmCentersList();
    }, [navigation, filters]));

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    async function getAlarmCentersList() {
        try {
            setIsLoading(true);
            const response = await crmService.getAlarmCenterPaginated(filters);
            setAlarmCenters(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    function handleFilters() {
        setFilters({
            ...filters,
            textFilter,
            page: 0
        });

        navigation.setParams({
            textFilter,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='alarm-centers' lateralMenu='crm' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={{ flex: 1, rowGap: 15 }}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 300 }}>
                            <MyAppText style={styles.filterText}>{translate('searchAlarmCenter')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : '#888888'
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('cameraTitleIdOrSerialNumber')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ flexGrow: 1 }}></View>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => {
                                navigation.navigate('AlarmCenterDetails');
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('registerAlarmCenter')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('title'),
                                    selector: row => row.title,
                                    grow: 3,
                                    wrap: true,
                                },
                                {
                                    name: translate('installationCompany'),
                                    selector: row => row.installationCompany?.name || '',
                                },
                                {
                                    name: translate('internetType'),
                                    selector: row => row.internetType == '5g' ? '5G' : row.internetType == '4g' ? '4G' : row.internetType == 'fiber' ? translate('fiber') : translate('radio'),
                                },
                                {
                                    name: translate('serialNo'),
                                    selector: row => row.serialNo,
                                },
                                {
                                    name: translate('registeredAt'),
                                    selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
                                    wrap: true
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <TouchableOpacity onPress={() => navigation.navigate('AlarmCenterDetails', { id: row.id })}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                        </TouchableOpacity>
                                }
                            ]}
                            data={alarmCenters}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        headerContent: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 10,
            flexWrap: 'wrap',
            rowGap: 10,
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 40,
            paddingHorizontal: 30,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 10
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#888',
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            paddingHorizontal: 8,
            height: 40
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
    });
}
