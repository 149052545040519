import React from 'react';
import LateralMenu from "../LateralMenu";
import { faIdBadge, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import logoEvent from '../../../assets/logo_events.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'events';

interface EventLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function EventLateralMenu({ navigation, selectedMenu }: EventLateralMenuProps) {
    return <LateralMenu
        topImage={logoEvent}
        items={[{
            icon: faIdBadge,
            text: translate('events'),
            action: () => navigation.navigate('Events', { screen: 'Events' }),
            selected: selectedMenu == 'events'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
