import React, { useContext, useRef } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';
import { dispatchService } from '../services/central-api/dispatch';

interface FileUploadProps {
    occurrenceId: number;
    callback: (occurrenceReport: OccurrenceReport) => void;
}

export default function FileUploader({ occurrenceId, callback }: FileUploadProps) {
    const { theme } = useContext(ThemeContext);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const styles = getStyles(theme);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <input
                type="file"
                style={{ display: 'none' }}
                onChange={async (e) => {
                    try {
                        if (!e.target.files) {
                            return;
                        }

                        const file = e.target.files[0];
                        const occurrenceReport = await dispatchService.createOccurrenceAttachment(occurrenceId, file);
                        callback(occurrenceReport);
                    } catch (err) {
                        console.error(err);
                    }
                }}
                ref={(input) => (fileInputRef.current = input)}
            />
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={handleUploadClick} style={styles.button}>
                    <FontAwesomeIcon icon={faUpload} color={getThemedColor(theme, '#FFFFFF')} fontSize={14} />
                    <MyAppText style={styles.text}>{translate('attach')}</MyAppText>
                </TouchableOpacity>
            </View>
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        buttonContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        text: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            marginLeft: 10
        },
        button: {
            borderRadius: 4,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
    });
}
