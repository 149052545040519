import { UnistylesRegistry } from 'react-native-unistyles';

export const breakpoints = {
    xs: 0,
    md: 768,
    lg: 1224,
} as const;

export const lightTheme = {
    colors: {
        backgroundColor: '#FFFFFF',
        borderColor: '#E6E6E6',
        buttonBackground: '#000028',
        buttonHover: '#1d1f57',
        disabledButtonBackground: '#CCCCCC',
        color: '#FFFFFF',
        disabledText: '#888888',
        loadingColor: '#000000',
        fieldColor: '#000000',
        iconColor: '#58595B'
    },
} as const;

export const darkTheme = {
    colors: {
        backgroundColor: '#181A1B',
        borderColor: '#373C3E',
        buttonBackground: '#FFFFFF',
        buttonHover: '#e5e5e5',
        disabledButtonBackground: '#3F4447',
        color: '#181A1B',
        disabledText: '#888888',
        loadingColor: '#FFFFFF',
        fieldColor: '#FFFFFF',
        iconColor: '#D3CFC9'
    },
} as const;

type AppThemes = {
    light: typeof lightTheme,
    dark: typeof darkTheme
};

// if you defined breakpoints
type AppBreakpoints = typeof breakpoints;

// override library types
declare module 'react-native-unistyles' {
    export interface UnistylesBreakpoints extends AppBreakpoints { }

    export interface UnistylesThemes extends AppThemes { }
}

UnistylesRegistry
    .addBreakpoints(breakpoints)
    .addThemes({
        light: lightTheme,
        dark: darkTheme,
    });
