import React, { useCallback, useContext, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import { translate } from '../../services/translate';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyAppText from '../../components/MyAppText';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useFocusEffect } from '@react-navigation/native';
import DeleteModal from '../../components/DeleteModal';
import { centralAPI } from '../../services/central-api';

export default function Sessions({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedSession, setSelectedSession] = useState<Session>();

    useFocusEffect(useCallback(() => {
        getSessions();

    }, [navigation]));

    async function getSessions() {
        try {
            const response = await centralAPI.getOwnUserSessions();
            setSessions(response);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteSession(sessionId: number) {
        try {
            await centralAPI.deleteOwnUserSession(sessionId);
            await getSessions();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='sessions' lateralMenu='user' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.header}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyAppText style={styles.title}>{translate('sessionHistory')}</MyAppText>
                            <View style={{ rowGap: 7 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flexBasis: '65%' }}>
                                        <MyAppText style={styles.headerCell}>{translate('deviceBrowser')}</MyAppText>
                                    </View>
                                    <View style={{ flexBasis: '15%' }}>
                                        <MyAppText style={[styles.headerCell, { alignSelf: 'center' }]}>{translate('situation')}</MyAppText>
                                    </View>
                                    <View style={{ flexBasis: '15%' }}>
                                        <MyAppText style={styles.headerCell}>{translate('mostRecentActivity')}</MyAppText>
                                    </View>
                                    <View style={{ flexBasis: '5%' }}>
                                        <MyAppText style={styles.headerCell}>{translate('action')}</MyAppText>
                                    </View>
                                </View>
                                <View style={styles.tableLine}></View>
                            </View>
                            <ScrollView>
                                <View style={{ gap: 10 }}>
                                    {sessions.map((session, sessionIndex) => (
                                        <View key={sessionIndex} style={{ rowGap: 10 }}>
                                            <View style={styles.row}>
                                                <View style={{ flexBasis: '65%' }}>
                                                    <MyAppText style={styles.cell}>{session.deviceOs}/{session.deviceBrowser}</MyAppText>
                                                </View>
                                                <View style={{ flexBasis: '15%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                                                    {session.current ?
                                                        <View style={[styles.marker, { backgroundColor: '#00BA39' }]}>
                                                            <MyAppText style={styles.markerText}>{translate('currentSession')}</MyAppText>
                                                        </View> : <View style={styles.marker}>
                                                            <MyAppText style={styles.markerText}>{translate(session.status)}</MyAppText>
                                                        </View>
                                                    }
                                                </View>
                                                <View style={{ flexBasis: '15%' }}>
                                                    <MyAppText style={styles.cell}>{moment(session.lastIteration).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                                                </View>
                                                {!session.current ?
                                                    <TouchableOpacity onPress={() => {
                                                        setSelectedSession(session);
                                                        setDeleteModalVisible(true);
                                                    }} style={{ flexBasis: '5%', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                        <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                    </TouchableOpacity> : null
                                                }
                                            </View>
                                            <View style={styles.tableLine}></View>
                                        </View>
                                    ))
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
                {/* Delete Modal */}
                <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    onSubmit={async () => {
                        if (selectedSession) {
                            await deleteSession(selectedSession.id);
                        }
                    }}
                />
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            columnGap: 10,
            minHeight: 65,
            justifyContent: 'flex-end',
        },
        title: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold',
            fontFamily: 'Open Sans',
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
            rowGap: 15
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 8,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            justifyContent: 'center',
            alignItems: 'center'
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        }
    });
}
