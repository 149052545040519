import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal, ActivityIndicator, ScrollView, Image } from 'react-native';
import MyAppText from '../../MyAppText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';
import { dispatchService } from '../../../services/central-api/dispatch';
import personDefaultPicture from '../../../../assets/person-default-picture.png';
import moment from 'moment';

interface OccurrenceCaptureHistoryModalParameters {
    occurrenceId: number;
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
}

export default function OccurrenceCaptureHistoryModal({ occurrenceId, isModalVisible, setModalVisible }: OccurrenceCaptureHistoryModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();
    const [isLoading, setIsLoading] = useState(true);
    const [occurrences, setOccurrences] = useState<Occurrence[]>([]);

    async function getCaptureHistory() {
        try {
            const occurrences = await dispatchService.getCaptureHistoric(occurrenceId);
            setOccurrences(occurrences);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!isModalVisible) {
            setIsLoading(true);
            return;
        }


        getCaptureHistory();
    }, [isModalVisible]);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                        <View style={{ alignItems: 'flex-end' }}>
                            <TouchableOpacity style={{ width: 20, height: 20 }} onPress={() => setModalVisible(false)}>
                                <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={getThemedColor(theme, '#58595B')} />
                            </TouchableOpacity>
                        </View>
                        {isLoading ?
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', rowGap: 10 }}>
                                <ActivityIndicator size='large' color={getThemedColor(theme, '#000000')} />
                                <MyAppText style={styles.videoNotReadyText}>{translate('loading')}...</MyAppText>
                            </View>
                            :
                            <ScrollView contentContainerStyle={{
                                rowGap: 15
                            }}>
                                {occurrences.map((occurrence) => (
                                    <TouchableOpacity
                                        key={occurrence.id}
                                        style={{
                                            alignSelf: 'flex-start',
                                        }}
                                        onPress={() => {
                                            window.open('/dispatch/occurrences/detail/' + occurrence.id);
                                        }}
                                    >
                                        {occurrence.triggerType == 'face_detected' ?
                                            <View style={{
                                                flexDirection: 'row',
                                                gap: 15,
                                            }}>
                                                <Image source={{ uri: occurrence.resume.imageUrl ?? personDefaultPicture }} style={styles.image} />
                                                <View style={{ gap: 5, paddingTop: 15 }}>
                                                    <View style={styles.visualizedRow}>
                                                        <MyAppText style={styles.cardBoldText}>{translate('occurrenceId')}:</MyAppText>
                                                        <MyAppText style={styles.cardText}>{occurrence.id}</MyAppText>
                                                    </View>
                                                    <MyAppText style={styles.cardBoldText}>{translate('visualizedOn')}</MyAppText>
                                                    <View style={styles.visualizedRow}>
                                                        <FontAwesomeIcon icon={faLocationDot} style={{ color: getThemedColor(theme, '#58595B') }} />
                                                        <MyAppText style={styles.cardText}>{occurrence.externalReference?.camera.title}</MyAppText>
                                                    </View>
                                                    <View style={styles.visualizedRow}>
                                                        <FontAwesomeIcon icon={faCalendarCheck} style={{ color: getThemedColor(theme, '#58595B') }} />
                                                        <MyAppText style={styles.cardText}>{moment(occurrence.externalReference?.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                                                    </View>
                                                </View>
                                            </View>
                                            : null
                                        }

                                    </TouchableOpacity>
                                ))}
                            </ScrollView>

                        }
                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        videoNotReadyText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 560,
            padding: 30,
        },
        image: {
            height: 180,
            width: 180,
            objectFit: 'contain',
            borderRadius: 8,
        },
        cardText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        cardBoldText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
    });
}
