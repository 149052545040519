import { BaseService, Unauthorized, UnexpectedError } from "./base-service";

class PolygonService extends BaseService {

    async getPolygonsType(): Promise<{ type: string; }[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/map-polygon-types`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPolygonsByType(type: string): Promise<MapPolygon[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/map-polygon/${type}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }


}

export const polygonService = new PolygonService();
