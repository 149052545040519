import React, { useContext, useState } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import MyDataTable from '../../MyDataTable';
import DeleteModal from '../../DeleteModal';
import InvolvedObjectModal from './InvolvedObjectModal';

interface InvolvedObjectsParams {
    involvedObjects: InvolvedObject[];
    setInvolvedObjects: (value: InvolvedObject[]) => void;
}

export default function InvolvedObjects({ involvedObjects, setInvolvedObjects }: InvolvedObjectsParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [selectedInvolvedObject, setSelectedInvolvedObject] = useState<InvolvedObject>(getBlankObject());
    const [objectModalOpen, setObjectModalOpen] = useState<boolean>(false);
    const [deleteObjectModalVisible, setDeleteObjectModalVisible] = useState(false);

    function getBlankObject(): InvolvedObject {
        return {
            amount: 0,
            objectCategoryId: 0,
            objectSubCategoryId: 0,
            description: ''
        };
    }

    async function createUpdateInvolvedObjects() {
        if (!selectedInvolvedObject.id) {
            selectedInvolvedObject.id = -Math.random();
            involvedObjects.push(selectedInvolvedObject);
        } else {
            const index = involvedObjects.findIndex(object => object.id === selectedInvolvedObject.id);
            involvedObjects[index] = { ...involvedObjects[index], ...selectedInvolvedObject };
        }

        setInvolvedObjects(involvedObjects);
        setSelectedInvolvedObject(getBlankObject());
    }

    return (
        <>
            <View style={{ flex: 1, rowGap: 20 }}>
                <View style={{ alignItems: 'flex-end' }}>
                    <TouchableOpacity onPress={() => {
                        setObjectModalOpen(true);
                    }} style={styles.attachButton}>
                        <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                        <MyAppText style={styles.attachButtonText}>{translate('attach')}</MyAppText>
                    </TouchableOpacity>
                </View>
                <MyDataTable
                    columns={[
                        {
                            name: translate('category'),
                            selector: row => row.objectCategory?.name || '',
                            wrap: true,
                        },
                        {
                            name: translate('subCategory'),
                            selector: row => row.objectSubCategory?.name || '',
                            wrap: true,
                        },
                        {
                            name: translate('amount'),
                            selector: row => row.amount || '',
                            wrap: true,
                        },
                        {
                            name: translate('action'),
                            button: true,
                            cell: row =>
                                <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedInvolvedObject(row);
                                        setObjectModalOpen(true);
                                    }}>
                                        <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        setSelectedInvolvedObject(row);
                                        setDeleteObjectModalVisible(true);
                                    }}>
                                        <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                    </TouchableOpacity>
                                </View>
                        }
                    ]}
                    data={involvedObjects}
                />
            </View>
            <DeleteModal
                setModalVisible={setDeleteObjectModalVisible}
                isModalVisible={deleteObjectModalVisible}
                itemName={translate('object')}
                onSubmit={() => {
                    const index = involvedObjects.findIndex(object => object.id === selectedInvolvedObject.id);
                    if (index >= 0) {
                        involvedObjects.splice(index, 1);
                        setInvolvedObjects(involvedObjects);
                        setSelectedInvolvedObject(getBlankObject());
                    }
                }}
                onCancel={() => {
                    setSelectedInvolvedObject(getBlankObject());
                }}
            />
            <InvolvedObjectModal
                involvedObject={selectedInvolvedObject}
                setInvolvedObject={setSelectedInvolvedObject}
                isModalVisible={objectModalOpen}
                setModalVisible={(value) => {
                    if (!value) {
                        setSelectedInvolvedObject(getBlankObject());
                    }
                    setObjectModalOpen(value);
                }}
                onSubmit={createUpdateInvolvedObjects}
            />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        attachButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        attachButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        }
    });
}
