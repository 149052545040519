import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../../components/MyAppText';
import MyDropDownPicker from '../../../components/MyDropDownPicker';
import { cameraService } from '../../../services/central-api/cameras';

interface Props {
    value?: number;
    onChange: (value: string) => void;
}

function UserDropdown({ value, onChange }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [persons, setPersons] = useState<{ label: string, value: string; }[]>([]);

    const [selectedPersonFilter, setSelectedPersonFilter] = useState<string>(value?.toString() ?? '');
    async function getPeople() {
        try {
            const people = await cameraService.getUsers();
            setPersons(people.map((person) => {
                return {
                    value: String(person.id),
                    label: person.warName ?? person.name
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        getPeople();
    }, []);

    useEffect(() => {
        onChange(selectedPersonFilter);
    }, [selectedPersonFilter]);

    return (
        <View style={{ gap: 5, minWidth: 250, flexGrow: 1 }}>
            <MyAppText>{translate('requester')}</MyAppText>
            <MyDropDownPicker
                items={persons}
                value={selectedPersonFilter}
                setValue={setSelectedPersonFilter}
                setOpen={setIsOpen}
                open={isOpen}
                multiple={false}
                mode="BADGE"
                searchable={true}
                borderColor='#888'
                loading={isLoading}
            />
        </View>
    );
}

export default UserDropdown;
