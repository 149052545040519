import React, { useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import MyAppText from '../MyAppText';
import ThemeContext from '../../context/Theme';
import { translate } from '../../services/translate';
import getThemedColor from '../../services/get-themed-color';
import { faCalendarCheck, faLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export function MpPlateDetectedOccurrence({ occurrence, navigation, imageUrl }: { occurrence: Occurrence, navigation: Navigation; imageUrl: string; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    function InfoInRow({ title, desc }: { title: string; desc: string | number; }) {
        return <View style={{ flexDirection: 'row', gap: 5, alignItems: 'flex-end' }}>
            <MyAppText style={styles.cardBoldText}>
                {title}:
            </MyAppText>
            <MyAppText style={styles.cardText}>
                {desc}
            </MyAppText>
        </View>;
    }

    if (!occurrence.resume) {
        return <></>;
    }
    const resume = occurrence.resume as MpPlateDetectedResumeData;

    return (
        <View style={{ gap: 20 }}>
            <View style={{ gap: 20 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <MyAppText style={styles.alertText}>
                        {translate('mpPlateDetectedAlert')}:
                    </MyAppText>
                    <TouchableOpacity onPress={() => navigation.navigate("GcmAgent", { screen: 'AgentOccurrences' })}>
                        <FontAwesomeIcon color={getThemedColor(theme, "#222222")} fontSize={30} icon={faTimes} />
                    </TouchableOpacity>
                </View>
                <MyAppText style={styles.alertName}>{resume.plate}</MyAppText>
                <View style={styles.imageBox}>
                    <Image source={{ uri: imageUrl }} style={styles.image} />
                </View>
            </View>
            <View style={styles.card}>
                <View style={{ gap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>
                        {translate('vehicleData')}
                    </MyAppText>
                    <View>
                        <InfoInRow title={translate('brand')} desc={resume.modelBrand}/>
                        <InfoInRow title={translate('color')} desc={resume.color}/>
                        <InfoInRow title={translate('origin')} desc={`${resume.plateMunicipality}/${resume.plateFederativeUnit}`}/>
                        <InfoInRow title={translate('manufacturingYear')} desc={resume.manufacturingYear}/>
                        <InfoInRow title={translate('modelYear')} desc={resume.modelYear}/>
                        <InfoInRow title={translate('origination')} desc={resume.origin}/>
                        <InfoInRow title={translate('chassis')} desc={resume.chassis}/>
                    </View>
                </View>
                <View style={{ gap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('visualizedOn')}</MyAppText>
                    <View style={styles.visualizedRow}>
                        <FontAwesomeIcon icon={faLocationDot} style={{ color: getThemedColor(theme, '#58595B') }} />
                        <MyAppText style={styles.cardText}>{resume.location}</MyAppText>
                    </View>
                    <View style={styles.visualizedRow}>
                        <FontAwesomeIcon icon={faCalendarCheck} style={{ color: getThemedColor(theme, '#58595B') }} />
                        <MyAppText style={styles.cardText}>{moment(resume.time).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                    </View>
                </View>
                <View style={{ gap: 5 }}>
                    <MyAppText style={styles.cardBoldText}>{translate('systemData')}</MyAppText>
                    <View>
                        <InfoInRow title={translate('occurrenceSystem')} desc={resume.occurrenceSystem}/>
                        <InfoInRow title={translate('natureOfOccurrence')} desc={resume.natureOfOccurrence}/>
                        <InfoInRow title={translate('policeReportNumber')} desc={resume.policeReportNumber}/>
                        <InfoInRow title={translate('policeReportYear')} desc={resume.policeReportYear}/>
                        <InfoInRow title={translate('policeReportLocation')} desc={`${resume.policeReportMunicipality}/${resume.policeReportFederativeUnit}`}/>
                        <InfoInRow title={translate('declarantName')} desc={resume.declarantName}/>
                        {resume.contactAreaCode && resume.contactPhone ?
                            <InfoInRow title={translate('phone')} desc={`(${resume.contactAreaCode}) ${resume.contactPhone} ${resume.contactBranch}`}/> : null}
                        <InfoInRow title={translate('occurrenceDate')} desc={moment(new Date(resume.occurrenceDate)).format('DD/MM/YYYY HH:mm:ss')}/>
                        <InfoInRow title={translate('policeUnit')} desc={resume.policeUnit}/>
                    </View>
                </View>
                <View>
                    { occurrence.id ? <InfoInRow title={translate('policeUnit')} desc={resume.policeUnit}/> : null}
                    <View style={styles.visualizedRow}>
                        <MyAppText style={styles.cardBoldText}>{translate('priority')}:</MyAppText>
                        <MyAppText style={[styles.cardText, occurrence.priority === 'maximum' ? { color: '#FF0027' } : undefined]}>{occurrence.priority ? translate(occurrence.priority) : ''}</MyAppText>
                    </View>
                    <View>
                        <View>
                            <MyAppText style={styles.cardBoldText}>{translate('narrative')}:</MyAppText>
                            <MyAppText style={styles.cardText}>{occurrence.narrative}</MyAppText>
                        </View>
                    </View>
                </View>
                <View>
                    <MyAppText style={styles.cardBoldText}>{translate('description')}:</MyAppText>
                    <MyAppText style={styles.cardText}>{resume.occurrenceHistory}</MyAppText>
                </View>
            </View>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        alertText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            fontWeight: 'bold',
        },
        alertName: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 18,
            fontWeight: 'bold',
        },
        card: {
            gap: 20,
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#EEEEEE'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            padding: 14,
            display: 'flex',
            alignItems: 'flex-start',
        },
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        cardText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        cardBoldText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        plateInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 24
        },
        imageBox: {
            height: 220,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        image: {
            height: 220,
            width: 340,
            objectFit: 'contain',
            borderRadius: 8,
        },
    });
}
