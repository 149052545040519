import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Hoverable } from 'react-native-web-hover';
import MyAppText from './MyAppText';

interface Props {
    icon: IconDefinition,
    text: string;
    onPress: VoidFunction;
}

function CustomButton({ icon, text, onPress }: Props) {
    const { styles } = useStyles(stylesheet);

    return (
        <TouchableOpacity onPress={onPress}>
            <Hoverable>
                {({ hovered }) => (
                    <View style={[styles.buttonContainer(hovered), { gap: 10 }]}>
                        <FontAwesomeIcon style={styles.buttonIcon} fontSize={16} icon={icon} color='white' />
                        <MyAppText style={styles.buttonText}>{text}</MyAppText>
                    </View>
                )}
            </Hoverable>
        </TouchableOpacity>
    );
}

const stylesheet = createStyleSheet(theme => ({
    buttonContainer: (hovered: boolean) => ({
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        paddingHorizontal: 30,
        width: '100%',
        columnGap: 10,
        height: 40,
        backgroundColor: hovered ? theme.colors.buttonHover : theme.colors.buttonBackground,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        justifyContent: 'center'
    }),

    buttonText: {
        color: theme.colors.color,
        fontSize: 16,
    },

    buttonIcon: {
        color: theme.colors.color,
    }
}));

export default CustomButton;
