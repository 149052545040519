import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import { accessService } from '../../services/central-api/access';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyPhoneInput from '../../components/MyPhoneInput';
import { WindowInformation } from '../../services/window-information';

export default function InternetCompanyModal({ onClose, internetCompanyId }: { onClose: () => void, internetCompanyId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState<string>('');
    const [responsible, setResponsible] = useState<string>('');

    async function getInternetCompany(id: number) {
        const internetCompany = await accessService.getInternetCompany(id);
        setName(internetCompany.name);
        setPhone(internetCompany.phone);
        setResponsible(internetCompany.responsible);
    }

    async function loadPageInfo() {
        try {
            if (internetCompanyId) {
                await getInternetCompany(internetCompanyId);
            }
        } catch (err) {
            console.error(err);
        }
    }

    function isFieldInvalid(value: string) {
        return value == '';
    }

    function isPhoneInvalid(value: string) {
        return value == '' || value.length < 10;
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    function isFormValid() {
        return !isFieldInvalid(name) && !isFieldInvalid(responsible) && !isPhoneInvalid(phone);
    }

    async function formSubmit() {
        try {
            if (internetCompanyId) {
                await accessService.updateInternetCompany(internetCompanyId, { name, phone, responsible });
            } else {
                await accessService.createInternetCompany({ name, phone, responsible });
            }
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? {width: '95%'} : {width: '20%'}]}>
            <View style={{rowGap: 10}}>
                <FormInput
                    label={translate('name')}
                    placeholder={translate('name')}
                    value={name}
                    onChangeText={setName}
                    invalid={isFieldInvalid}
                />
                <MyPhoneInput
                    label={translate('phone')}
                    placeholder={translate('phone')}
                    value={phone}
                    onChangeText={setPhone}
                    invalid={isPhoneInvalid}
                />
                <FormInput
                    label={translate('responsible')}
                    placeholder={translate('responsible')}
                    value={responsible}
                    onChangeText={setResponsible}
                    invalid={isFieldInvalid}
                />
            </View>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 200,
            rowGap: 30
        },
    });
}
