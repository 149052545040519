import React, { useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { dispatchService } from '../../services/central-api/dispatch';
import { WindowInformation } from '../../services/window-information';

export default function VehiclesModal({ onClose, vehicle }: { onClose: () => void, vehicle?: Vehicle; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [type, setType] = useState<VehicleType>(vehicle?.type || 'SUV');
    const [status, setStatus] = useState<VehicleStatus>(vehicle?.status || 'inactive');
    const [prefix, setPrefix] = useState(vehicle?.prefix || '');
    const [typePickerOpen, setTypePickerOpen] = useState(false);
    const [statusPickerOpen, setStatusPickerOpen] = useState(false);


    function isVehicleTypeInvalid(value: string) {
        return value == '';
    }

    function isVehicleStatusInvalid(value: string) {
        return value == '';
    }

    function isFormValid(): boolean {
        return !isVehicleTypeInvalid(type) &&
            !isVehicleStatusInvalid(status);
    }

    async function formSubmit() {
        try {
            if (!status || !type) {
                return;
            }

            if (vehicle?.id) {
                await dispatchService.updateVehicle(vehicle.id, {
                    type,
                    status,
                    prefix,
                });

                onClose();
                return;
            }
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '45%' }]}>
            <View style={{ rowGap: 25 }}>
                <View style={[styles.row]}>
                    <View style={{ flex: 1 }}>
                        <FormInput
                            label={translate('vehicle_metadata_registry')}
                            placeholder={translate('vehicle_metadata_registry')}
                            value={prefix}
                            onChangeText={setPrefix}
                            invalid={() => false}
                        />
                    </View>
                </View>
                <View style={[styles.row, { zIndex: 300 }]}>
                    <View style={{ flex: 1 }}>
                        <FormInput
                            label={translate('actingBody')}
                            placeholder={translate('actingBody')}
                            value={vehicle?.actingBody?.name || ''}
                            onChangeText={() => { }}
                            invalid={() => false}
                            disabled={true}
                        />
                    </View>
                    <View style={{ rowGap: 5, flex: 1 }}>
                        <MyAppText style={styles.label}>{translate('status')}</MyAppText>
                        <MyDropDownPicker
                            height={40}
                            open={statusPickerOpen}
                            setOpen={setStatusPickerOpen}
                            onOpen={() => {
                                setTypePickerOpen(false);
                            }}
                            value={status}
                            setValue={setStatus}
                            items={['active', 'inactive', 'based', 'maintenance'].map((item: string) => {
                                return {
                                    label: translate(item), value: item
                                };
                            })}
                            searchable={true}
                        />
                    </View>
                </View>
                <View style={[styles.row, { zIndex: 200 }]}>
                    <View style={{ rowGap: 5, flex: 1 }}>
                        <MyAppText style={styles.label}>{translate('vehicle_metadata_type')}</MyAppText>
                        <MyDropDownPicker
                            open={typePickerOpen}
                            setOpen={setTypePickerOpen}
                            onOpen={() => {
                                setStatusPickerOpen(false);
                            }}
                            value={type}
                            setValue={setType}
                            items={['SUV', 'sedan', 'pickup', 'truck', 'motorcycle'].map((item: string) => {
                                return {
                                    label: translate(item), value: item
                                };
                            })}
                            searchable={true}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <FormInput
                            label={translate('vehicle_metadata_plate')}
                            placeholder={translate('vehicle_metadata_plate')}
                            value={vehicle?.plate || ''}
                            onChangeText={() => { }}
                            invalid={() => false}
                            disabled={true}
                        />
                    </View>
                </View>
            </View>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            minWidth: 300,
            minHeight: 450,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            justifyContent: 'space-between'
        },
        rightContent: {
            flex: 1,
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        row: {
            flexDirection: 'row',
            columnGap: 30
        }
    });
}
