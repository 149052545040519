import React from 'react';
import LateralMenu from "../LateralMenu";
import { faClipboardList, faIdBadge, faRightFromBracket, faSearch, faTag, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import logoFacial from '../../../assets/logo_facial.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'liveEvents' | 'liveAlerts' | 'search' | 'register' | 'markers';

interface FacialLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function FacialLateralMenu({ navigation, selectedMenu }: FacialLateralMenuProps) {
    return <LateralMenu
        topImage={logoFacial}
        items={[{
            icon: faClipboardList,
            text: translate('liveEvents'),
            action: () => navigation.navigate('Facial', { screen: 'LiveEvents' }),
            selected: selectedMenu == 'liveEvents'
        }, {
            icon: faTriangleExclamation,
            text: translate('liveAlerts'),
            action: () => navigation.navigate('Facial', { screen: 'LiveAlerts' }),
            selected: selectedMenu == 'liveAlerts'
        }, {
            icon: faSearch,
            text: translate('search'),
            action: () => navigation.navigate('Facial', { screen: 'Search' }),
            selected: selectedMenu == 'search'
        }, {
            icon: faIdBadge,
            text: translate('register'),
            action: () => navigation.navigate('Facial', { screen: 'PeopleList' }),
            selected: selectedMenu == 'register'
        }, {
            icon: faTag,
            text: translate('markers'),
            action: () => navigation.navigate('Facial', { screen: 'Markers' }),
            selected: selectedMenu == 'markers'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
