import PersonRelationModal from './PersonRelationModal';
import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../components/MyAppText';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import Toast from 'react-native-toast-message';
import DeleteModal from '../../components/DeleteModal';
import { dispatchService } from '../../services/central-api/dispatch';
import { Pagination } from '../../components/Pagination';
import { ClientError } from '../../services/central-api/base-service';
import MyDataTable from '../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';

const limit = 25;

export default function PersonRelation({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'PersonRelations'>>();

    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedPersonRelation, setSelectedPersonRelation] = useState<PersonRelation>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [personRelations, setPersonRelations] = useState<PersonRelation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(Math.max(route.params.page - 1, 0));
    const [pageCount, setPageCount] = useState(1);

    async function getPersonRelations(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getPersonRelations({ page, limit });
            setPersonRelations(response.rows);
            setPageCount(Math.ceil(response.count / limit));

        } catch (err) {
            //TODO: add proper error treatments
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPersonRelations();
    }, [page]);

    function handleEditClick(personRelation: PersonRelation) {
        setSelectedPersonRelation(personRelation);
        setModalVisible(true);
        getPersonRelations();
    }

    function showDeleteConfirmationDialog(personRelation: PersonRelation) {
        setSelectedPersonRelation(personRelation);
        setDeleteModalVisible(true);
    }

    async function confirmDelete(personRelation: PersonRelation) {
        try {
            if (personRelation?.id) {
                await dispatchService.deletePersonRelation(personRelation.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });

                setDeleteModalVisible(false);
                getPersonRelations();

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleFormClose() {
        setSelectedPersonRelation(undefined);
        setModalVisible(false);
        getPersonRelations();
    }

    function handleFormNew() {
        setSelectedPersonRelation(undefined);
        setModalVisible(true);
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='person_relation' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={handleFormNew} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    wrap: true,
                                    grow: 3
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => handleEditClick(row)}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => showDeleteConfirmationDialog(row)}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={personRelations}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <PersonRelationModal
                                    onClose={handleFormClose}
                                    personRelation={selectedPersonRelation}
                                />
                            </View>
                        </View>
                    </Modal>

                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedPersonRelation?.name) || ''}
                        onSubmit={async () => {
                            if (selectedPersonRelation) {
                                await confirmDelete(selectedPersonRelation);
                            }
                        }}
                    />

                </View>
            </DefaultPageLayout >
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        cellButtons: {
            flex: 1,
            justifyContent: 'space-around',
            flexDirection: 'row',
        },
        deleteConfirmation: {
            alignItems: 'center',
            justifyContent: 'center',
            width: '33%',
            height: '33%',
            marginLeft: '33%',
            marginTop: '10%'
        },
        deleteConfirmationText: {
            fontSize: 20,
            marginBottom: 20
        },
        deleteButtonsView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        deleteButtons: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 10,
            color: getThemedColor(theme, '#FFFFFF')
        },
        red: {
            backgroundColor: 'red',
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
}
