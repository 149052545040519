import React, { useState, useEffect, useRef, useContext } from 'react';
import { View, StyleSheet, ScrollView, Image as ReactImage, TouchableOpacity, Modal, DimensionValue, Animated, TextInput, ActivityIndicator } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowLeft, faChevronDown, faFileVideo, faRoute, faCheck } from '@fortawesome/free-solid-svg-icons';
import { centralAPI } from '../../services/central-api';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import Map from '../../components/Map';
import Toast from 'react-native-toast-message';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { gcmAgentService } from '../../services/central-api/gcmAgent';
import { SceneChangedOccurrence } from '../../components/gcm-agent/SceneChangedOccurrence';
import { IrregularVehicleOccurrence } from '../../components/gcm-agent/IrregularVehicleOccurrence';
import { FaceDetectedOccurrence } from '../../components/gcm-agent/FaceDetectedOccurrence';
import { DefaultOccurrence } from '../../components/gcm-agent/DefaultOccurrence';
import { PlateDetectedOccurrence } from '../../components/gcm-agent/PlateDetectedOccurrence';
import { AreaInvasionOccurrence } from '../../components/gcm-agent/AreaInvasionOccurrence';
import { ClientError } from '../../services/central-api/base-service';
import { MpPlateDetectedOccurrence } from '../../components/gcm-agent/MpPlateDetectedOccurrence';
import { MpFaceDetectedOccurrence } from '../../components/gcm-agent/MpFaceDetectedOccurrence';
import InvolvedVehicles from '../../components/dispatch/occurrenceForm/InvolvedVehicles';
import InvolvedPeople from '../../components/dispatch/occurrenceForm/InvolvedPeople';
import InvolvedObjects from '../../components/dispatch/occurrenceForm/InvolvedObjects';
import { AlarmCenterOccurrence } from '../../components/gcm-agent/AlarmCenterOccurrence';
import FinishReasonModal from '../../components/gcm-agent/FinishReason';

interface OccurrenceDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            occurrenceId: string;
        };
    };
}

const animationTime = 300;

export default function AgentOccurrenceDetails({ navigation, route, authenticatedUser }: OccurrenceDetailParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [occurrence, setOccurrence] = useState<Occurrence>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const opacityAnim = useRef(new Animated.Value(0)).current;
    const scrollViewRef = useRef<ScrollView>(null);
    const [shadowOpen, setShadowOpen] = useState<boolean>(false);
    const [step, setStep] = useState<'occurrenceDetail' | 'occurrenceReport' | 'occurrenceInvolved'>('occurrenceDetail');
    const [historicNarrative, setHistoricNarrative] = useState({ dirty: false, value: '' });
    const [pin, setPin] = useState<LatLng>();
    const [files, setFiles] = useState<{ file: File, objectURI: string; key: string; }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldFinishOccurrence, setShouldFinishOccurrence] = useState<boolean>(false);
    const [involvedVehicles, setInvolvedVehicles] = useState<InvolvedVehicle[]>([]);
    const [involvedPeople, setInvolvedPeople] = useState<InvolvedPerson[]>([]);
    const [involvedObjects, setInvolvedObjects] = useState<InvolvedObject[]>([]);
    const [finishStarter, setFinishStarter] = useState<boolean>(false);

    async function getOccurrence(occurrenceId: string) {
        try {
            const occurrence = await centralAPI.gcmAgentOccurrence(Number(occurrenceId));
            setOccurrence(occurrence);
            setInvolvedVehicles(occurrence.involvedVehicles);
            setInvolvedPeople(occurrence.involvedPeople);
            setInvolvedObjects(occurrence.involvedObjects);
        } catch (err) {
            console.error(err);
        }
    }

    const [finishCategory, setFinishCategory] = useState<number>(0);
    const [finishSubCategory, setFinishSubCategory] = useState<number>(0);

    useEffect(() => {
        if (!occurrence) {
            return;
        }
        return setPin({ lat: occurrence.latitude, lng: occurrence.longitude });
    }, [occurrence]);

    useEffect(() => {
        getOccurrence(route.params.occurrenceId);
    }, [route]);

    useEffect(() => {
        if (modalOpen) {
            setShadowOpen(true);
            Animated.timing(opacityAnim, {
                toValue: 0.2,
                duration: animationTime,
                useNativeDriver: true,
            }).start();
        } else {
            Animated.timing(opacityAnim, {
                toValue: 0,
                duration: animationTime,
                useNativeDriver: true,
            }).start();
            setTimeout(() => setShadowOpen(false), animationTime);
        }
    }, [modalOpen]);

    async function finishEvent() {
        try {
            if (!occurrence?.id) {
                return;
            }

            setIsLoading(true);

            for (const file of files) {
                try {
                    await gcmAgentService.createOccurrenceAttachment({ occurrenceId: occurrence.id, file: file.file });
                } catch (err) {
                    console.error(err);
                }
            }

            if (shouldFinishOccurrence) {
                await gcmAgentService.finishOccurrence({
                    occurrenceId: occurrence.id,
                    report: historicNarrative.value,
                    category: finishCategory,
                    subCategory: finishSubCategory
                });
            } else {
                await gcmAgentService.addOccurrenceReport({ occurrenceId: occurrence.id, report: historicNarrative.value });
            }

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });

            clearOccurrenceReportForm();

            if (shouldFinishOccurrence) {
                navigation.navigate('GcmAgent', { screen: 'AgentOccurrences' });
            } else {
                setStep('occurrenceDetail');
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    function clearOccurrenceReportForm() {
        setHistoricNarrative({ dirty: false, value: '' });
        setFiles([]);
    }

    async function updateOccurrenceInvolved() {
        if (!occurrence?.id) {
            return;
        }

        try {
            await gcmAgentService.updateOccurrence({
                id: occurrence.id,
                involvedVehicles: involvedVehicles.map(involvedVehicle => involvedVehicle.id && involvedVehicle.id < 0 ? { ...involvedVehicle, id: undefined } : involvedVehicle),
                involvedPeople: involvedPeople.map(involvedPerson => involvedPerson.id && involvedPerson.id < 0 ? { ...involvedPerson, id: undefined } : involvedPerson),
                involvedObjects: involvedObjects.map(involvedObject => involvedObject.id && involvedObject.id < 0 ? { ...involvedObject, id: undefined } : involvedObject)
            });

            await getOccurrence(String(occurrence.id));

            setStep('occurrenceDetail');
        } catch (err) {
            console.error(err);

            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    if (!occurrence) {
        return (
            <DefaultPageContainer>
                <DefaultPageLayout navigation={navigation} selectedMenu='occurrences' lateralMenu='gcmAgent' authenticatedUser={authenticatedUser}>
                    <></>
                </DefaultPageLayout>
            </DefaultPageContainer>
        );
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='occurrences' lateralMenu='gcmAgent' authenticatedUser={authenticatedUser}>
                {!isLoading ?
                    <ScrollView ref={scrollViewRef}>
                        {step == 'occurrenceDetail' ?
                            <View style={styles.container}>
                                {occurrence.triggerType == 'plate_detected' && occurrence.externalReference ?
                                    <PlateDetectedOccurrence occurrence={occurrence} event={occurrence.externalReference} navigation={navigation} imageUrl={occurrence.resume.imageUrl} /> : null}
                                {occurrence.triggerType == 'mp_plate_detected' ?
                                    <MpPlateDetectedOccurrence occurrence={occurrence} navigation={navigation} imageUrl={occurrence.resume.imageUrl} /> : null}
                                {occurrence.triggerType == 'irregular_vehicle' && occurrence.externalReference ?
                                    <IrregularVehicleOccurrence occurrence={occurrence} event={occurrence.externalReference} navigation={navigation} imageUrl={occurrence.resume.imageUrl} /> : null}
                                {occurrence.triggerType == 'face_detected' && occurrence.externalReference ?
                                    <FaceDetectedOccurrence occurrence={occurrence} event={occurrence.externalReference} imageUrl={occurrence.resume.imageUrl} navigation={navigation} /> : null}
                                {occurrence.triggerType == 'mp_face_detected' ?
                                    <MpFaceDetectedOccurrence occurrence={occurrence} navigation={navigation} imageUrl={occurrence.resume.imageUrl} /> : null}
                                {occurrence.triggerType == 'defocus' || occurrence.triggerType == 'scenechangedetection' ?
                                    <SceneChangedOccurrence occurrence={occurrence} navigation={navigation} /> : null}
                                {occurrence.triggerType == 'area_invasion' ?
                                    <AreaInvasionOccurrence occurrence={occurrence} navigation={navigation} /> : null}
                                {occurrence.triggerType == 'alarm_center' ?
                                    <AlarmCenterOccurrence occurrence={occurrence} navigation={navigation} /> : null}
                                {occurrence.triggerType == null ?
                                    <DefaultOccurrence occurrence={occurrence} navigation={navigation} /> : null}


                                {pin ?
                                    <View style={{ rowGap: 5 }}>
                                        <TouchableOpacity onPress={() => {
                                            if (pin) {
                                                const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${pin.lat},${pin.lng}&travelmode=driving&dir_action=navigate`;
                                                window.open(directionsUrl, '_blank');
                                            }
                                        }} style={styles.routeButton}>
                                            <FontAwesomeIcon icon={faRoute} style={{ color: getThemedColor(theme, '#FFFFFF') }} />
                                            <MyAppText style={styles.routeText}>{translate('route')}</MyAppText>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => setModalOpen(!modalOpen)}>
                                            <View style={{ width: '100%', height: 450 }}>
                                                <Map
                                                    pinnedMarker={pin}
                                                    draggable={false}
                                                    mapControls={false}
                                                />
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                    : null
                                }
                                <TouchableOpacity style={styles.involvedButton} onPress={() => {
                                    setStep('occurrenceInvolved');
                                    scrollViewRef.current?.scrollTo({ y: 0, animated: false });
                                }}>
                                    <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 16 }}>{translate('involved').toUpperCase()}</MyAppText>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.addReport} onPress={() => {
                                    setShouldFinishOccurrence(false);
                                    setStep('occurrenceReport');
                                }}>
                                    <MyAppText style={{ color: '#008E2C', fontSize: 16 }}>{translate('addReport').toUpperCase()} </MyAppText>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.finishButton} onPress={() => {
                                    setShouldFinishOccurrence(true);
                                    setStep('occurrenceReport');
                                }}>
                                    <MyAppText style={{ color: '#FF0027', fontSize: 16 }}>{translate('finish').toUpperCase()} </MyAppText>
                                </TouchableOpacity>
                            </View >
                            : null}
                        {step == 'occurrenceReport' && !isLoading ?
                            <View style={styles.container} >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 45 }}>
                                    <TouchableOpacity onPress={() => {
                                        clearOccurrenceReportForm();
                                        setStep('occurrenceDetail');
                                    }}>
                                        <FontAwesomeIcon fontSize={30} icon={faArrowLeft} color={getThemedColor(theme, '#222222')} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                    <MyAppText style={styles.alertText}>{translate('addReport')}</MyAppText>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 30 }}>
                                    <TextInput
                                        placeholder={translate('addReport')}
                                        multiline={true}
                                        style={[
                                            styles.input,
                                            styles.multiline,
                                            historicNarrative.value == '' && historicNarrative.dirty ? styles.invalid : null,
                                            { color: historicNarrative.value ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#CCCCCC') }
                                        ]}
                                        value={historicNarrative.value}
                                        onBlur={() => {
                                            setHistoricNarrative({ dirty: true, value: historicNarrative.value });
                                        }}
                                        onChangeText={(value) => {
                                            setHistoricNarrative({ dirty: true, value });
                                        }}
                                    />
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                    <MyAppText style={styles.alertText}>{translate('addPicturesAndVideos')}</MyAppText>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                    {files.map((file) =>

                                        file.file.type != 'video/mp4' ?
                                            <ReactImage
                                                key={file.key}
                                                style={[styles.blockSize, {
                                                    overflow: 'hidden',
                                                }]}
                                                source={{
                                                    uri: file.objectURI
                                                }}
                                            />
                                            : <View key={Math.random()} style={[styles.blockSize, {
                                                backgroundColor: getThemedColor(theme, '#000028'),
                                                justifyContent: 'center'
                                            }]}>
                                                <FontAwesomeIcon fontSize={30} icon={faFileVideo} color={getThemedColor(theme, '#FFFFFF')} style={{ justifySelf: 'center', alignSelf: 'center' }} />
                                            </View>

                                    )}
                                    {files.length < 3 ?
                                        <TouchableOpacity style={[styles.blockSize, styles.blockStyle]} >
                                            <label style={{ width: '100%', height: '100%', display: 'grid' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    type='file'
                                                    onChange={(e) => {
                                                        if (!e.target.files) {
                                                            return;
                                                        }
                                                        const file = e.target.files[0];

                                                        if (!['image/jpeg', 'image/png', 'video/mp4'].includes(file.type)) {
                                                            Toast.show({
                                                                type: 'sentinelxError',
                                                                text1: translate('gcmAgentFormatNotValid'),
                                                            });
                                                            return;
                                                        }
                                                        setFiles(current => [...current, { file, objectURI: URL.createObjectURL(file), key: String(Math.random()) }]);
                                                    }}
                                                    accept='image/jpeg,image/png,video/mp4'
                                                />
                                                <FontAwesomeIcon fontSize={30} icon={faAdd} color={'#181A1B'} style={{ justifySelf: 'center', alignSelf: 'center' }} />

                                            </label>
                                        </TouchableOpacity>
                                        : null
                                    }
                                    {(() => {
                                        for (let i = 0; i < 2 - files.length; i++) {
                                            return <View style={styles.blockSize} />;
                                        }
                                    })()}
                                </View>
                                <TouchableOpacity
                                    style={[
                                        styles.concludeButton, historicNarrative.value == '' ? styles.disabledConcludeButton : undefined
                                    ]}
                                    onPress={() => {
                                        !occurrence.OccurrenceUnits?.find(unit => unit.OccurrenceUnit.occurrenceId == occurrence.id)?.OccurrenceUnit.isStarter ?
                                            finishEvent() :
                                            setFinishStarter(true);
                                    }}
                                    disabled={historicNarrative.value == ''}
                                >
                                    <MyAppText style={{
                                        color: historicNarrative.value == '' ? getThemedColor(theme, '#888888') : getThemedColor(theme, '#FFFFFF'),
                                        fontSize: 16
                                    }}>
                                        {translate(shouldFinishOccurrence ? 'conclude' : 'addReport').toUpperCase()}
                                    </MyAppText>
                                </TouchableOpacity>
                            </View>
                            : null}
                        {step == 'occurrenceInvolved' && !isLoading ?
                            <View style={styles.container} >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <TouchableOpacity onPress={() => {
                                        setStep('occurrenceDetail');
                                    }}>
                                        <FontAwesomeIcon fontSize={30} icon={faArrowLeft} color={getThemedColor(theme, '#222222')} />
                                    </TouchableOpacity>
                                    <MyAppText style={{ color: getThemedColor(theme, '#58595B'), fontSize: 16 }}>{translate('involved').toUpperCase()} </MyAppText>
                                    <TouchableOpacity onPress={() => updateOccurrenceInvolved()}>
                                        <FontAwesomeIcon fontSize={30} icon={faCheck} color={getThemedColor(theme, '#222222')} />
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <MyAppText style={styles.alertText}>{translate('involvedVehicles')}</MyAppText>
                                    </View>
                                    <InvolvedVehicles
                                        involvedVehicles={involvedVehicles}
                                        setInvolvedVehicles={setInvolvedVehicles} />
                                </View>
                                <View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <MyAppText style={styles.alertText}>{translate('involvedPeople')}</MyAppText>
                                    </View>
                                    <InvolvedPeople
                                        involvedPeople={involvedPeople}
                                        setInvolvedPeople={setInvolvedPeople} />
                                </View>
                                <View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <MyAppText style={styles.alertText}>{translate('involvedObjects')}</MyAppText>
                                    </View>
                                    <InvolvedObjects
                                        involvedObjects={involvedObjects}
                                        setInvolvedObjects={setInvolvedObjects} />
                                </View>
                            </View>
                            : null}
                    </ScrollView >
                    :
                    <View style={styles.loadingView}>
                        <MyAppText style={styles.loadingText}>
                            {translate('processing')}...
                        </MyAppText>
                        <ActivityIndicator
                            animating={true}
                            style={styles.loading}
                            size='large'
                            color='#0071bc'
                        >
                        </ActivityIndicator>
                    </View>
                }

                {shadowOpen ?
                    <Animated.View style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#313131',
                        position: 'absolute',
                        opacity: opacityAnim
                    }} />
                    : null}
                <Modal
                    animationType='slide'
                    transparent={true}
                    visible={modalOpen}
                    onRequestClose={() => {
                        setModalOpen(!modalOpen);
                    }}
                >
                    <View style={styles.mapModalView}>
                        <TouchableOpacity style={styles.modalBackgroundView} onPress={() => setModalOpen(!modalOpen)} />
                        <View style={styles.topModalView}>
                            <TouchableOpacity onPress={() => setModalOpen(!modalOpen)}>
                                <FontAwesomeIcon icon={faChevronDown} style={{
                                    marginLeft: 10,
                                    marginRight: 5,
                                    color: getThemedColor(theme, '#222222')
                                }} />
                            </TouchableOpacity>
                            <MyAppText style={styles.topModalText}>{translate('location')}</MyAppText>
                        </View>
                        <View style={{ width: '100%', height: '80%' }}>
                            <Map
                                pinnedMarker={pin}
                            />
                        </View>
                    </View>
                </Modal>
                <FinishReasonModal
                    setModalVisible={setFinishStarter}
                    isModalVisible={finishStarter}
                    finishCategory={finishCategory}
                    finishSubCategory={finishSubCategory}
                    setFinishCategory={setFinishCategory}
                    setFinishSubCategory={setFinishSubCategory}
                    onSubmit={async () => {
                        await finishEvent();
                    }}
                />
            </DefaultPageLayout >
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        loading: {
            marginHorizontal: 'auto',
            transform: [{ scaleX: 2 }, { scaleY: 2 }],
            width: '20%',
            height: '20%',
        },
        loadingText: {
            color: getThemedColor(theme, '#000000'),
            textAlign: 'center',
            fontSize: 16
        },
        loadingView: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center'
        },
        blockSize: {
            width: 97,
            height: 97,
            borderRadius: 8
        },
        blockStyle: {
            backgroundColor: '#C6C6CE',
        },
        mapModalView: {
            flex: 1,
            justifyContent: 'flex-end',
        },
        modalBackgroundView: {
            height: `calc(20% - 50px)` as DimensionValue,
            width: '100%',
            borderColor: getThemedColor(theme, '#EEEEEE'),
            borderTopWidth: 1
        },
        topModalView: {
            height: 50,
            flexDirection: 'row',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            alignItems: 'center'
        },
        topModalText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 16
        },
        container: {
            flex: 1,
            padding: 16,
            gap: 30
        },
        alertText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            fontWeight: 'bold',
        },
        alertName: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 8
        },
        image: {
            height: 314,
            width: 311,
            objectFit: 'contain',
            borderRadius: 8,
        },
        imageBox: {
            height: 314,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 30
        },
        card: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#EEEEEE'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            padding: 14,
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 30
        },
        cardBoldText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 14,
            fontWeight: 'bold',
        },
        cardText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        finishButton: {
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#FF0027',
            padding: 14,
            display: 'flex',
            alignItems: 'center',
        },
        addReport: {
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#008E2C',
            padding: 14,
            display: 'flex',
            alignItems: 'center',
        },
        involvedButton: {
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#58595B'),
            padding: 14,
            display: 'flex',
            alignItems: 'center',
        },
        concludeButton: {
            borderRadius: 4,
            backgroundColor: getThemedColor(theme, '#000028'),
            padding: 14,
            display: 'flex',
            alignItems: 'center',
        },
        marker: {
            borderRadius: 8,
            height: 16,
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 8,
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        input: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            width: '100%',
            height: 40,
            padding: 10,
            borderRadius: 4,
        },
        multiline: {
            minHeight: 50,
            height: 200,
            zIndex: -11
        },
        invalid: {
            borderColor: 'red',
        },
        disabledConcludeButton: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        routeButton: {
            borderRadius: 4,
            backgroundColor: getThemedColor(theme, '#000028'),
            padding: 14,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            columnGap: 10,
        },
        routeText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 560,
            padding: 30,
        },
    });
}
