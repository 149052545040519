import React, { useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { dispatchService, TeamNameCategory } from '../../services/central-api/dispatch';
import { WindowInformation } from '../../services/window-information';
import { ClientError } from '../../services/central-api/base-service';
import Toast from 'react-native-toast-message';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';

export default function TeamNameModal({ onClose, teamName }: { onClose: () => void, teamName?: TeamName; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState(teamName?.name || '');
    const [category, setCategory] = useState<TeamNameCategory | ''>(teamName?.category || '');
    const [categoryPickerOpen, setCategoryPickerOpen] = useState<boolean>(false);

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name);
    }

    async function formSubmit() {
        try {
            if (teamName?.id) {
                await dispatchService.updateTeamName({
                    id: teamName.id,
                    name,
                    category: category == '' ? null : category
                });
                onClose();
                return;
            }

            await dispatchService.createTeamName({
                name,
                category: category == '' ? null : category
            });
            onClose();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '20%' }]}>
            <FormInput
                label={translate('name')}
                placeholder={translate('name')}
                value={name}
                onChangeText={setName}
                invalid={isNameInvalid}
            />
            <View style={{ rowGap: 5, zIndex: 2 }}>
                <MyAppText style={styles.label}>{translate('category')}</MyAppText>
                <MyDropDownPicker
                    open={categoryPickerOpen}
                    setOpen={setCategoryPickerOpen}
                    value={category}
                    setValue={setCategory}
                    items={['', ...Object.values(TeamNameCategory)].map((item: string) => {
                        return {
                            label: item ? translate(item) : '', value: item
                        };
                    })}
                    searchable={false}
                    zIndex={2}
                />
            </View>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        formContainer: {
            minWidth: 300,
            minHeight: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 10,
            justifyContent: 'space-between'
        },
    });
}
