import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Modal } from 'react-native';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { WindowInformation } from '../../services/window-information';
import FormActions from '../formActions';
import { dispatchService } from '../../services/central-api/dispatch';
import MyRadio from '../MyRadio';
import { translate } from '../../services/translate';
import MyAppText from '../MyAppText';
import { ScrollView } from 'react-native-web-hover';

interface FinishReasonModalParameters {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
    finishCategory: number;
    finishSubCategory: number;
    setFinishCategory: (value: number) => void;
    setFinishSubCategory: (value: number) => void;
}

export default function FinishReasonModal({
    isModalVisible,
    setModalVisible,
    onSubmit,
    finishCategory,
    finishSubCategory,
    setFinishCategory,
    setFinishSubCategory,
}: FinishReasonModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [finishCategories, setFinishCategories] = useState<FinishCategory[]>([]);

    async function loadPageInfo() {
        const finishCategories = await dispatchService.getSimplifiedFinishReasons();
        setFinishCategories(finishCategories);
    }

    useEffect(() => {
        loadPageInfo();
    }, [isModalVisible]);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '40%' }]}>
                        <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('finishReason')}</MyAppText>
                        <ScrollView contentContainerStyle={styles.content}>
                            <MyRadio
                                style={styles.customRadio}
                                selected={String(finishCategory)}
                                setSelected={(value) => {
                                    setFinishCategory(value);
                                    setFinishSubCategory(0);
                                }}
                                options={finishCategories.map((finishCategory) => {
                                    return {
                                        label: finishCategory.name,
                                        value: finishCategory.id
                                    };
                                })}
                            />
                            {finishCategory && (finishCategories.map(fg => fg.id)).includes(finishCategory) ?
                                <View style={{ rowGap: 10, flex: 1 }}>
                                    <MyAppText style={{ fontWeight: 'bold', fontSize: 16 }}>{translate('subCategory')}</MyAppText>
                                    <MyRadio
                                        style={styles.customRadio}
                                        selected={String(finishSubCategory)}
                                        setSelected={(value) => {
                                            setFinishSubCategory(value);
                                        }}
                                        options={(finishCategories.find(finishCat => finishCat.id == finishCategory) as FinishCategory).finishSubCategories
                                            .filter(finishSubCategory => finishSubCategory.name != 'Outros')
                                            .map((finishSubCategory) => {
                                                return {
                                                    label: finishSubCategory.name,
                                                    value: finishSubCategory.id
                                                };
                                            })}
                                    />
                                </View> : null}
                        </ScrollView>
                        <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                            <FormActions
                                onSubmit={() => {
                                    onSubmit();
                                    setModalVisible(false);
                                }}
                                onClose={() => {
                                    setModalVisible(false);
                                    setFinishCategory(0);
                                    setFinishSubCategory(0);
                                }}
                                disabled={!(finishCategory && finishSubCategory)} />
                        </View>
                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 560,
            padding: 30,
        },
        row: {
            flexDirection: 'column',
            columnGap: 30,
        },
        content: {
            gap: 30,
            flex: 1
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        customRadio: {
            flexDirection: 'column',
            rowGap: 10,
        },
    });
}
