import React, { useContext, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from "../../services/translate";
import FormInput from "../../components/formInput";
import FormActions from "../../components/formActions";
import Toast from 'react-native-toast-message';
import { crmService } from '../../services/central-api/crm';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { ClientError } from '../../services/central-api/base-service';


export default function DisableEnableModal({ onClose, cameraData, setDisabledAt }: { onClose: (success: boolean) => void, cameraData?: { id?: string, disabledAt: Date | null; }; setDisabledAt: (value: Date | null) => void; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const [description, setDescription] = useState('');
    function isDescriptionInvalid(value: string) {
        return value == "";
    }

    function isFormValid() {
        return !isDescriptionInvalid(description);
    }

    async function formSubmit() {
        try {
            if (cameraData?.id) {
                const camera = await crmService.disableEnableCamera(cameraData.id, cameraData.disabledAt !== null ? 'enable' : 'disable', description);
                setDisabledAt(camera.disabledAt);
            }
            onClose(true);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={styles.formContainer}>
            <View>
                <FormInput
                    label={translate('description')}
                    placeholder={translate('description')}
                    value={description}
                    onChangeText={setDescription}
                    invalid={isDescriptionInvalid}
                />
            </View>
            <FormActions
                onSubmit={formSubmit}
                onClose={() => onClose(false)}
                disabled={!isFormValid()} />
        </View>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        formContainer: {
            width: '25%',
            minHeight: 150,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            rowGap: 25,
            justifyContent: 'space-between'
        },
    });
}
