import { PaginatedResource } from "../../typings/Paginated";
import { Log } from "./audit-logs";
import { ClientError, ExtendableError, InvalidValue, Unauthorized, UnexpectedError, BaseService, NotFound } from "./base-service";

export interface getVehiclesPaginatedParameters {
    page: number;
    limit: number;
    textFilter?: string;
    status?: string;
}


export enum TeamNameCategory {
    LIGHT_VEHICLE = 'light_vehicle',
    MOTORCYCLE = 'motorcycle',
    ON_FOOT = 'on_foot',
    BICYCLE = 'bicycle',
    BOAT = 'boat',
    HEAVY_VEHICLE = 'heavy_vehicle',
    TRACTOR_TRUCK = 'tractor_truck',
    VAN = 'van',
    JET_SKI = 'jet_ski',
    BUS = 'bus'
}

interface createUnitParameters {
    unitNameId: number;
    actingBodyUnitId: number;
    description?: string;
    usersToSet: number[];
    vehiclesToSet: number[];
    equipmentsToSet: number[];
    estimatedStart: string;
    estimatedFinish: string;
    /**
     * Modifications on agents assignments. The key stands for the AgentId and the value for the AssignmentId
     */
    modifiedAgentAssignments: { [agentId: number]: number; };
}

interface updateUnitParameters {
    unitId: number;
    unitNameId: number;
    description?: string;
    usersToSet: number[];
    vehiclesToSet: number[];
    equipmentsToSet: number[];
    estimatedStart: string;
    estimatedFinish: string;
    /**
     * Modifications on agents assignments. The key stands for the AgentId and the value for the AssignmentId
     */
    modifiedAgentAssignments: { [agentId: number]: number; };
}

export interface getUnitsPaginatedParameters {
    page: number;
    limit: number;
    agentFilter?: string;
    teamFilter?: string;
    beginIn?: string;
    endIn?: string;
    status: string;
}

export interface getObjectCategoriesPaginatedParameters {
    page: number;
    limit: number;
    textFilter?: string;
}

export interface GetFinishCategoriesPaginatedParameters {
    page: number;
    limit: number;
    textFilter?: string;
}

export interface getVehiclesPositionsParameters {
    textFilter?: string;
    status?: string;
}

export class AlreadyWithThisUser extends ExtendableError {
    constructor() {
        super(`You selected the same user that already is the responsible for this occurrence.`);
    }
}

export class OccurrenceIsNotPending extends ExtendableError {
    constructor() {
        super(`This occurrence is not pending.`);
    }
}

interface getReportsPaginatedParameters {
    page: number;
    limit: number;
    textFilter?: string;
}

interface createReportParameters {
    name: string;
    isPdf: boolean;
    startDate: string;
    endDate: string;
    tags?: number[];
}

interface createSkillParameters {
    name: string;
    description: string;
    /**
     * List of user IDs to be added
     */
    usersToAdd: number[];
}

interface updateSkillParameters extends createSkillParameters {
    /**
     * List of user IDs to be removed
     */
    usersToRm: number[];
}

interface upsertAssignmentParameters {
    name: string;
    description: string;
}

export interface getOccurrenceTypesPaginatedParameters {
    page: number;
    textFilter?: string;
    limit: number;
    isActive: boolean;
}

export interface GetOccurrencesParameters {
    page: number;
    limit: number;
    triggerType?: TriggerType | 'manual';
    priority?: OccurrencePriorities;
    situation?: OccurrenceSituation;
    occurrenceTypeId?: number;
    responsibleUserId?: number;
    textFilter?: string;
}

export class DispatchService extends BaseService {
    async getOccurrences(params: GetOccurrencesParameters): Promise<{ count: number; rows: Occurrence[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences${this.encodeQueryParams({ ...params })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicles(params: getVehiclesPaginatedParameters): Promise<{ count: number; rows: Vehicle[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicles${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
            status: params.status
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedVehicles(params: { status?: VehicleStatus; actingBodyId?: number; }): Promise<SimplifiedVehicle[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicles-simplified${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceTypes(params: getOccurrenceTypesPaginatedParameters): Promise<{ count: number; rows: OccurrenceType[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_types${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
            isActive: params.isActive
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceVideoUrl(id: number): Promise<VideoUrl> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/video-url`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgentPositions(): Promise<AgentPosition[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/agent/positions`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getEquipments(params: {
        page: number;
        limit: number;
    }): Promise<{ count: number; rows: Equipment[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipments${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPersonRelations(params: {
        page: number;
        limit: number;
    }): Promise<{ count: number; rows: PersonRelation[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relations${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deletePersonRelation(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relation/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getVehicleRelations(params: {
        page: number;
        limit: number;
    }): Promise<{ count: number; rows: VehicleRelation[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relations${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteVehicleRelation(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relation/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getSimplifiedEquipments(actingBodyId?: number): Promise<SimplifiedEquipment[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipments-simplified${this.encodeQueryParams({ actingBodyId })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrencePositions(): Promise<OccurrencePosition[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/positions`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getActingBodies(): Promise<ActingBodySimplified[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/acting-bodies`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getUnitNames(): Promise<UnitName[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit-names`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    /**
     *
     * @param id
     * @throws {ClientError} CantFinishConcludedSituation
     */
    async finishOccurrence(id: number, category: number, subCategory: number, other?: string) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/finish`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ category, subCategory, other })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @param id
     * @throws {ClientError} CantReopenOpenedSituation
     * @throws {ClientError} MoreThan72Hours
     */
    async reopenOccurrence(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/reopen`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createReport(params: createReportParameters) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/report`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getReports(params: getReportsPaginatedParameters): Promise<{ count: number; rows: Report[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReport(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/report/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAgents({ actingBodyId }: {
        actingBodyId?: number;
    }): Promise<SimplifiedUserForUnit[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/agents${this.encodeQueryParams({ actingBodyId })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSkills(params: { page?: number; limit?: number; textFilter?: string; }): Promise<{ count: number; rows: Skill[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skills${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedSkills(): Promise<SimplifiedSkill[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skills-simplified`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getEventOperators({ actingBodyId }: {
        actingBodyId?: string;
    }): Promise<{ id: number; name: string; warName: string; }[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/event-operators${this.encodeQueryParams({ actingBodyId })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSkill(id: number): Promise<Skill> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @param occurrenceId
     * @param params
     * @returns
     * @throws {AlreadyWithThisUser}
     * @throws {ClientError} CantEditConcludedSituation
     */
    async transferOccurrence(occurrenceId: number, params: {
        eventOperatorId: number;
    }): Promise<OccurrenceReport> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/transfer`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });
        if (res.status == 403) {
            throw new ClientError('ForbiddenToTransferOccurrence');
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "AlreadyWithThisUser") {
                throw new AlreadyWithThisUser();
            }
            throw new ClientError(resJson.code);
        }
        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createSkill({ name, description, usersToAdd }: createSkillParameters): Promise<Skill> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description, usersToAdd }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateSkill(id: number, { name, description, usersToAdd, usersToRm }: updateSkillParameters): Promise<Skill> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description, usersToAdd, usersToRm }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteSkill(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/skill/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getAssignments(params: { page?: number; limit?: number; textFilter?: string; }): Promise<{ count: number; rows: Assignment[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignments${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAssignment(id: number): Promise<Assignment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createAssignment({ name, description }: upsertAssignmentParameters): Promise<Assignment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateAssignment(id: number, { name, description }: upsertAssignmentParameters): Promise<Assignment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ name, description }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 400) {
            const resJson = await res.json();
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteAssignment(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/assignment/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrence(occurrence: {
        phone: string;
        requester: string;
        latitude: number;
        longitude: number;
        priority: OccurrencePriorities;
        narrative: string;
        occurrenceTypeId: number;
        tags?: number[];
        involvedVehicles?: InvolvedVehicle[];
        involvedPeople?: InvolvedPerson[];
        involvedObjects?: InvolvedObject[];
    }): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(occurrence)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @param occurrence
     * @returns
     * @throws {ClientError} CantEditConcludedSituation
     */
    async updateOccurrence(occurrence: {
        id: number;
        phone?: string;
        requester?: string;
        latitude?: number;
        longitude?: number;
        priority?: OccurrencePriorities;
        narrative?: string;
        occurrenceTypeId?: number;
        unitsToSet?: { id: number; isStarter: boolean; }[];
        tags?: number[];
        involvedVehicles?: InvolvedVehicle[];
        involvedPeople?: InvolvedPerson[];
        involvedObjects?: InvolvedObject[];
    }): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrence.id}`, {
            method: "PATCH",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(occurrence)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicle(id: number): Promise<Vehicle> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateVehicle(id: number, vehicle: {
        status: VehicleStatus;
        type: VehicleType;
        prefix?: string;
    }): Promise<Vehicle> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(vehicle)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrence(id: number): Promise<Occurrence> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @param id
     * @throws {OccurrenceIsNotPending}
     */
    async setOccurrenceInService(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${id}/in_service`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "OccurrenceIsNotPending") {
                throw new OccurrenceIsNotPending();
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getStatistics(): Promise<Statistic> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/statistics${this.encodeQueryParams({
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehiclePositions(params?: getVehiclesPositionsParameters): Promise<CarMapPosition[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle/positions${this.encodeQueryParams({
            textFilter: params?.textFilter ?? "",
            status: params?.status ?? ""
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrencePriorities(): Promise<string[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_priorities`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceType(id: number): Promise<OccurrenceType> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteOccurrenceType(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createOccurrenceType(occurrenceType: OccurrenceType): Promise<OccurrenceType> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(occurrenceType)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateOccurrenceType(occurrenceType: OccurrenceType): Promise<OccurrenceType> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence_type/${occurrenceType.id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(occurrenceType)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicleTypes(): Promise<string[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle_type`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getVehicleStatus(): Promise<VehicleStatus[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle_status`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getEquipment(id: number): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteEquipment(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createVehicleRelation(vehicleRelation: VehicleRelation): Promise<VehicleRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relation`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(vehicleRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateVehicleRelation(vehicleRelation: VehicleRelation): Promise<VehicleRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/vehicle-relation/${vehicleRelation.id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(vehicleRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }


    async createPersonRelation(personRelation: PersonRelation): Promise<PersonRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relation`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(personRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updatePersonRelation(personRelation: PersonRelation): Promise<PersonRelation> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/person-relation/${personRelation.id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(personRelation)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createEquipment(equipment: Equipment): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(equipment)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateEquipment(equipment: Equipment): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/equipment/${equipment.id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(equipment)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createOccurrenceAttachment(occurrenceId: number, file: File): Promise<OccurrenceReport> {
        const token = await this.getToken();
        const formData = new FormData();
        formData.append("file", file, file.name);

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/attachment`, {
            method: "POST",
            headers: this.getHeaders(token),
            body: formData,
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createOccurrenceReport(occurrenceId: number, content: string): Promise<OccurrenceReport> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/report`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ "content": content }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCaptureHistoric(occurrenceId: number): Promise<Occurrence[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrence/${occurrenceId}/capture-historic`, {
            method: "GET",
            headers: this.getHeaders(token),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPaginatedUnits(params: getUnitsPaginatedParameters): Promise<{ count: number; rows: PaginatedUnit[]; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/units/paginated${this.encodeQueryParams({ ...params })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnits(params: { equipmentsIds: number[]; skillsIds: number[]; unitName?: string; dispatchedUnits: number[]; }): Promise<Unit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/units${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceDispatchedUnits(occurrenceId: number): Promise<Unit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/${occurrenceId}/dispatched-units`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedUnits(): Promise<SimplifiedUnit[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/units-simplified`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createUnit(params: createUnitParameters): Promise<Unit> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/unit`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateUnit(params: updateUnitParameters): Promise<Unit> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${params.unitId}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @returns
     * @throws {ClientError} CannotFinishUnitWithOccurrences
     */
    async finishUnit(id: number, reason?: string): Promise<void> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}/finished`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ reason })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    /**
     *
     * @returns
     * @throws {ClientError} CurrentUnitNotActive | CannotPauseUnitWithOccurrences
     */
    async pauseUnpauseUnit(id: number, action: 'pause' | 'unpause', pauseReasonId?: number) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}/pause`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ action, pauseReasonId })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        } else if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getPauseReasons(): Promise<PauseReason[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/pause-reasons`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnit(id: number): Promise<Unit> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getUnitHistoric(id: number): Promise<Log[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/unit/${id}/finished-historic`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getOccurrenceTags(): Promise<Tag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tags`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createOccurrenceTag(params: CreateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tag`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateOccurrenceTag(id: number, params: UpdateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tag/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteOccurrenceTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/occurrences/tag/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getReportTags(): Promise<Tag[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tags`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createReportTag(params: CreateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tag`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 201) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateReportTag(id: number, params: UpdateTag): Promise<Tag> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tag/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteReportTag(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/reports/tag/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getPaginatedObjectsCategories(params: getObjectCategoriesPaginatedParameters): Promise<{ count: number; rows: PaginatedObjectCategory[]; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/objects-categories${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedObjectsCategories(): Promise<ObjectCategory[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/objects-categories-simplified`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedFinishReasons(): Promise<FinishCategory[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-reasons-simplified`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedObjectSubCategories(id: number): Promise<{ id: number, name: string; }[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/object-sub-categories-simplified/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteObjectCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createObjectCategory(params: CreateObjectCategory): Promise<ObjectCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateObjectCategory(id: number, params: UpdateObjectCategory): Promise<ObjectCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getObjectCategory(id: number): Promise<ObjectCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-category/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteObjectSubCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/object-sub-category/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async getTeamNames(params: {
        page: number;
        limit: number;
        textFilter: string;
    }): Promise<{ count: number; rows: TeamName[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-names${this.encodeQueryParams(params)}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteTeamName(id: number) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-name/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async createTeamName(teamName: TeamName): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-name`, {
            method: 'POST',
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(teamName)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateTeamName(teamName: TeamName): Promise<Equipment> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/unit-name/${teamName.id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(teamName)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteFinishCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }

    async createFinishCategory(params: { name: string; finishSubCategories?: Partial<FinishSubCategory>[]; }): Promise<FinishCategory> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateFinishCategory(id: number, params: { name: string; finishSubCategories?: Partial<FinishSubCategory>[]; }): Promise<FinishCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category/${id}`, {
            method: "PUT",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFinishCategory(id: number): Promise<FinishCategory> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-category/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getPaginatedFinishCategories(params: GetFinishCategoriesPaginatedParameters): Promise<PaginatedResource<FinishCategory>> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-categories${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteFinishSubCategory(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/dispatch/finish-sub-category/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 204) {
            throw new UnexpectedError();
        }
    }
}

export const dispatchService = new DispatchService();
