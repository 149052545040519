import React from 'react';
import LateralMenu from "../LateralMenu";
import { faHomeLg, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import logoEvent from '../../../assets/logo_dashboard.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'dashboard';

interface DashboardLateralMenuProps extends LateralMenuProps {
    selectedMenu: SelectedMenu;
}

export default function DashboardLateralMenu({ navigation, selectedMenu }: DashboardLateralMenuProps) {
    return <LateralMenu
        topImage={logoEvent}
        items={[{
            icon: faHomeLg,
            text: translate('general_vision'),
            action: () => navigation.navigate('Dashboard', { screen: 'Dashboard' }),
            selected: selectedMenu == 'dashboard'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
