import React from 'react';
import { StyleSheet, TouchableOpacity, View, FlatList, TextInput } from 'react-native';
import { Slider } from '@miblanchard/react-native-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faFloppyDisk, faPlay, faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../services/translate';
import MyAppText from './MyAppText';
import { cameraService } from '../services/central-api/cameras';
import Toast from 'react-native-toast-message';
import { ClientError } from '../services/central-api/base-service';
import Gamepad from 'react-gamepad';

export const PtzControls = ({ camera }: { camera: Camera; }) => {
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [presets, setPresets] = React.useState<Preset[]>([]);
    const [speedValue, setSpeedValue] = React.useState(4);
    const [gamepadIndex, setGamepadIndex] = React.useState(0);

    const [alreadyLoaded, setAlreadyLoaded] = React.useState(false);
    function ptzEventKeyHandler(event: KeyboardEvent) {
        if (event.key === '+') {
            sendPtzEvent('zoomIn');
        }
        if (event.key === '-') {
            sendPtzEvent('zoomOut');
        }
        if (event.key === 'ArrowRight') {
            sendPtzEvent('right');
        }
        if (event.key === 'ArrowLeft') {
            sendPtzEvent('left');
        }
        if (event.key === 'ArrowUp') {
            sendPtzEvent('up');
        }
        if (event.key === 'ArrowDown') {
            sendPtzEvent('down');
        }
    }

    React.useEffect(() => {
        (async () => {
            try {
                const presets = await cameraService.getPresets(camera.id);
                setPresets(presets);
                setAlreadyLoaded(true);
            } catch (err) {
                console.error(err);
            }
        })();

        window.addEventListener('keydown', ptzEventKeyHandler);

        return () => {
            window.removeEventListener('keydown', ptzEventKeyHandler);
        };

    }, []);

    async function savePreset(preset: Preset) {
        try {
            await cameraService.savePreset({
                cameraId: camera.id,
                preset
            });
        } catch (err) {
            console.error(err);
        }
    }

    function renderItem({ item }: { item: Preset; }) {
        return (
            <View style={[styles.row, { paddingTop: 5, paddingBottom: 3 }]}>
                <TouchableOpacity style={{ width: '5%' }}
                    onPress={async () => {
                        try {
                            await cameraService.goToPreset({ cameraId: camera.id, presetId: item.id });
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faPlay} />
                </TouchableOpacity>
                <TextInput
                    style={{ width: '90%', marginLeft: 10, marginRight: 10, color: '#555555', fontSize: 15 }}
                    value={item.name}
                    onChangeText={(value) => {
                        item.name = value;
                        item.dirty = true;
                        forceUpdate();
                    }} />
                {
                    item.dirty ?
                        <TouchableOpacity style={{ width: '5%' }} onPress={() => {
                            item.dirty = false;
                            savePreset(item);
                        }}>
                            <FontAwesomeIcon icon={faFloppyDisk} />
                        </TouchableOpacity> : <View style={{ width: '5%' }}></View>}
            </View>
        );
    }

    async function sendPtzEvent(event: keyof PtzMovementList, speed = speedValue) {
        try {
            await cameraService.moveCamera({
                cameraId: camera.id,
                movement: event,
                speed: speed / 100
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxWarning',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <>
            <View style={styles.row}>
                <View>
                    <Gamepad
                        gamepadIndex={gamepadIndex}
                        onConnect={setGamepadIndex}
                        onAxisChange={(axisName, value, previousValue) => {
                            if (value == 0 || !alreadyLoaded) {
                                return;
                            }
                            const axisCheck = value - previousValue;
                            const movementAmount = 5;

                            if (axisName == 'LeftStickX') { //Horizontal
                                if (value > 0 && axisCheck > 0) {
                                    sendPtzEvent('right', movementAmount);
                                } else if (value < 0 && axisCheck < 0) {
                                    sendPtzEvent('left', movementAmount);
                                }
                            }

                            if (axisName == 'LeftStickY') { //Vertical
                                if (value > 0 && axisCheck > 0) {
                                    sendPtzEvent('up', movementAmount);
                                } else if (value < 0 && axisCheck < 0) {
                                    sendPtzEvent('down', movementAmount);
                                }
                            }

                            if (axisName == 'RightStickX') {//Zoom
                                if (value > 0 && axisCheck > 0) {
                                    sendPtzEvent('zoomIn', movementAmount);
                                } else if (value < 0 && axisCheck < 0) {
                                    sendPtzEvent('zoomOut', movementAmount);
                                }
                            }
                        }}
                        onB={async () => {
                            try {
                                await cameraService.goToPreset({ cameraId: camera.id, presetId: '1' });
                            } catch (err) {
                                console.error(err);
                            }
                        }}

                    >
                        <></>
                    </Gamepad>
                </View>
                <View>
                    <View style={styles.row}>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("leftUp");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 270 }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("up");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 315 }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("rightUp");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 0 }} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.row}>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("left");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 225 }} />
                        </TouchableOpacity>
                        <View style={styles.arrowButton}></View>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("right");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 45 }} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.row}>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("leftDown");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 180 }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("down");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 135 }} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.arrowButton} onPress={() => {
                            sendPtzEvent("rightDown");
                        }}>
                            <FontAwesomeIcon icon={faLocationArrow} transform={{ rotate: 90 }} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.zoomView}>
                    <TouchableOpacity style={styles.arrowButton} onPress={() => {
                        sendPtzEvent("zoomIn");
                    }}>
                        <FontAwesomeIcon icon={faSearchPlus} fontSize={20} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.arrowButton} onPress={() => {
                        sendPtzEvent("zoomOut");
                    }}>
                        <FontAwesomeIcon icon={faSearchMinus} fontSize={20} />
                    </TouchableOpacity>
                </View>
            </View >
            <View style={styles.rowSlider}>
                <Slider
                    animateTransitions
                    maximumTrackTintColor="#d3d3d3"
                    minimumValue={1}
                    maximumValue={50}
                    minimumTrackTintColor="#000000"
                    thumbTintColor="#000000"
                    value={speedValue}
                    step={1}
                    onValueChange={(value) => setSpeedValue(value[0])}
                />
            </View>
            <View style={styles.row}>
                <MyAppText style={[styles.sliderText, { textAlign: 'left' }]}>{translate('slow')}</MyAppText>
                <MyAppText style={[styles.sliderText, { textAlign: 'right' }]}>{translate('fast')}</MyAppText>
            </View>
            <MyAppText style={styles.presetsText}>{translate('presets')}</MyAppText>
            <View style={styles.list}>
                <FlatList data={presets} renderItem={renderItem} ItemSeparatorComponent={() => <View style={styles.itemSeparator} />} />
            </View>
        </>
    );
};

export default PtzControls;

const styles = StyleSheet.create({
    presetsText: {
        marginTop: 50,
        color: '#555555',
        fontSize: 20,
    },
    sliderText: {
        width: '50%',
        color: '#555555',
        fontWeight: 'bold',
        fontSize: 13,
        userSelect: 'none'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    rowSlider: {
        justifyContent: 'center',
        width: '100%'
    },
    arrowButton: {
        alignItems: 'center',
        width: 40,
        height: 40,
        borderWidth: 1,
        paddingTop: 10,
        margin: 3,
        fontSize: 20,
        backgroundColor: '#e7e7e7',
        borderRadius: 3,
        borderColor: '#a8a8a8'
    },
    zoomView: {
        marginLeft: 20,
        marginTop: 20
    },
    itemSeparator: {
        height: 1,
        width: "100%",
        marginTop: 5,
        marginBottom: 5,
        backgroundColor: "#a8a8a8",
    },
    list: {
        borderColor: '#a8a8a8',
        borderWidth: 1,
        borderRadius: 3,
        minHeight: 300,
        marginTop: 20,
        padding: 10
    }
});
