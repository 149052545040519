import React from 'react';
import LateralMenu from "../LateralMenu";
import { faSearch, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import logoEvent from '../../../assets/logo_smart_search_facial.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'smartSearchFacial';

interface SmartSearchFacialLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function SmartSearchFacialLateralMenu({ navigation, selectedMenu }: SmartSearchFacialLateralMenuProps) {
    return <LateralMenu
        topImage={logoEvent}
        items={[{
            icon: faSearch,
            text: translate('searchByPhoto'),
            action: () => navigation.navigate('SmartSearchFacial', { screen: 'SmartSearchFacial' }),
            selected: selectedMenu == 'smartSearchFacial'
        }]}
        bottomItems={[{
            icon: faRightFromBracket,
            text: translate('goBack'),
            style: { rotate: '180deg' },
            action: () => navigation.navigate('Menu')
        }]} />;
}
