import React, { useCallback, useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import { SelectableItem, SelectableList } from '../../components/SelectableList';
import { Tabs } from '../../components/Tabs';
import MyAppText from '../../components/MyAppText';
import { MyCheckbox } from '../../components/MyCheckbox';
import { accessService } from '../../services/central-api/access';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { WindowInformation } from '../../services/window-information';
import { cameraService } from '../../services/central-api/cameras';
import { centralAPI } from '../../services/central-api';

export default function GroupModal({ onClose, groupId }: { onClose: () => void, groupId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [ownUser, setOwnUser] = useState<UserData>();
    const [selectableActingBodies, setSelectableActingBodies] = useState<{ label: string, value: number; }[]>([]);
    const [actingBodyId, setActingBodyId] = useState<number>(0);
    const [isOpenDepartment, setIsOpenDepartment] = useState(false);
    const [selectableUnits, setSelectableUnits] = useState<{ label: string, value: number; }[]>([]);
    const [unitId, setUnitId] = useState<number>(0);
    const [isOpenUnit, setIsOpenUnit] = useState(false);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);
    const [groupUserIds, setGroupUserIds] = useState<number[]>([]);
    const [selectableCameras, setSelectableCameras] = useState<SelectableItem[]>([]);
    const [selectedCameras, setSelectedCameras] = useState<SelectableItem[]>([]);
    const [groupCameraIds, setGroupCameraIds] = useState<string[]>([]);
    const [, updateState] = useState({});
    const [selectableTagsFilter, setSelectableTagsFilter] = useState<{ label: string, value: number; }[]>([]);
    const [isTagPickerOpen, setIsTagPickerOpen] = useState(false);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [cameras, setCameras] = useState<AccessSimplifiedCameraData[]>([]);
    const [displayFilter, setDisplayFilter] = useState<string[]>([]);
    const [permissions, setPermissions] = useState<GroupPermissions>({
        camera_watch: true,
        camera_watch_timeline: false,
        camera_move_ptz: false,
        camera_watch_facial_detections: false,
        camera_watch_lpr_detections: false
    });

    const forceUpdate = useCallback(() => updateState({}), []);

    async function getGroup(id: number, cameras: AccessSimplifiedCameraData[]) {
        const group = await accessService.getGroup(id);
        setName(group.name);
        setDescription(group.description);
        setPermissions(group.groupPermissionObj);
        if (group.actingBodyUnit?.actingBodyId) {
            await loadUnits(group.actingBodyUnit.actingBodyId);

            setActingBodyId(group.actingBodyUnit.actingBodyId);
            setUnitId(group.actingBodyUnitId);

            const users = await loadUsers(group.actingBodyUnit.id || 0);
            const selectedUsers = group.Users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            });
            setSelectedUsers(selectedUsers);

            setSelectableUsers((users || [])
                .filter((user) => !selectedUsers.find((groupUser) => groupUser.id == user.id))
                .map((user) => {
                    return {
                        id: user.id || 0,
                        name: user.name
                    };
                })
            );

            setGroupUserIds(group.Users.map((user) => user.id || 0));
        }


        const selectedCameras = group.Cameras.map((camera) => {
            return {
                id: camera.id,
                name: camera.title
            };
        });
        setSelectedCameras(selectedCameras);
        setGroupCameraIds(group.Cameras.map((camera) => camera.id));
        setSelectableCameras(cameras
            .filter((camera) => !selectedCameras.find((groupCamera) => groupCamera.id == camera.id))
            .map((camera) => {
                return {
                    id: camera.id,
                    name: camera.title
                };
            })
        );
    }

    async function loadPageInfo() {
        try {
            const [cameras, tags, ownUser, actingBodies] = await Promise.all([
                accessService.getAllCameras({}),
                cameraService.getCameraTags(),
                centralAPI.getOwnUser({ includeRole: true }),
                accessService.getActingBodies({ page: 0, limit: 9999999 }),
            ]);

            setOwnUser(ownUser);

            setSelectableActingBodies(actingBodies.rows.map((actingBody) => {
                return {
                    value: actingBody.id || 0,
                    label: actingBody.name
                };
            }));

            setSelectableTagsFilter((tags || []).map((tag) => {
                return {
                    value: tag.id || 0,
                    label: tag.name
                };
            }));

            setCameras(cameras);
            setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));

            if (groupId) {
                await getGroup(groupId, cameras);
            } else {

                setSelectableCameras(cameras.map((camera) => {
                    return {
                        id: camera.id || 0,
                        name: camera.title
                    };
                }));

                if (!ownUser.isAdmin && ownUser.actingBodyUnitId) {
                    setActingBodyId(ownUser.role?.actingBodyId || 0);
                    loadUsers(ownUser.actingBodyUnitId);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadUsers(actingBodyUnitId: number) {
        try {
            setSelectedUsers([]);
            const users = await accessService.getAccessAllUsers({ actingBodyUnitId: actingBodyUnitId });
            setSelectableUsers(users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            }));
            return users;
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    useEffect(() => {
        if (!selectableTagsFilter || !selectedTags || !selectableCameras || !selectedCameras || !cameras) {
            return;
        }

        if (selectedTags.length == 0) {
            return setDisplayFilter(cameras.map(camera => {
                return camera.id;
            }));
        }

        setDisplayFilter(cameras.filter((camera: AccessSimplifiedCameraData) => {
            return camera.tagList?.some(tag => selectedTags.includes(tag));
        }).map(camera => {
            return camera.id;
        }));
    }, [selectedTags]);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name) && (!ownUser?.isAdmin || unitId !== 0);
    }

    async function formSubmit() {
        try {
            if (groupId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !groupUserIds.includes(selectedUserId));
                const usersToRm = groupUserIds.filter((groupUserId) => !selectedUserIds.includes(groupUserId));

                const selectedCameraIds = selectedCameras.map((camera) => String(camera.id));
                const camerasToAdd = selectedCameraIds.filter((selectedCameraId) => !groupCameraIds.includes(selectedCameraId));
                const camerasToRm = groupCameraIds.filter((groupCameraId) => !selectedCameraIds.includes(groupCameraId));

                await accessService.updateGroup(groupId, {
                    name,
                    description,
                    usersToAdd,
                    usersToRm,
                    camerasToAdd,
                    camerasToRm,
                    permissions
                });
                onClose();
                return;
            }

            await accessService.createGroup({
                name,
                description,
                usersToAdd: selectedUsers.map(item => Number(item.id)),
                camerasToAdd: selectedCameras.map(item => String(item.id)),
                permissions,
                unitId,
            });
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    async function loadUnits(actingBodyId: number) {
        try {
            setUnitId(0);
            const actingBody = await accessService.getActingBody(actingBodyId);
            if (!actingBody.actingBodyUnits) {
                setSelectableUnits([]);
            } else {
                setSelectableUnits(actingBody.actingBodyUnits.map((role) => {
                    return {
                        value: role.id || 0,
                        label: role.name
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '80%' }]}>
            <Tabs tabs={[{
                key: 'details',
                label: translate('details')
            }, {
                key: 'users',
                label: translate('users')
            }, {
                key: 'cameras',
                label: translate('cameras')
            }, {
                key: 'permissions',
                label: translate('permissions')
            }]}>
                {({ selectedTab }) => {
                    if (selectedTab == 'details') {
                        return (<>
                            <FormInput
                                label={translate('name')}
                                placeholder={translate('name')}
                                value={name}
                                onChangeText={setName}
                                invalid={isEmpty}
                            />
                            <FormInput
                                label={translate('description')}
                                placeholder={translate('description')}
                                value={description}
                                onChangeText={setDescription}
                                invalid={() => false}
                            />
                            {ownUser?.isAdmin ?
                                <View style={{ flexDirection: 'row', gap: 16, zIndex: 12 }}>
                                    <View style={{ flex: 1, gap: 5 }}>
                                        <MyAppText style={styles.label}>{translate('actingBody')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableActingBodies}
                                            multiple={false}
                                            value={actingBodyId}
                                            setValue={async (value) => {
                                                const val = value(0);
                                                setActingBodyId(val);
                                                loadUnits(val);
                                                setSelectedUsers([]);
                                                setSelectableUsers([]);
                                            }}
                                            setOpen={setIsOpenDepartment}
                                            open={isOpenDepartment}
                                            searchable={true}
                                            disabled={!!groupId}
                                        />
                                    </View>
                                    <View style={{ flex: 1, gap: 5 }}>
                                        <MyAppText style={styles.label}>{translate('unit')}</MyAppText>
                                        <MyDropDownPicker
                                            items={selectableUnits}
                                            multiple={false}
                                            value={unitId}
                                            setValue={async (value) => {
                                                const val = value(0);
                                                setUnitId(val);
                                                loadUsers(val);
                                            }}
                                            setOpen={setIsOpenUnit}
                                            open={isOpenUnit}
                                            searchable={true}
                                            disabled={!!groupId}
                                        />
                                    </View>
                                </View>
                                : null
                            }
                        </>);
                    }
                    if (selectedTab == 'users') {
                        return <SelectableList
                            selectableLabel={translate('users')}
                            selectableContent={selectableUsers}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedUsers}
                        />;
                    }
                    if (selectedTab == 'cameras') {
                        return (<View style={{ rowGap: 10, flex: 1 }}>
                            <View style={styles.camerasTagsContainer}>
                                <MyAppText style={styles.camerasTagsTitle}>{translate('tags')}</MyAppText>
                                <View style={styles.dropdownStructure}>
                                    <MyDropDownPicker
                                        placeholderColor={getThemedColor(theme, '#CCCCCC')}
                                        placeholder={translate('filter')}
                                        open={isTagPickerOpen}
                                        items={selectableTagsFilter}
                                        setOpen={setIsTagPickerOpen}
                                        value={selectedTags}
                                        setValue={setSelectedTags}
                                        multiple={true}
                                        mode='BADGE'
                                        searchable={true}
                                    />
                                </View>
                            </View>
                            <SelectableList
                                displayFilter={displayFilter}
                                selectableLabel={translate('cameras')}
                                selectableContent={selectableCameras}
                                selectedLabel={translate('participants')}
                                selectedContent={selectedCameras}
                            />
                        </View>);
                    }
                    if (selectedTab == 'permissions') {
                        return (<View style={styles.permissionsContainer}>
                            <View style={styles.permissionColumn}>
                                <MyAppText style={styles.permissionSubTitle}>{translate('cameras')}</MyAppText>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('canWatchLive')}
                                    checked={permissions.camera_watch}
                                    setChecked={() => { }}
                                />
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('canWatchRecords')}
                                    checked={permissions.camera_watch_timeline}
                                    setChecked={(value) => {
                                        permissions.camera_watch_timeline = value;
                                        forceUpdate();
                                    }}
                                />
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('canMovePtz')}
                                    checked={permissions.camera_move_ptz}
                                    setChecked={(value) => {
                                        permissions.camera_move_ptz = value;
                                        forceUpdate();
                                    }}
                                />
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('canWatchFacialDetections')}
                                    checked={permissions.camera_watch_facial_detections}
                                    setChecked={(value) => {
                                        permissions.camera_watch_facial_detections = value;
                                        forceUpdate();
                                    }}
                                />
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('canWatchLprDetections')}
                                    checked={permissions.camera_watch_lpr_detections}
                                    setChecked={(value) => {
                                        permissions.camera_watch_lpr_detections = value;
                                        forceUpdate();
                                    }}
                                />
                            </View>
                            <View style={styles.permissionColumn}>
                            </View>
                        </View>);
                    }
                }}
            </Tabs>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />
        </View>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        checkbox: {
            paddingTop: 6
        },
        permissionsContainer: {
            flexDirection: 'row'
        },
        permissionColumn: {
            width: '50%',
            rowGap: 5
        },
        permissionSubTitle: {
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold',
            fontSize: 14,
        },
        camerasTagsContainer: {
            flexDirection: 'row',
            zIndex: 1,
            minHeight: 40,
            columnGap: 20,
            alignItems: 'center'
        },
        camerasTagsTitle: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
            height: 25,
            fontWeight: 'bold',
        },
        dropdownStructure: {
            flex: 1
        },
        formContainer: {
            minWidth: 300,
            height: '80%',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            minHeight: 520,
            justifyContent: 'space-between',
            rowGap: 10
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
    });
}
