import React, { useContext, useEffect, useState } from 'react';
import ThemeContext from '../context/Theme';
import { Modal, TouchableOpacity, View, StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAppText from './MyAppText';
import { ChangeLanguage, getDefaultLanguage, translate } from '../services/translate';
import { faCircleHalfStroke, faGear, faLanguage, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../services/get-themed-color';
import app from '../services/version.json';
import { centralAPI } from '../services/central-api';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface UserInformationParameters {
    navigation: Navigation;
    showDropDown: boolean;
    setShowDropDown: (value: boolean) => void;
    acceptedTermsOfUse: boolean;
}

export default function UserInformationModal({ navigation, showDropDown, setShowDropDown, acceptedTermsOfUse }: UserInformationParameters) {
    const { theme, toggleTheme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [language, setLanguage] = useState<string>('');

    async function getLanguage() {
        const language = await getDefaultLanguage();
        setLanguage(language);
    }

    useEffect(() => {
        getLanguage();
    }, []);


    function removeMovideskElement() {
        const element = document.getElementById('md-app-widget');
        if (element) {
            element.remove();
        }
    }

    return (
        <Modal visible={showDropDown} animationType='none' onRequestClose={() => setShowDropDown(false)} transparent={true}>
            <TouchableOpacity onPress={() => setShowDropDown(false)} style={styles.modalContainer}></TouchableOpacity>
            <View style={styles.modalBody}>
                <View style={styles.optionsBody}>
                    <TouchableOpacity
                        onPress={() => {
                            toggleTheme();
                        }}
                        style={styles.modalButtons}
                    >
                        <FontAwesomeIcon icon={faCircleHalfStroke} color={getThemedColor(theme, '#222222')} style={{ width: 20 }} />
                        <MyAppText>{translate(theme === 'light' ? 'changeToDark' : 'changeToLight')}</MyAppText>
                    </TouchableOpacity>
                    <View style={styles.tableLine}></View>
                    <TouchableOpacity
                        onPress={async () => {
                            if (language == 'pt-br') {
                                setLanguage('en-us');
                                await ChangeLanguage('en-us');
                            } else {
                                setLanguage('pt-br');
                                await ChangeLanguage('pt-br');
                            }
                        }}
                        style={styles.modalButtons}
                    >
                        <FontAwesomeIcon icon={faLanguage} color={getThemedColor(theme, '#222222')} style={{ width: 20 }} />
                        <MyAppText>{translate('changeLanguage')}: {translate(language)}</MyAppText>
                    </TouchableOpacity>
                    <View style={styles.tableLine}></View>
                    {acceptedTermsOfUse ? <>
                        <TouchableOpacity
                            onPress={() => {
                                navigation.navigate('User', { screen: 'Account' });
                                removeMovideskElement();
                                setShowDropDown(false);
                            }}
                            style={styles.modalButtons}
                        >
                            <FontAwesomeIcon icon={faGear} color={getThemedColor(theme, '#222222')} style={{ width: 20 }} />
                            <MyAppText>{translate('settings')}</MyAppText>
                        </TouchableOpacity>
                        <View style={styles.tableLine}></View>
                        <TouchableOpacity onPress={async () => {
                            navigation.navigate('ChangeLogs');
                            removeMovideskElement();
                            setShowDropDown(false);
                        }} style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <MyAppText> {translate('version')}: {app[0].version}</MyAppText>
                        </TouchableOpacity>
                        <View style={styles.tableLine}></View>
                    </> : null
                    }
                    <TouchableOpacity
                        onPress={async () => {
                            try {
                                await centralAPI.logout();
                                setShowDropDown(false);
                                window.location.href = '/login';
                            } catch (err) {
                                console.error(err);
                            }
                        }}
                        style={styles.modalButtons}
                    >
                        <FontAwesomeIcon icon={faRightFromBracket} color={'rgb(240, 54, 103)'} style={{ width: 20 }} />
                        <MyAppText style={styles.logoutText}>{translate('logout')}</MyAppText>
                    </TouchableOpacity>
                    <View style={styles.tableLine}></View>
                </View>
                <View style={{ padding: 10, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', columnGap: 15 }}>
                    <TouchableOpacity onPress={() => {
                        navigation.navigate('TermsOfUse');
                        removeMovideskElement();
                        setShowDropDown(false);
                    }}>
                        <MyAppText style={styles.footerText}>{translate('termsOfUse')}</MyAppText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        window.location.href = '/contact-us';
                        setShowDropDown(false);
                    }}>
                        <MyAppText style={styles.footerText}>{translate('contactUs')}</MyAppText>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

function getStyles(theme: Theme) {
    const insets = useSafeAreaInsets();

    return StyleSheet.create({
        footerText: {
            fontSize: 13,
        },
        modalContainer: {
            flexDirection: 'column',
            flex: 1
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            flexDirection: 'column',
            borderRadius: 4,
            paddingHorizontal: 20,
            paddingTop: 20,
            position: 'absolute',
            top: 55 + insets.top,
            right: 5 + insets.right,
            minWidth: 300
        },
        optionsBody: {
            rowGap: 15,
        },
        modalButtons: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        logoutText: {
            color: 'rgb(240, 54, 103)',
            fontSize: 15,
        },
    });
}
