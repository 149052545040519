import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import { SelectableItem, SelectableList } from '../../components/SelectableList';
import { Tabs } from '../../components/Tabs';
import Toast from 'react-native-toast-message';
import { accessService } from '../../services/central-api/access';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { WindowInformation } from '../../services/window-information';

export default function TurnstileModal({ onClose, turnstileId }: { onClose: (success: boolean) => void, turnstileId?: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [selectableUsers, setSelectableUsers] = useState<SelectableItem[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<SelectableItem[]>([]);

    const [turnstilesUserIds, setTurnstilesUserIds] = useState<number[]>([]);

    async function getTurnstile(id: number) {
        const turnstile = await accessService.getHikTurnstile(id);
        setName(turnstile.name);

        const users = await loadUsers();
        const selectedUsers = turnstile.Users.map((user) => {
            return {
                id: user.id || 0,
                name: user.name
            };
        });
        setSelectedUsers(selectedUsers);

        setSelectableUsers((users || [])
            .filter((user) => !selectedUsers.find((turnstileUser) => turnstileUser.id == user.id))
            .map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            })
        );

        setTurnstilesUserIds(turnstile.Users.map((user) => user.id || 0));
    }

    async function loadPageInfo() {
        try {
            if (turnstileId) {
                await getTurnstile(turnstileId);
            } else {
                loadUsers();
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function loadUsers() {
        try {
            setSelectedUsers([]);
            const users = await accessService.getAccessAllUsers({});
            setSelectableUsers(users.map((user) => {
                return {
                    id: user.id || 0,
                    name: user.name
                };
            }));
            return users;
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    function isEmpty(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isEmpty(name);
    }

    async function formSubmit() {
        try {
            if (turnstileId) {
                const selectedUserIds = selectedUsers.map((user) => Number(user.id));
                const usersToAdd = selectedUserIds.filter((selectedUserId) => !turnstilesUserIds.includes(selectedUserId));
                const usersToRm = turnstilesUserIds.filter((turnstileUserIds) => !selectedUserIds.includes(turnstileUserIds));


                await accessService.updateHikTurnstile(turnstileId, {
                    usersToAdd,
                    usersToRm,
                });
                onClose(true);
                return;
            }
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 635 }]}>
            <Tabs tabs={[{
                key: 'details',
                label: translate('details')
            }, {
                key: 'users',
                label: translate('users')
            }]}>
                {({ selectedTab }) => {
                    if (selectedTab == 'details') {
                        return (<>
                            <FormInput
                                label={translate('name')}
                                placeholder={translate('name')}
                                value={name}
                                onChangeText={setName}
                                invalid={isEmpty}
                                disabled={true}
                            />
                        </>);
                    }
                    if (selectedTab == 'users') {
                        return <SelectableList
                            selectableLabel={translate('users')}
                            selectableContent={selectableUsers}
                            selectedLabel={translate('participants')}
                            selectedContent={selectedUsers}
                        />;
                    }
                }}
            </Tabs>
            <FormActions
                onSubmit={formSubmit}
                onClose={() => onClose(false)}
                disabled={!isFormValid()} />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        permissionColumn: {
            flexDirection: 'column',
            gap: 5,
        },
        permissionMainColumn: {
            flex: 1,
            flexDirection: 'column',
            gap: 15,
            minWidth: 200
        },
        permissionSubTitle: {
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold',
            fontSize: 14,
        },
        formContainer: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 2,
            padding: 20,
            height: 560,
            justifyContent: 'space-between',
            rowGap: 10
        },
        permissionsContainer: {
            rowGap: 20,
            flexDirection: 'row',
            flex: 1,
            flexWrap: 'wrap'
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
    });
}
