import React, { useEffect, useState } from 'react';
import { View, TextInput, TouchableOpacity, ScrollView, Modal } from 'react-native';
import Toast from 'react-native-toast-message';
import FormActions from '../../../components/formActions';
import FormInput from '../../../components/formInput';
import MyAppText from '../../../components/MyAppText';
import { ClientError } from '../../../services/central-api/base-service';
import { dispatchService } from '../../../services/central-api/dispatch';
import { WindowInformation } from '../../../services/window-information';
import { translate } from '../../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from '../../../components/DeleteModal';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface Props {
    setModalVisible: (value: boolean) => void;
    isModalVisible: boolean; finishCategoryId: number | null;
    onSubmit: () => void;
}

export default function EditModal({ isModalVisible, setModalVisible, finishCategoryId, onSubmit }: Props) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [finishSubCategories, setFinishSubCategories] = useState<Partial<FinishSubCategory>[]>([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedFinishSubCategoryForDeletion, setSelectedFinishSubCategoryForDeletion] = useState<Partial<FinishSubCategory>>();

    function isNameInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isNameInvalid(name) && finishSubCategories.length > 0;
    }

    async function getFinishCategory(finishCategoryId: number) {
        try {
            const finishCategory = await dispatchService.getFinishCategory(finishCategoryId);
            setName(finishCategory.name);
            setFinishSubCategories(finishCategory.finishSubCategories || []);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteFinishSubCategory(finishSubCategory: FinishSubCategory) {
        try {
            if (!finishSubCategory.id) {
                return;
            }
            await dispatchService.deleteFinishSubCategory(finishSubCategory.id);
            const index = finishSubCategories.findIndex(r => r.id == finishSubCategory.id);
            finishSubCategories.splice(index, 1);
            setFinishSubCategories([...finishSubCategories]);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (finishCategoryId) {
            getFinishCategory(finishCategoryId);
        }
    }, [finishCategoryId]);

    async function formSubmit() {
        try {
            if (finishCategoryId) {
                await dispatchService.updateFinishCategory(finishCategoryId, {
                    name,
                    finishSubCategories
                });
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setModalVisible(false);
                return;
            }

            await dispatchService.createFinishCategory({ name, finishSubCategories });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onSubmit();
            setModalVisible(false);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setName('');
            setFinishSubCategories([]);
        }
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '30%' }]}>
                        <FormInput
                            label={translate('name')}
                            placeholder={translate('name')}
                            value={name}
                            onChangeText={setName}
                            invalid={isNameInvalid}
                        />
                        <View style={{ gap: 8, maxHeight: 400 }}>
                            <MyAppText style={styles.label}>{translate('finishSubCategories')}</MyAppText>
                            <ScrollView contentContainerStyle={{ gap: 5, paddingRight: 10 }}>
                                {finishSubCategories.map((finishSubCategory, index) => (
                                    <View key={index} style={{ flexDirection: 'row', alignItems: 'center', gap: 10, padding: 5 }}>
                                        <TextInput
                                            placeholder={translate('subCategory')}
                                            style={[styles.input, styles.textColor(finishSubCategory.name)]}
                                            value={finishSubCategory.name}
                                            onChangeText={(value) => {
                                                finishSubCategories[index].name = value;
                                                setFinishSubCategories([...finishSubCategories]);
                                            }}
                                        />
                                        <TouchableOpacity onPress={() => {
                                            const subCategory = finishSubCategories[index];
                                            if (subCategory.id) {
                                                setSelectedFinishSubCategoryForDeletion(subCategory);
                                                setIsDeleteModalVisible(true);
                                                return;
                                            }
                                            finishSubCategories.splice(index, 1);
                                            setFinishSubCategories([...finishSubCategories]);
                                        }}>
                                            <FontAwesomeIcon fontSize={24} icon={faTimes} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>))
                                }
                            </ScrollView>
                            <TouchableOpacity onPress={() => {
                                finishSubCategories.push({ name: '' });
                                setFinishSubCategories([...finishSubCategories]);
                            }}>
                                <MyAppText style={styles.addFinishSubCategory}>+ {translate('addFinishSubCategory')}</MyAppText>
                            </TouchableOpacity>
                        </View>

                        <FormActions
                            onSubmit={formSubmit}
                            onClose={() => setModalVisible(false)}
                            disabled={!isFormValid()} />
                    </View>
                </View>

                <DeleteModal
                    setModalVisible={setIsDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={selectedFinishSubCategoryForDeletion?.name}
                    onSubmit={async () => {
                        if (selectedFinishSubCategoryForDeletion) {
                            await deleteFinishSubCategory(selectedFinishSubCategoryForDeletion as FinishSubCategory);
                        }
                    }}
                />
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet(theme => ({
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25
    },
    label: {
        color: theme.colors.iconColor,
    },
    addFinishSubCategory: {
        color: theme.colors.buttonBackground
    },
    input: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.backgroundColor,
        borderColor: theme.colors.borderColor,
        borderWidth: 1,
        height: 40,
        padding: 10,
        borderRadius: 4,
        flex: 1
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    textColor: (text?: string) => ({
        color: text ? theme.colors.fieldColor : theme.colors.disabledText
    })
}));
