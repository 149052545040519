import React, { useContext, useEffect, useState } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import DefaultPageContainer from "../../components/DefaultPageContainer";
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MosaicEditModal from './MosaicEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faEdit } from '@fortawesome/free-regular-svg-icons';

import { translate } from "../../services/translate";
import MyAppText from '../../components/MyAppText';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { Pagination } from '../../components/Pagination';
import { cameraService } from '../../services/central-api/cameras';
import MyDataTable from '../../components/MyDataTable';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../typings/Params';

const limit = 25;

export default function Mosaics({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<CamerasParamList, 'Mosaic'>>();

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [mosaic, setMosaic] = useState<Mosaic>();
    const [mosaicsList, setMosaicsList] = useState<Mosaic[]>([]);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState(Math.max(route.params.page - 1, 0));
    const [pageCount, setPageCount] = useState(1);

    async function getMosaicsList() {
        try {
            setIsLoading(true);
            const response = await cameraService.getMosaics({ page, limit });
            setMosaicsList(response.rows);
            setPageCount(Math.ceil(response.count / limit));

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getMosaicsList();
    }, [page]);

    function handleFormClose() {
        setMosaic(undefined);
        setModalVisible(false);
        getMosaicsList();
    }

    function handleFormNew() {
        setMosaic(undefined);
        setModalVisible(true);
    }

    function handleEditClick(mosaic: Mosaic) {
        setMosaic(mosaic);
        setModalVisible(true);
    }

    function handleOpenClick(mosaic: Mosaic) {
        navigation.navigate("MosaicDetail", { id: mosaic.id });
    }

    function showDeleteConfirmationDialog(mosaic: Mosaic) {
        setMosaic(mosaic);
        setDeleteModalVisible(true);
    }

    async function confirmDelete(mosaic: Mosaic) {
        try {
            if (mosaic?.id) {
                await cameraService.deleteMosaic(mosaic.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });

                setDeleteModalVisible(false);
                getMosaicsList();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='mosaics' lateralMenu='cameras' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={handleFormNew} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            onRowClicked={handleOpenClick}
                            columns={[
                                {
                                    name: translate('title'),
                                    selector: row => row.title,
                                    wrap: true,
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <TouchableOpacity onPress={() => handleEditClick(row)}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => showDeleteConfirmationDialog(row)}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={mosaicsList}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <MosaicEditModal
                                    onClose={handleFormClose}
                                    mosaic={mosaic}
                                />
                            </View>
                        </View>
                    </Modal>

                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(mosaic?.title) || ''}
                        onSubmit={async () => {
                            if (mosaic) {
                                await confirmDelete(mosaic);
                            }
                        }}
                    />
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: "center",
        },
    });
}
