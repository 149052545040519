class LateralMenuWidth {
    width: number;
    isOpen: boolean;
    constructor() {
        this.width = 250;
        this.isOpen = true;
    }

    getOriginalWidth() {
        return this.width;
    }

    getWidth() {
        if (this.isOpen) {
            return this.width;
        }
        return 0;
    }

    setIsOpen(value: boolean) {
        this.isOpen = value;
    }
}

export const lateralMenuWidth = new LateralMenuWidth();
