import React, { useContext, useEffect, useState } from "react";
import DefaultPageContainer from "../components/DefaultPageContainer";
import { centralAPI } from "../services/central-api";
import { ScrollView, View, Image, StyleSheet, TouchableOpacity } from "react-native";
import RenderHtml from 'react-native-render-html';
import getThemedColor from "../services/get-themed-color";
import ThemeContext from "../context/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import MyAppText from "../components/MyAppText";
import { translate } from "../services/translate";
import { useNavigationState } from "@react-navigation/native";
import { WindowInformation } from "../services/window-information";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import UserInformationModal from "../components/UserInformationModal";

interface sourceProps {
    html: string;
}

const headerHeight = 64;

export default function TermsOfUse({ navigation }: { navigation: Navigation; }) {
    const { theme } = useContext(ThemeContext);
    const index = useNavigationState(state => state);
    const [showDropDown, setShowDropDown] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = React.useState<AuthenticatedUser>();
    const [source, setSource] = useState<sourceProps>({
        html: ''
    });
    const windowInfo = WindowInformation();
    const insets = useSafeAreaInsets();

    const styles = getStyles(theme);

    async function getTermsOfUse() {
        try {
            const termsOfUse = await centralAPI.getTermsOfUse();
            setSource({ html: termsOfUse.termsOfUse });
        } catch (err) {
            console.error(err);
        }
    }

    async function getAuthenticatedUser() {
        try {
            const authenticatedUser = await centralAPI.checkAuthentication();
            setAuthenticatedUser(authenticatedUser);
        } catch (err) {
            console.warn(err);
        }
    }

    function goBack() {

        if (!authenticatedUser) {
            return window.location.href = '/login';
        }

        if (index.routes.length > 1) {
            return navigation.goBack();
        }

        window.location.href = '/menu';
    }

    async function acceptTermsOfUse() {
        try {
            await centralAPI.acceptTermsOfUse();
            window.location.href = '/menu';
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getAuthenticatedUser();
        getTermsOfUse();
    }, []);

    return (
        <DefaultPageContainer>
            <View style={styles.headerBar}>
                <View style={{ width: 24, height: 24 }}>
                </View>
                <View style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {windowInfo.isMobile ?
                        <Image source={require(`../../assets/smartsampa_logo_${theme}.png`)} style={styles.headerLogo} />
                        : <Image source={require(`../../assets/big_smartsampa_logo_${theme}.png`)} style={styles.bigHeaderLogo} />
                    }                </View>
                {authenticatedUser ?
                    <TouchableOpacity onPress={() => setShowDropDown(!showDropDown)}>
                        <FontAwesomeIcon icon={faCircleUser} color={getThemedColor(theme, '#000000')} fontSize={24} />
                    </TouchableOpacity>
                    :
                    <View style={{ width: 24, height: 24 }}>
                    </View>
                }
            </View>
            <View style={[styles.container, {
                width: windowInfo.width,
                height: windowInfo.height - headerHeight - insets.top - insets.bottom,
            }]}>

                <View style={styles.header}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        {!authenticatedUser || authenticatedUser.acceptedTermsOfUse ?
                            <TouchableOpacity onPress={goBack}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                            </TouchableOpacity> : null
                        }
                        <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('termsOfUse')}
                        </MyAppText>
                    </View>
                </View>
                <View style={styles.cardList}>
                    <ScrollView >
                        <View style={{ flex: 1, maxWidth: 1000, alignSelf: 'center' }}>
                            <RenderHtml
                                source={source}
                                baseStyle={{
                                    color: getThemedColor(theme, '#58595B'),
                                }}
                            />
                            {authenticatedUser && !authenticatedUser.acceptedTermsOfUse ?
                                <TouchableOpacity
                                    style={{
                                        backgroundColor: getThemedColor(theme, '#008E2C'),
                                        borderRadius: 2,
                                        height: 40,
                                        justifyContent: 'center',
                                    }}
                                    onPress={acceptTermsOfUse}
                                >
                                    <MyAppText style={{
                                        color: getThemedColor(theme, '#FFFFFF'),
                                        alignSelf: 'center',
                                        fontSize: 16
                                    }}>{translate('iAcceptTermsOfUse')}</MyAppText>
                                </TouchableOpacity>
                                : null}
                        </View>
                    </ScrollView>
                </View>
            </View>
            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </DefaultPageContainer >
    );
}


function getStyles(theme: Theme) {

    return StyleSheet.create({
        container: {
            rowGap: 15,
            padding: 10
        },
        headerBar: {
            height: headerHeight,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            border: `1px solid ${getThemedColor(theme, '#E6E6E6')}`,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingHorizontal: 14
        },
        headerLogo: {
            height: 40,
            width: 275,
        },
        bigHeaderLogo: {
            height: 40,
            width: 700,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
        },
    });
}
