import OccurrenceTypesModal from './OccurrenceTypesModal';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal, ScrollView, ActivityIndicator, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../services/translate';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../components/MyAppText';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import Toast from 'react-native-toast-message';
import DeleteModal from '../../components/DeleteModal';
import { Pagination } from '../../components/Pagination';
import { dispatchService, getOccurrenceTypesPaginatedParameters } from '../../services/central-api/dispatch';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';
import { MyCheckbox } from '../../components/MyCheckbox';

const limit = 25;

export default function OccurrenceTypes({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'OccurrenceTypes'>>();

    const [filters, setFilters] = useState<getOccurrenceTypesPaginatedParameters>({
        page: Math.max(route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit,
        isActive: typeof route.params.isActive === 'boolean' ? route.params.isActive : true,
    });
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [isActiveFilter, setIsActiveFilter] = useState(filters.isActive);

    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });

    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedOccurrenceType, setSelectedOccurrenceType] = useState<OccurrenceType>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [occurrenceTypes, setOccurrenceTypes] = useState<OccurrenceType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);

    async function getOccurrenceTypes(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getOccurrenceTypes(filters);
            setOccurrenceTypes(response.rows);
            setPageCount(Math.ceil(response.count / limit));

        } catch (err) {
            //TODO: add proper error treatments
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrenceTypes();
    }, [filters]);

    function handleEditClick(occurrenceType: OccurrenceType) {
        setSelectedOccurrenceType(occurrenceType);
        setModalVisible(true);
        getOccurrenceTypes();
    }

    function showDeleteConfirmationDialog(occurrenceType: OccurrenceType) {
        setSelectedOccurrenceType(occurrenceType);
        setDeleteModalVisible(true);
    }

    async function confirmDelete(occurrenceType: OccurrenceType) {
        try {
            if (occurrenceType?.id) {
                await dispatchService.deleteOccurrenceType(occurrenceType.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setDeleteModalVisible(false);
                getOccurrenceTypes();

            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    function handleFormClose() {
        setSelectedOccurrenceType(undefined);
        setModalVisible(false);
        getOccurrenceTypes();
    }

    function handleFormNew() {
        setSelectedOccurrenceType(undefined);
        setModalVisible(true);
    }

    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter,
            isActive: isActiveFilter
        });

        navigation.setParams({
            textFilter,
            isActive: isActiveFilter,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='occurrence_types' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 300 }}>
                            <MyAppText style={styles.filterText}>{translate('searchOccurrenceType')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : '#888888'
                                }]}
                                value={textFilter}
                                onKeyPress={handleKeyDown}
                                onChangeText={setTextFilter}
                                placeholder={translate('typeToSearch')}
                            />
                        </View>
                        <View style={{ justifyContent: 'flex-end', flexGrow: 1, minWidth: 200 }}>
                            <MyCheckbox
                                style={styles.checkbox}
                                label={translate('active')}
                                checked={isActiveFilter}
                                setChecked={(value) => {
                                    if (!value && !filters.isActive) {
                                        return;
                                    }

                                    setIsActiveFilter(value);
                                }} />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>

                            <View style={{ justifyContent: 'flex-end' }}>
                                <TouchableOpacity onPress={handleFormNew} style={styles.new}>
                                    <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                    <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>

                    <View style={styles.cardList}>
                        <View style={{ rowGap: 7 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ flexBasis: '95%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('name')}</MyAppText>
                                </View>
                                <View style={{ flexBasis: '5%' }}>
                                    <MyAppText style={styles.headerCell}>{translate('actions')}</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableLine}></View>
                        </View>
                        <ScrollView>
                            <View style={{ gap: 10 }}>
                                {isLoading ?
                                    <View>
                                        <ActivityIndicator size="small" color={getThemedColor(theme, '#000000')} />
                                    </View> :
                                    occurrenceTypes.length ?
                                        occurrenceTypes.map((occurrenceType, occurrenceTypeIndex) => (
                                            <View key={occurrenceTypeIndex} style={{ rowGap: 9 }}>
                                                <View style={styles.row}>
                                                    <View style={{ flexBasis: '95%' }}>
                                                        <MyAppText>{occurrenceType.name}</MyAppText>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', flexBasis: '5%', columnGap: 10 }}>
                                                        <TouchableOpacity onPress={() => handleEditClick(occurrenceType)}>
                                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => showDeleteConfirmationDialog(occurrenceType)}>
                                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={styles.tableLine}></View>
                                            </View>
                                        )) :
                                        <View>
                                            <MyAppText style={{ textAlign: 'center', color: '#58595B', marginTop: 10 }}>
                                                {translate('noResults')}.
                                            </MyAppText>
                                        </View>
                                }
                            </View>
                        </ScrollView>
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <OccurrenceTypesModal
                                    onClose={handleFormClose}
                                    occurrenceType={selectedOccurrenceType}
                                />
                            </View>
                        </View>
                    </Modal>

                    <DeleteModal
                        setModalVisible={setDeleteModalVisible}
                        isModalVisible={isDeleteModalVisible}
                        itemName={String(selectedOccurrenceType?.name) || ''}
                        onSubmit={async () => {
                            if (selectedOccurrenceType) {
                                await confirmDelete(selectedOccurrenceType);
                            }
                        }}
                    />

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        row: {
            flexDirection: 'row',
            rowGap: 15
        },
        deleteConfirmation: {
            alignItems: 'center',
            justifyContent: 'center',
            width: '33%',
            height: '33%',
            marginLeft: '33%',
            marginTop: '10%'
        },
        deleteConfirmationText: {
            fontSize: 20,
            marginBottom: 20
        },
        deleteButtonsView: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        deleteButtons: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 10,
            color: getThemedColor(theme, '#FFFFFF')
        },
        circle: {
            width: 15,
            height: 15,
            borderRadius: 7.5,
            marginRight: 10
        },
        red: {
            backgroundColor: 'red',
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 20,
            flex: 1
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        checkbox: {
            paddingHorizontal: 8,
            height: 40
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#888',
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        buttonsContainer: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            marginTop: 20
        }
    });
}
