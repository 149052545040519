import { BaseService, ClientError, ExtendableError, InvalidValue, NotFound, Unauthorized, UnexpectedError } from "./base-service";


export class DuplicatedCameraValue extends ExtendableError {
    cameraId: string | undefined;
    constructor(code: string, cameraId: string) {
        super(code);
        this.cameraId = cameraId;
    }
}

interface UpdatePtzConfiguration {
    address?: string;
    port?: number;
    username?: string;
    password?: string;
}

interface updateCameraParameters {
    title?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    serialNo?: string;
    hasFacial?: boolean;
    isPanoramic?: boolean;
    internetType?: InternetType;
    installer?: string;
    networkType?: NetworkType;
    installedAmountCameras?: number;
    installationCompanyId?: number;
    internetCompanyId?: number;
    rtspAddress?: string;
    httpAddress?: string;
    sourceTech?: SourceTech;
    ptzConfiguration?: UpdatePtzConfiguration;
}

interface createCameraParameters {
    title: string;
    address: string;
    latitude: number;
    longitude: number;
    serialNo: string;
    hasFacial: boolean;
    hasLpr: boolean;
    type: CameraType;
    internetType: InternetType;
    installer: string;
    networkType: NetworkType;
    installationCompanyId: number;
    internetCompanyId: number;
    installedAmountCameras: number;
    rtspAddress?: string;
    httpAddress?: string;
    ptzConfiguration?: PtzConfiguration;
}

interface getCamerasPaginatedParameters {
    page: number;
    limit: number;
    textFilter?: string;
    offline?: boolean;
    showDisabled: boolean;
    hideChildren: boolean;
}

interface getReportsPaginatedParameters {
    page: number;
    limit: number;
    textFilter?: string;
}

interface createReportParameters {
    name: string;
    startDate: string;
    endDate: string;
    isPdf: boolean;
}

interface createAlarmCenterParameters {
    title: string;
    address: string;
    latitude: number;
    longitude: number;
    serialNo: string;
    internetType: InternetType;
    installer: string;
    networkType: NetworkType;
    installationCompanyId: number;
    internetCompanyId: number;
}

interface updateAlarmCenterParameters {
    title?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    serialNo?: string;
    internetType?: InternetType;
    installer?: string;
    networkType?: NetworkType;
    installationCompanyId?: number;
    internetCompanyId?: number;
}

export interface getPaginatedAlarmCenterParameters {
    page: number;
    limit: number;
    textFilter?: string;
}


class CrmService extends BaseService {

    async getCamerasPaginated(params: getCamerasPaginatedParameters): Promise<{ count: number; rows: CrmPaginatedCamera[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/cameras/paginated${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
            offline: params.offline,
            showDisabled: params.showDisabled,
            hideChildren: params.hideChildren,
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} CannotUpdateToCurrentAction
     * @returns
     */
    async disableEnableCamera(id: string, action: 'enable' | 'disable', reason: string): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}/${action}`, {
            method: "PATCH",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({ reason })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async updateCamera(id: string, data: updateCameraParameters): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}`, {
            method: "PATCH",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(data)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'RtspAlreadyRegistered' || resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    /**
     *
     * @throws {ClientError} RtspAlreadyRegistered | SerialNoAlreadyRegistered | RtspCameraMustHaveAnAddress
     * @returns
     */
    async createCamera(data: createCameraParameters): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                ...data,
                sourceTech: 'rtsp',
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == "InvalidValue") {
                throw new InvalidValue(resJson.field);
            } else if (resJson.code == 'RtspAlreadyRegistered' || resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCamera(id: string): Promise<Camera> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async deleteCamera(id: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/crm/camera/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async downloadCameraOfflineTime(params: createReportParameters) {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/cameras-offline-time`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(params)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async getReports(params: getReportsPaginatedParameters): Promise<{ count: number; rows: Report[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/crm/reports/paginated${this.encodeQueryParams({
            ...params,
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getReport(id: number): Promise<{ resultUrl: string; }> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/crm/report/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    /**
     *
     * @throws {ClientError} SerialNoAlreadyRegistered
     * @returns
     */
    async createAlarmCenter(data: createAlarmCenterParameters): Promise<AlarmCenter> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-center`, {
            method: "POST",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify({
                ...data,
                sourceTech: 'rtsp',
            })
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAlarmCenter(id: string): Promise<AlarmCenter> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-center/${id}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async getAlarmCenterPaginated(params: getPaginatedAlarmCenterParameters): Promise<{ count: number; rows: AlarmCenter[]; }> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-centers/paginated${this.encodeQueryParams({
            limit: params.limit,
            page: params.page,
            textFilter: params.textFilter,
        })}`, {
            method: "GET",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateAlarmCenter(id: string, data: updateAlarmCenterParameters): Promise<AlarmCenter> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/alarm-center/${id}`, {
            method: "PATCH",
            headers: this.getHeaders(token, "application/json;charset=utf-8"),
            body: JSON.stringify(data)
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 404) {
            throw new NotFound();
        }

        if (res.status >= 400 && res.status <= 499) {
            const resJson = await res.json();
            if (resJson.code == 'SerialNoAlreadyRegistered') {
                throw new DuplicatedCameraValue(resJson.code, resJson.cameraId);
            }
            throw new ClientError(resJson.code);
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
        return res.json();
    }

    async deleteAlarmCenter(id: string) {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/alarm-center/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(token, "application/json;charset=utf-8")
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

}

export const crmService = new CrmService();
