import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import { Pagination } from '../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faSearch, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import { dispatchService } from '../../services/central-api/dispatch';
import AssignmentModal from './AssignmentModal';
import MyDataTable from '../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';

export default function Assignments({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'Assignments'>>();

    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: 25
    });

    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(filters.page);
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const loaders = useRef({ didPageLoad: false, didTextFilterLoad: false });


    async function getAssignments() {
        try {
            setIsLoading(true);
            setPage(page);
            const response = await dispatchService.getAssignments(filters);
            setAssignments(response.rows);
            setPageCount(Math.ceil(response.count / 10));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(assignment: Assignment) {
        try {
            if (assignment?.id) {
                await dispatchService.deleteAssignment(assignment.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                setDeleteModalVisible(false);
                getAssignments();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }
    useEffect(() => {
        if (!loaders.current.didPageLoad) {
            loaders.current.didPageLoad = true;
            return;
        }
        setFilters({ ...filters, page });
    }, [page]);

    useEffect(() => {
        getAssignments();
    }, [filters]);

    function handleFilters() {
        setFilters({
            ...filters,
            textFilter,
            page: 0
        });

        navigation.setParams({
            textFilter,
            page: 1
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='assignments' lateralMenu='dispatch' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                            <MyAppText style={styles.filterText}>{translate('searchAssignment')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => {
                                setSelectedAssignment(undefined);
                                setModalVisible(true);
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('name'),
                                    selector: row => row.name,
                                    grow: 5
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 20 }}>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedAssignment(row);
                                                setModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                setSelectedAssignment(row);
                                                setDeleteModalVisible(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                            </TouchableOpacity>
                                        </View>
                                }
                            ]}
                            data={assignments}
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />
                </View>
                {/* Delete Modal */}
                <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={String(selectedAssignment?.name) || ''}
                    onSubmit={async () => {
                        try {
                            if (selectedAssignment) {
                                await confirmDelete(selectedAssignment);
                            }
                            Toast.show({
                                type: 'sentinelxSuccess',
                                text1: translate('ActionSuccessfully'),
                            });
                        } catch (err) {
                            if (err instanceof ClientError) {
                                return Toast.show({
                                    type: 'sentinelxError',
                                    text1: translate(err.message),
                                });
                            }

                            console.error(err);
                            Toast.show({
                                type: 'sentinelxError',
                                text1: translate('unexpectedError'),
                            });
                        }
                    }}
                />
                {/* Create/Edit Modal */}
                <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} >
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <AssignmentModal
                                onClose={() => {
                                    setSelectedAssignment(undefined);
                                    setModalVisible(false);
                                    getAssignments();
                                }}
                                assignmentId={selectedAssignment?.id}
                            />
                        </View>
                    </View>
                </Modal>
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 20
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        buttonsContainer: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            marginTop: 20
        }
    });
}
